import { menus } from "../../../../helpers/menu";
import { Box, List, ListItemIcon, ListItemText, useMediaQuery } from "@mui/material";
import { fnLocalizeFilter } from "../../../../services/localize.service";
import { useGlobalContext } from "../../../../services/context.service";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { userLogout } from "../../../../store/actions/userAction";
import { useAppDispatch } from "../../../../hooks/redux";
import { getMedia } from "../../../../helpers/media";
import { useEffect } from "react";
import { hoverLink } from "../../../../style/global";
import { checkPermission } from "../../../../helpers/permissions";
import { getDataFromLocalStorage } from "../../../../utils/methods";

const DrawerHeader = styled( 'div' )( ( { theme } ) => ( {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing( 0, 1 ),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
} ) );

const MenuItem = styled( List )( ( theme ) => ( {
	display: 'flex',

} ) );


const useStyles = makeStyles<Theme>( ( theme ) => ( {
	link: {
		textDecoration: "inherit",
		color: "#ccc",
		display: "flex",
		width: "100%",
		"&:hover": {
			...hoverLink
		}
	},
	activeLink: {
		textDecoration: "inherit",
		color: "#ccc",
		display: "flex",
		width: "100%",
		backgroundColor: "rgba(200,200,200,0.2)",
		"& hover": {
			...hoverLink
		}
	}
} ) );

export default function Menu () {

	const { locale } = useGlobalContext();
	const location = useLocation();
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const matches = useMediaQuery( getMedia() );
	const navigate = useNavigate();

	useEffect( () => {
		let role: string = getDataFromLocalStorage( 'role' ) as string;
		/*  */
		if ( role === "SmartCardsDriver" )
			navigate( './driver/cards' );
		else if ( location.pathname === `/` ) navigate( './clients' );
	}, [] )


	const logout = () => {
		dispatch( userLogout() );
	};

	return <Box width={ "100%" }>
		{ !matches && <><DrawerHeader/><DrawerHeader/></> }

		{
			menus.filter( menu => !!menu.icon && menu.permission && checkPermission( { permissionName: menu.permission } ) > 0 ).map( menu => (
				<NavLink key={ menu.link } to={ `./${ menu.linkApp }` }
				         className={ location.pathname !== `/${ menu.link }` ? classes.link : classes.activeLink }>
					<MenuItem key={ menu.link }>
						<ListItemIcon sx={ { minWidth: "25px", margin: "0 .7rem", color: "inherit" } }>{ menu.icon }</ListItemIcon>
						<ListItemText color="inherit" primary={ fnLocalizeFilter( locale, `${ menu.title }` ) }/>
					</MenuItem>
				</NavLink>
			) )
		}
		<NavLink className={ classes.link } to={ "./" }>
			<MenuItem onClick={ logout }>
				<ListItemIcon sx={ { minWidth: "25px", margin: "0 .7rem", color: "inherit" } }><ExitToAppIcon/></ListItemIcon>
				<ListItemText color="inherit" primary={ fnLocalizeFilter( locale, '_lang.module.login.btnLogout' ) }/>
			</MenuItem>
		</NavLink>
	</Box>
}
