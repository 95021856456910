import { REACT_APP_BASE_URL } from "./api";
import { getDataFromLocalStorage } from "../utils/methods";
import axios from "axios";
// @ts-ignore
import { saveAs } from 'file-saver';

export interface IReport {
	reportId: number;
	fromDate: string;
	toDate: string;
	holders: number[];
	cards: string[];
	fileName: string;
}

export const getReportFile = async ( data: IReport ) => {

	const baseUrl = REACT_APP_BASE_URL;
	const storage = JSON.parse( getDataFromLocalStorage( 'token' ) as string );

	await axios( {
		url: `${ baseUrl }/common/reports/create`,
		method: "post",
		data: data,
		responseType: 'blob',
		// @ts-ignore
		headers: { 'authorization': `Bearer ${ storage && storage.token }` }
	} ).then( ( response ) => {
		saveAs( response.data, data.fileName );
	} );
}
