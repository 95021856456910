import { MouseEvent, Suspense, useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { fnLocalizeFilter } from "../../../../services/localize.service";
import { useGlobalContext } from "../../../../services/context.service";
import Loading from "../../../UI/Loading/Loading";
import Modal from "../../../UI/Modal/Modal";

export default function SmartCardContextMenu () {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	const [ isOpenModal, setOpenModal ] = useState( false );

	const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>( null );
	const open = Boolean( anchorEl );
	const handleClick = ( event: MouseEvent<HTMLButtonElement> ) => {
		setAnchorEl( event.currentTarget );
	};
	const handleClose = () => {
		setAnchorEl( null );
	};

	const handleBlockedCard = () => {
		setOpenModal(true)
		handleClose();
	};

	const handleModalBlockedCard = () => {
		console.log( 'handleModalBlockedCard' )
		setOpenModal(false)
	};

	return (
		<>
			<IconButton onClick={ handleClick }>
				<MoreVertIcon sx={ { color: "#fff" } }/>
			</IconButton>

			<Menu
				anchorEl={ anchorEl }
				open={ open }
				onClose={ handleClose }
				anchorOrigin={ { vertical: 'top', horizontal: 'right' } }
				transformOrigin={ { vertical: 'top', horizontal: 'right' } }
			>
				<MenuItem dense
				          onClick={ handleBlockedCard }>{ fnLocalizeFilter( locale, "_lang.module.cards.qr.contextMenu.blocked" ) }</MenuItem>
			</Menu>

			{
				isOpenModal && <Suspense fallback={ <Loading type={ 'fullScreen' }/> }>
            <Modal
                handleBtnOk={ handleModalBlockedCard }
                isOpen={ isOpenModal }
                handleBtnCancel={ setOpenModal }
            >
              <>{ fnLocalizeFilter( locale, '_lang.module.cards.qr.contextMenu.confirmBlockedCard' ) }</>
            </Modal>
          </Suspense>
			}

		</>
	);
}
