import { Button, Grid, useMediaQuery } from "@mui/material";
import { ICardPurses, ICards, ICardsStatus } from "../../../../../../services/response.interface";
import { cardAPI } from "../../../../../../services/cardAPI.service";
import { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { useGlobalContext } from "../../../../../../services/context.service";
import ClientGroupTitle from "../../../../../Common/ClientGroupTitle";
import { getMedia } from "../../../../../../helpers/media";
import CustomTable from "../../../../../UI/CustomTable";
import { getColumnsLimits } from "./limits.columns";
import { useAppDispatch } from "../../../../../../hooks/redux";
import { fnLocalizeFilter } from "../../../../../../services/localize.service";
import { COUNT_PER_ON_PAGE } from "../../../../../../helpers/api";
import { IFilter, IOrderBy } from "../../../../../UI/CustomTable/interface/interface.custom";
import { styled } from "@mui/material/styles";
import Loading from "../../../../../UI/Loading/Loading";
import { checkPermission } from "../../../../../../helpers/permissions";

const ModalAddLimit = lazy( () => import('./ModalAddLimit') );

interface ICardLimits {
	card: ICards;
	cards: ICards[];
	cardPurses: ICardPurses[];
	cardTypeLimits: ICardsStatus[];
}

/* Кол-во строк в таблице счетов, на странице инфо клиента */
const countPerOnPage: number = +COUNT_PER_ON_PAGE;

const GridTitle = styled( Grid )( () => ( {
	display: 'flex',
	alignItems: 'center',
	"&>.MuiGrid-root": {
		margin: 0
	}
} ) );

export default function CardLimits ( { card, cards, cardPurses, cardTypeLimits }: ICardLimits ) {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();
	/* Инициализация медиа  */
	const matches = useMediaQuery( getMedia() );
	const dispatch = useAppDispatch();
	const [ isOpenModal, setOpenModal ] = useState( false );

	/* Получаем список счетов выбранного клиента */
	const [ getLimits, {
		data: dataLimits = { limits: [] },
		isLoading: isLoadingLimits,
		isSuccess: isSuccessLimits
	} ] = cardAPI.useLazyFetchLimitsByCardQuery();

	useEffect( () => {
		card.cardId && getLimits( { locale, cardId: card.cardId } )
	}, [ card, locale ] );

	/* Заполняем табличные заголовки */
	const columns = useMemo( () => {
		return getColumnsLimits( {
			locale,
			cards,
			limits: dataLimits.limits,
			cardId: card.id,
			dispatch
		} );
	}, [ cards, dispatch, card, locale, dataLimits ] );

	/* Текущая страница счетов */
	const [ pageNum, setPage ] = useState<number>( 0 );
	const [ orderBy, setOrderBy ] = useState<IOrderBy>( { field: "", order: "asc" } );
	const handleChangePage = ( { page, orderBy }: IFilter ) => {
		//setRefresh( true );
		setOrderBy( orderBy );
		setPage( page - 1 );
	};

	/* Фильтр по столбцам таблицы  */
	const rows = useMemo( () => {
		/* Применение фильтра */
		let filteredArr = dataLimits.limits;
		let count = filteredArr.length;

		/* Обрезаем массив до необходимых размеров отображения на странице */
		const startRow = pageNum === 0 ? 0 : ( pageNum * countPerOnPage );
		const stopRow = ( pageNum + 1 ) * countPerOnPage;

		/* Обрезаем по строкам на странице после фильтра */
		filteredArr = filteredArr.slice( startRow, stopRow );

		return { arr: filteredArr, count };

	}, [ pageNum, orderBy, dataLimits ] );


	return <>
		<Grid item>
			<GridTitle item xs={ 12 } sx={ { margin: checkPermission( { permissionName: 'Limits' } ) > 1 ? 'initial': '1rem' } }>
				<ClientGroupTitle isLoading={ isLoadingLimits }
				                  title={ fnLocalizeFilter( locale, '_lang.module.dataGroup.limits' ) } mt={ 0 }
				/>
				{ checkPermission( { permissionName: 'Limits' } ) > 1 &&
            <Button
                color={ 'secondary' }
                variant={ 'contained' }
                sx={ { margin: '1rem' } }
                onClick={ () => setOpenModal( true ) }
            >
							{ fnLocalizeFilter( locale, '_lang.module.cards.limits.titleBtnAddLimit' ) }
            </Button>
				}
			</GridTitle>

			<CustomTable
				rows={ rows.arr }
				columns={ columns }
				isLoading={ isLoadingLimits }
				count={ rows.count }
				labelEmptyRows={ fnLocalizeFilter( locale, '_lang.module.common.table.emptyRows' ) }
				rowsPerPageOptions={ [ countPerOnPage ] }
				fnRefreshData={ handleChangePage }
				accountId={ card && card.id }
			/>

		</Grid>
		{
			isOpenModal && <Suspense fallback={ <Loading type={ 'fullScreen' }/> }>
          <ModalAddLimit isShow={ isOpenModal } handleClose={ () => setOpenModal( false ) } cardId={ card && card.id }
          />
        </Suspense>
		}
	</>
}