import { MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

const SelectCustom = styled( Select )( () => ( {
	"& .MuiSelect-select": {
		whiteSpace: 'break-spaces'
	}
} ) );

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

interface IComboboxMultiply {
	/**
	 * Массив
	 */
	array: any[];
	/**
	 * Название отображаемого поля
	 */
	optionName: string;
	/**
	 * Идентификатор
	 */
	fieldId: string;
	/**
	 * Выбранные поля
	 */
	selected?: any[];

	onChange? ( arr: any[] ): void;

	[ key: string ]: any;
}

export default function ComboboxMultiply ( {
	                                           array,
	                                           selected,
	                                           optionName,
	                                           fieldId,
	                                           onChange,
	                                           ...other
                                           }: IComboboxMultiply ) {

	const [ selectedArray, setSelectedArray ] = useState<typeof selected>( [] );

	useEffect( () => {
		selected && setSelectedArray( selected );
	}, [ selected ] )

	const handleChange = ( e: SelectChangeEvent<any> ) => {
		const { target: { value } } = e;
		const newVal = value === 'string' ? value.split( ',' ) : value;
		setSelectedArray( newVal );
		onChange && onChange( newVal );
	};

	return <>
		<SelectCustom
			sx={ { width: '100%' } }
			value={ selectedArray }
			multiple
			onChange={ handleChange }
			renderValue={ ( selected: any ) => {
				return selected.join( ', ' )
			} }
			MenuProps={ MenuProps }
			{ ...other }
		>
			{
				array.map( ( a ) => (
					<MenuItem key={ a[ fieldId ] } value={ a[ fieldId ] }>
						<Checkbox checked={ selectedArray && selectedArray.indexOf( a[ fieldId ] ) !== -1 }/>
						<ListItemText primary={ `[${ a[ fieldId ] }] ${ a[ optionName ] }` }/>
					</MenuItem>
				) )
			}

		</SelectCustom>
	</>
}