import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VariantType } from "notistack";
import { IModalMessage } from "../../components/UI/Modal/ModalOld";

export interface ITooltip {
	type: VariantType;
	message: string;
}

export interface IModal extends IModalMessage {
	isModalOpen: boolean;
}

interface LayoutState {
	isLoading: boolean,
	tooltip: ITooltip
	modal: IModal;
}

const initialState: LayoutState = {
	isLoading: false,
	tooltip: {
		type: "default",
		message: ""
	},
	modal: {
		isModalOpen: false
	}
}


const layoutReducer = createSlice( {
	name: 'layout',
	initialState,
	reducers: {
		/* Отображение нового всплывающего окна */
		showToast ( state: LayoutState, action: PayloadAction<ITooltip> ) {
			state.tooltip.type = action.payload.type;
			state.tooltip.message = action.payload.message;
		},
		/* Очистка сообщения */
		clearToast ( state: LayoutState ) {
			state.tooltip.type = "default";
			state.tooltip.message = "";
		},
		/* Отображение иодального окна */
		showModalWindow ( state: LayoutState, action: PayloadAction<IModalMessage> ) {
			state.modal = { ...action.payload, isModalOpen: true }
		},
		/* Закрытие модального окна */
		closeModalWidow ( state: LayoutState ) {
			state.modal.isModalOpen = false;
		}
	}
} )

export default layoutReducer;