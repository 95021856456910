import { Box, Card, CardMedia, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ReactCardFlip from 'react-card-flip';
import { useMemo, useState } from "react";
import SmartCardContextMenu from "./SmartCardContextMenu";
import QRCard from "./QRCard";
import Loading from "../../../UI/Loading/Loading";
import { useGlobalContext } from "../../../../services/context.service";
import { cardAPI } from "../../../../services/cardAPI.service";
import LimitsCardDriver from "./LimitsCardDriver";
import { formatDriverCard } from "../../../../utils/methods";
import { ICardsStatus } from "../../../../services/response.interface";


const Container = styled( Box )( () => ( {
	boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
	maxWidth: 330, maxHeight: 180,
	width: 310, height: 180,
	margin: "0 auto",
	borderRadius: "8px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	position: "relative"
} ) );

const NumCard = styled( Typography )( () => ( {
	color: "#9b9b9b",
	position: "absolute",
	bottom: "30%",
	right: 10
} ) );

const FIOCard = styled( Typography )( () => ( {
	color: "#9b9b9b",
	position: "absolute",
	bottom: "5%",
	right: 10
} ) );

const StatusCard = styled(
	( props: any ) =>
		<Typography { ...props }/>
)( ( props ) => {
	const { status } = props;

	return ( {
		color: status === 0 ? "#a6ec92" : "#bb0109",
		position: "absolute",
		top: 7,
		left: 18
	} )
} );


const ContextMenu = styled( Box )( () => ( {
	position: "absolute",
	right: 0, top: 0,
	zIndex: 1
} ) );


export default function SmartCard () {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	const [ isFrontSide, setFrontSide ] = useState( false );

	const { data: dataCard, isLoading: isLoadingData } = cardAPI.useFetchCardDriverQuery( { locale } );
	const [ getQR, { data: dataQR, isLoading: isLoadingQR } ] = cardAPI.useLazyFetchQRCardDriverQuery();

	/* Получаем все доступные статусы карт */
	const { data: cardStatuses = { statuses: [] } } = cardAPI.useFetchCardsStatusQuery( { locale } );


	const handleGetQR = async () => {
		dataCard && getQR( { locale, cardId: dataCard.cardId } );
		setFrontSide( true );
	};

	const handleFlipToFront = () => {
		setFrontSide( false );
	};

	const getStatusName = ( id: number ) => {
		const status = cardStatuses.statuses.filter( s => s.id === id )[ 0 ] as ICardsStatus;
		return status && status.remark;
	};


	const fioDriver = useMemo( () => {
		if ( dataCard ) {
			const { lastName = "", firstName = "" } = dataCard;
			return `${ lastName } ${ firstName }`
		}
		return ""
	}, [ dataCard ] );

	if ( isLoadingData ) {
		return <Container><Loading type={ "big" }/></Container>
	}


	return (
		<>
			<ReactCardFlip isFlipped={ isFrontSide } flipDirection="horizontal">

				<Container>
					<Card sx={ { width: 330, height: 180 } } onClick={ handleGetQR }>
						<CardMedia
							component="img"
							image="./img/template-card.png"
						/>
					</Card>
					<StatusCard
						status={ dataCard && dataCard.status }>{ dataCard && getStatusName( dataCard.status ) }</StatusCard>
					<NumCard> { dataCard && formatDriverCard( dataCard.cardId ) } </NumCard>
					<FIOCard>{ fioDriver }</FIOCard>
					<ContextMenu> <SmartCardContextMenu/> </ContextMenu>
				</Container>

				<Container onClick={ handleFlipToFront }>
					{
						isLoadingQR
							? <Loading type={ "big" }/>
							: dataQR && <QRCard qr={ dataQR.qr } lifeTime={ dataQR.lifeTime } flipCard={ handleFlipToFront }/>
					}
				</Container>


			</ReactCardFlip>

			{ dataCard && <LimitsCardDriver cardId={ dataCard.cardId }/> }

		</>
	)
}
