import { ICommonResponse } from "../services/response.interface";
import { AppDispatch } from "../store/store";
import { showTooltip } from "../store/actions/layoutActions";
import { ITooltip } from "../store/reducers/layoutReducer";
import { fnLocalizeFilter, ILocale } from "../services/localize.service";
import { IOrderBy } from "../components/UI/CustomTable/interface/interface.custom";

interface IFilterObject<T> {
	arr: T[],
	fields: string[],
	searchStr: string
}

/**
 * Поиск по нескольким полям в массиве
 * @param arr - Исходный массив
 * @param fields - поля по которым необходим поиск ['field']
 * @param searchStr - строка поиска
 */
export function filterObject<T> ( { arr, fields = [ '' ], searchStr }: IFilterObject<T> ) {
	return arr.reduce( ( acc: any, item: any ) => {

		let indexField = '';
		fields.forEach( field => {
			if ( item[ field ] ) indexField += item[ field ] + '/\\';
		} );


		return ( indexField.includes( searchStr ) )
			? [ ...acc, item ]
			: [ ...acc ];

	}, [] );


}

/**
 * Запись в локальное хранилище
 * @param key
 * @param value
 */

export function setDataToLocalStorage ( key: string, value: any ) {
	localStorage.setItem( key, value );
}

/**
 * Получение данных из локального хранилища
 * @param key
 */

export function getDataFromLocalStorage ( key: string ) {
	return localStorage.getItem( key );
}

/**
 * Удаление объектов из локального хранилища
 * @param key
 */
export function delDataFromLocalStorage ( key: string ) {
	localStorage.removeItem( key );
}

/**
 * Функция изменения стэйта форм редактирования
 */
export function handleClickSaveEditorState ( e: any, state: any, setChangeState: Function, setIsFormChange = ( bool?: boolean ) => {
} ) {

	const { id, type, name = undefined, value = null, checked = null } = e.target;

	let data;
	switch ( type ) {
		case "checkbox" : {
			data = { [ id ]: checked };
			break;
		}
		default : {
			if ( id ) {
				data = { [ id ]: value };
			} else {
				data = { [ name ]: value };
			}

		}
	}

	setChangeState( { ...state, ...data } );
	setIsFormChange( true );
}

interface IRequestTooltip {
	res: ICommonResponse;
	locale: ILocale;
	dispatch: AppDispatch;
}

/* Тоолтип ответа на асинхронны запрос */
export function asyncRequestTooltip ( { res, dispatch, locale }: IRequestTooltip ) {
	let tooltip: ITooltip = { type: "success", message: fnLocalizeFilter( locale, '_lang.error.1002' ) };

	if ( res.errorCode ) {
		tooltip = { type: "error", message: fnLocalizeFilter( locale, `_lang.error.${ res.errorCode }` ) };
	} else if ( res.errorCode !== 0 )
		tooltip = { type: "error", message: fnLocalizeFilter( locale, '_lang.error.1003' ) };

	dispatch( showTooltip( tooltip ) );

	return res.errorCode === 0;
}

interface IFieldSort {
	array: any[];
	orderBy: IOrderBy;
}

/* Сортировка полей на клиенте */
export function fieldSort ( { array, orderBy: { field, order } }: IFieldSort ) {
	return array.slice().sort( function ( a: any, b: any ) {
		if ( order === 'desc' ) {
			if ( a[ field ] > b[ field ] ) {
				return 1;
			}
			if ( a[ field ] < b[ field ] ) {
				return -1;
			}
		} else {
			if ( a[ field ] < b[ field ] ) {
				return 1;
			}
			if ( a[ field ] > b[ field ] ) {
				return -1;
			}
		}
		// a должно быть равным b
		return 0;
	} )
}

/* Форматирование номера карты для водителя */
export const formatDriverCard = ( card: string ) => {
	const space = 4;
	let newCard = "";

	for ( let i = 0; i < card.length; i++ ) {
		if ( i % space === 0 ) newCard += " ";
		newCard += card[ i ];
	}

	return newCard;

};
