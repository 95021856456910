import { bubble as Menu } from 'react-burger-menu'
import React, { useState } from "react";

const styles = {
	bmBurgerButton: {
		position: 'fixed',
		width: '36px',
		height: '30px',
		left: '25px',
		top: '15px'
	},
	bmBurgerBars: {
		background: '#bbb'
	},
	bmBurgerBarsHover: {
		background: '#a90000'
	},
	bmCrossButton: {
		height: '24px',
		width: '24px'
	},
	bmCross: {
		background: '#bdc3c7'
	},
	bmMenuWrap: {
		position: 'fixed',
		top: '0',

	},
	bmMenu: {
		background: '#373a47',
		width: "100%",
		fontSize: '1.15em'
	},
	bmMorphShape: {
		fill: '#373a47'
	},
	bmItemList: {
		color: '#b8b7ad',
		padding: '2.5rem 1rem 1rem 1rem',
		boxSizing: 'border-box'
	},
	bmItem: {
		display: 'inline-block'
	},
	bmOverlay: {
		top: "0",
		background: 'rgba(0, 0, 0, 0.3)'
	}
}

interface IMenuMobile {
	menu: JSX.Element
}

export default function MenuMobile ( { menu }: IMenuMobile ) {
	const [ isOpen, setOpen ] = useState<boolean>( false );

	return (
		<div>
			<Menu width={ "220px" } styles={ styles } isOpen={ isOpen }
			      pageWrapId={ "page-wrap" }
			      outerContainerId={ "outer-container" }>
				<main id="page-wrap" style={ { width: "100%" } }>
					{ menu }
				</main>

			</Menu>

		</div>
	)
}
