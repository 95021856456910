import { fnLocalizeFilter } from "../../services/localize.service";
import { useGlobalContext } from "../../services/context.service";
import { hoverLink } from "../../style/global";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { holderAPI } from "../../services/holderAPI.service";
import { styled } from "@mui/material/styles";
import React, { useMemo } from "react";
import { getMedia } from "../../helpers/media";


interface IClientDetail {
	children: React.ReactElement<any, any>;
	backToType: 'client' | 'cards';

	handleClose (): void;
}

const BackTo = styled( Typography )( () => ( {
	margin: "1rem",
	fontSize: '.9rem',
	cursor: 'pointer',
	"&:hover": {
		...hoverLink
	}
} ) );

const Container = styled( Grid )( () => ( {} ) );

export default function BackToLink ( { backToType, children, handleClose }: IClientDetail ) {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	/* Получаем список клиентов доступных пользователю */
	const { data = { holders: [] }, isLoading } = holderAPI.useFetchAllClientsQuery( locale );

	/* Инициализация медиа  */
	const matches = useMediaQuery( getMedia() );

	const backToTitle = useMemo( () => {
		let title = '';
		switch ( backToType ) {
			case 'cards': {
				title = fnLocalizeFilter( locale, '_lang.module.common.title.backToCards' );
				break;
			}
			case 'client':
			default : {
				title = fnLocalizeFilter( locale, '_lang.module.common.title.backToClients' );
				break;
			}
		}
		return title;
	}, [ backToType, locale ] );

	return <>
		<Container>
			<Grid>
				<BackTo onClick={ handleClose }>ᐊ { backToTitle } </BackTo>
			</Grid>
			<Grid style={ { padding: matches ? ".5rem 0 0 0" : "1rem 0 0 2rem" } }>
				{ children }
			</Grid>
		</Container>
	</>
}