import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IError {
	errorCode: number;
	errorDescription: string;
	errorObj? : any;
}

const initialState: IError = {
	errorCode: 0,
	errorDescription: 'Помилки відсутні',
	errorObj : null
}

const errorReducer = createSlice( {
	name: 'error',
	initialState,
	reducers: {
		catchError ( state: IError, action: PayloadAction<IError> ) {
			state.errorCode = action.payload.errorCode;
			state.errorDescription = action.payload.errorDescription;
		}
	}
} )

export default errorReducer;