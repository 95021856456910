import { TextFieldProps } from '@mui/material/TextField';
import { TextFieldFilled } from "../../../style/global";
import React from "react";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";


type IInputFilled = BaseTextFieldProps & TextFieldProps & { label: string } & { sx?: React.CSSProperties };

export default function InputFilled ( { label = "title", sx = {}, onChange, ...other }: IInputFilled ) {

	return (
		<TextFieldFilled
			label={ label.toUpperCase() }
			onChange={ onChange }
			variant="filled"
			sx={ { ...sx } }
			{ ...other }
		/>
	);
}
