import { IGlobalLocale } from "./locale.interface";

export const ua: IGlobalLocale = {
	module: {
		menu: {
			0: {
				title: "Клієнти",
				hint: "Довідник клієнтів"
			},
			1: {
				title: "Рахунки",
				hint: "Довідник рахунків"
			},
			2: {
				title: "Карти",
				hint: "Карти клієнтів"
			},
			3: {
				title: "Звіти",
				hint: "Звіти"
			},
			4: {
				title: "Документація",
				hint: "Документація"
			},
			5: {
				title: "Налаштування",
				hint: "Налаштування"
			}
		},
		login: {
			enterCRM: 'Увійти в CRM',
			welcomeText: 'Використайте дані для входу, отримані від адміністратора CRM',
			titleLogin: 'Логін',
			titlePassword: 'Пароль',
			btnRegister: 'Реєстрація',
			btnForgotPass: 'Забули пароль',
			btnEnter: 'Увійти',
			btnLogout: 'Вийти'
		},
		register: {
			registerCRM: 'Реєстрація в CRM',
			updateCRM: 'Заміна паролю',
			welcomeTextPhone: 'Для реєстрації чи нагадування паролю, будь-ласка введіть номер телефону',
			welcomeTextOTP: 'Введіть ОТП пароль, який Вам було відправлено на вказаний номер телефону',
			titlePhone: 'Телефон',
			titleOTP: 'ОТП пароль',
			btnRegisterPhone: 'Відправити ОТП пароль',
			btnRegisterOTP: 'Зареєструватись',
			btnUpdatePassOTP: 'Оновити дані',
			titleEnterPass: 'Введіть пароль',
			titleEnterRePass: 'Повтор паролю'
		},
		clientList: {
			placeholderSearchString: 'Пошук по назві або ЄДРПОУ',
			titleAccounts: 'Рахунки',
			titlePage: 'Клієнти',
			titleID: 'ID',
			titleNameClient: 'Назва клієнта',
			titleOKPO: 'ЄДРПОУ',
			titleINN: 'ІПН',
			titlePhone: 'Телефон',
			titleEmail: 'Пошта',
			titleAddress: 'Адреса',
			titleLegalAddress: 'Юридична адреса',
			titleNumContract: 'Номер договору',
			titleStatus: 'Статус клієнта',
		},
		accounts: {
			titleID: 'ID',
			titleRemark: 'Опис',
			titleRest: 'Залишок',
			titleOverdraft: 'Овердрафт',
			titleTotalBalance: 'Доступно',
			titleTotalBalanceHint: 'Баланс рахунку',
			titlePurses: 'Гаманці',
			titleTotalBalanceWithoutHold: 'Баланс',
			titleTotalBalanceWithoutHoldHint: 'Баланс рахунку без урахування холдів',
			titleCardsQty: 'Карти (кіл-ть)',
			titleCards: 'Карти',
			btnAccountLink: 'Перейти до рахунків'
		},
		cards: {
			titleID: 'Карта',
			titleVin: 'VIN',
			titlePhone: 'Телефон',
			titleName: 'Прізвище, ім\'я',
			titleSetting: 'Налаштування',
			titleConfirmRenewPin: 'Ви дійсно бажаєте змінити поточний pin?',
			titleShowNewPin: 'Новий PIN',
			titleBtnSendOtp: 'Відправити ОТП',
			titleCodeFromSMS: 'Код з смс',
			titleBtnCommit: 'Змінити номер',
			limits: {
				titleBtnAddLimit: 'Додати ліміт',
				titlePurses: 'Гаманці',
				titleMoneyByDay: 'Грн/Доба',
				titleQtyByDay: 'Літри/Доба',
				titleMoneyByMonth: 'Грн/Місяць',
				titleQtyByMonth: 'Літри/Місяць',
				titleBalance: 'Обіг',
				titleLimitAmount : 'Значення ліміту',
				titleLimitBalance : 'Залишок по ліміту',
				newLimit: {
					title: 'Рахунок',
					titleIndividual: 'Індивідуальний',
					titleCommon: 'Загальний',
					titleTypeLimit: 'Тип ліміту',
					titleLimitAmount: 'Сума'
				}
			},
			qr: {
				notMatchResolution : "Не підходить роздільна здатність екрану, будь ласка, поверніть телефон",
				contextMenu: {
					blocked: 'Блокування',
					confirmBlockedCard: 'Ви дійсно бажаєте заблокувати карту?',
					limits: 'Перегляд лімітів'
				},
				status: {
					0: "Заблокована",
					1: "Активна"
				}
			}
		},
		reports: {
			titleTrnKind: 'Вид звіту',
			titleDate: 'Дата',
			titleCard: 'Карта',
			titleAddress: 'Адреса АЗС',
			titlePurse: 'Гаманець',
			titleQty: 'Кіл-ть',
			titlePrice: 'Ціна',
			titleSum: 'Сума',
			titleDiscount: 'Знижка',
			titleSumWithDiscount: 'Сума зі знижкой',
			titleName: 'Назва',
			titleUseFromDate: 'Від дати',
			titleUseToDate: 'До дати'
		},
		setting: {
			titleChangePass: 'Змінити пароль',
			titleChangeUser: 'Користувачі',
			titleAddNewUser: 'Додати нового користувача',
			users: {
				titleFio: 'Прізвище, ім\'я',
				titleFirstName: 'Ім\'я',
				titleLastName: 'Прізвище',
				titlePhone: 'Телефон',
				titleEmail: 'Email',
				titleApiRole: 'Роль'
			}
		},
		dataGroup: {
			infoClient: 'Інформація клієнта',
			accounts: 'Рахунки',
			analytics: 'Аналітика',
			limits: 'Лімити',
			additionalInfo: 'Додаткова інформація',
			analyticInfo: 'Аналітика'
		},
		common: {
			table: {
				num: '#',
				active: 'Статус',
				filter: 'Фільтр',
				clearFilter: 'Очистити фильтр',
				emptyRows: 'Рядки відсутні',
				setting: 'Налаштування'
			},
			filter: {
				title: 'Фільтр',
				titleSubmit: 'Застосувати',
				titleStatus: 'Статус',
				titleIsActive: 'Активний',
				titleIsBlock: 'Заблоковано',
				titleBalance: 'Баланс',
				titleNumCard: 'Номер карти',
				titlePhone: 'Телефон',
				titleVIN: 'VIN',
				titleFirstName: 'Ім\'я',
				titleLastName: 'Прізвище',
				titleFrom: 'Від',
				titleTo: 'До',
				titleAccountName: 'Назва',
				titleSelectAll: 'Всі'
			},
			title: {
				backToClients: 'Назад до списку клієнтів',
				backToCards: 'Назад до списку карт',
				titleClientComboBox: 'Клієнт',
				titleAccountComboBox: 'Рахунок',
				titleCards: 'Карти',
				titleDataNotFound: 'Дані не знайдені',
				titleNotInitialized: 'Не визначено',
				titleCancel: 'Відміна',
				titlePeriod: 'Період',
				titleDocumentation: 'Документація',
				titleSearchStr: 'Пошукова строка',
				titleSelected: 'Обрано'
			},
			modal: {
				type: {
					info: 'Інформація',
					success: 'Успішне виконання',
					warning: 'Попередження',
					error: 'Помилка',
					confirmation: 'Підтвердження',
					default: ''
				},
				labelConfirmation: 'Ви дійсно бажаєте видалити строку?'
			}
		}
	},
	statusClient: {
		0: "Активний",
		1: "Заблокований",
		2: "Видалений",
		3: "Переміщений"
	},
	error: {
		1: "Введені дані не є номером телефону",
		2: "Номер телефону не знайдено",
		3: "Не корректний OTP",
		4: "Користувач був зареєстрований раніше",
		5: "Користувач не знайдений",
		6: "Невірний пароль або номер телефону (email)",
		7: "Клієнт не належить користувачеві. (Якщо йде запит холдера (рахунки, карти), який не прив'язаний до користувача)",
		8: "Помилкові параметри",
		9: "Помилка під час створення ліміту",
		10: "Не вдалось встановити новий пин",
		1000: "Невідома помилка",
		1001: "Користувач не авторизован",
		1002: "Операція виконана успішно",
		1003: "Під час виконання сталася помилка",
		1004: "Не коректний номер телефону",
		1005: "Помилка запиту",
		1006: "Запит не авторизован",
		1007: "Заповнене поле не вірного формату",
		validation: {
			fieldRequired: 'Обов\'язкове поле',
			fieldErrorEmail: 'Некоректний email'
		}
	}
}
