import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Box } from "@mui/material";

type IInputFilled = TextFieldProps & {
	placeholder: string;
}

const TextFieldSearch = styled( TextField )( ( { theme } ) => ( {
	width: "400px",
	backgroundColor: "#EDEDED",
	[ theme.breakpoints.down( 'md' ) ]: {
		width: "325px",
	}
} ) );

export default function InputSearchLine ( { placeholder, onChange, ...other }: IInputFilled ) {

	return (
		<Box sx={ {
			"& .MuiOutlinedInput-root": { height: "45px" },
			"& .MuiOutlinedInput-notchedOutline": { borderColor: "transparent" }
		} }>
			<TextFieldSearch
				onChange={ onChange }
				placeholder={ placeholder }
				style={ { marginTop: 11, borderRadius: "5px" } }
				{ ...other }
			/>
		</Box>
	);
}