import { IColumn } from "../../../../UI/CustomTable/components/Columns/Columns";
import { fnLocalizeFilter, ILocale } from "../../../../../services/localize.service";

interface IGetColumns {
	locale: ILocale;
}

export const getColumns = ( { locale }: IGetColumns ): IColumn[] => {
	return [
		{
			field : 'lastName',
			label:  fnLocalizeFilter( locale, '_lang.module.setting.users.titleLastName' ),
			formatHeader: 'default',
			cell : {format: 'default'},
			align: 'center',
			alignData: 'center'
		},
		{
			field : 'firstName',
			label:  fnLocalizeFilter( locale, '_lang.module.setting.users.titleFirstName' ),
			formatHeader: 'default',
			cell : {format: 'default'},
			align: 'center',
			alignData: 'center'
		},
		{
			field : 'cardId',
			label: fnLocalizeFilter( locale, '_lang.module.cards.titleID' ),
			formatHeader: 'default',
			cell : {format: 'default'},
			align: 'center',
			alignData: 'center'
		},
		{
			field : 'mobile',
			label: fnLocalizeFilter( locale, '_lang.module.cards.titlePhone' ),
			formatHeader: 'default',
			cell : {format: 'default'},
			align: 'center',
			alignData: 'center'
		}
	]
}
