import { Grid, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from "@mui/material";
import { TTypeCollapsibleColumn } from "../../../../interface/interface.custom";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../../../../../services/context.service";
import { IRow } from "../../../Rows/Rows";
import { getMedia } from "../../../../../../../helpers/media";
import { IResReportProducts } from "../../../../../../../services/response.interface";
import { fnLocalizeFilter } from "../../../../../../../services/localize.service";
import { styled } from "@mui/material/styles";

interface ICollapsibleType {
	type?: TTypeCollapsibleColumn;
	row: IRow;
}

const TableCellCenter = styled( TableCell )( () => ( {
	textAlign: 'center'
} ) );

export default function ReportTrnGoodsList ( { type, row }: ICollapsibleType ) {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();
	/* Инициализация медиа  */
	const matches = useMediaQuery( getMedia() );

	const [ newTable, setNewTable ] = useState<IRow>(row);

	useEffect( () => {
		setNewTable( row );
	}, [ row ] );

	return (
		<Grid item xs={ 6 } sx={ { margin: `${ matches ? "1rem 0 1rem 1rem" : "1rem 0 1rem 10rem" }` } }>
			<Table size="small" aria-label="purchases">
				<TableHead>
					<TableRow>
						<TableCell>{ fnLocalizeFilter( locale, '_lang.module.reports.titleName' ) }</TableCell>
						<TableCellCenter>{ fnLocalizeFilter( locale, '_lang.module.reports.titlePrice' ) }</TableCellCenter>
						<TableCellCenter>{ fnLocalizeFilter( locale, '_lang.module.reports.titleQty' ) }</TableCellCenter>
						<TableCellCenter>{ fnLocalizeFilter( locale, '_lang.module.reports.titleDiscount' ) }</TableCellCenter>
						<TableCellCenter>{ fnLocalizeFilter( locale, '_lang.module.reports.titleSum' ) }</TableCellCenter>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						newTable.products && newTable.products.map( ( p: IResReportProducts, idx: number ) => (
							<TableRow hover key={ p.goodsId }>
								<TableCell>{ p.name }</TableCell>
								<TableCellCenter> { p.price } </TableCellCenter>
								<TableCellCenter>{ p.quantity }</TableCellCenter>
								<TableCellCenter>{ p.discount }</TableCellCenter>
								<TableCellCenter>{ p.amount }</TableCellCenter>
							</TableRow>
						) )
					}
				</TableBody>
			</Table>
		</Grid>
	)
}