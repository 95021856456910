import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>( ( theme ) => ( {
	btnRegister: {
		"&:hover ": {
			color: theme.palette.primary.main,
			cursor: "pointer"
		}
	}
} ) );