import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TitleBlock from "../../TitleBlock/TitleBlock";
import InputFilled from "../../Input/InputFilled";
import React, { useEffect, useState } from "react";
import InputCheckbox from "../../Input/InputCheckbox";
import { useFilterAccountContext } from "../../../Module/Accounts/Filter/context.account";
import { IFilterAccountsField, initialStateFilterAccount } from "../../../Module/Accounts/Filter/interface.filter";
import { fnLocalizeFilter } from "../../../../services/localize.service";
import { useGlobalContext } from "../../../../services/context.service";

const Title = styled( Typography )( () => ( {
	fontSize: "2rem",
	fontWeight: 600,
	textAlign: "center"
} ) );

const FilterInput = styled( InputFilled )( ( props ) => {
	const { property } = props;
	const style = property === 'fullWidth'
		? { maxWidth: "initial", marginTop: "2rem" }
		: { maxWidth: "150px", marginTop: "0 !important" };

	return ( {
		...style,
		marginRight: property === 'left' ? ".5rem" : "0",
		marginLeft: property === 'right' ? ".5rem" : "0"
	} )
} );


interface IFilterAccounts {
	onFilterChange ( filter: IFilterAccountsField | any ): void;
}

export default function FilterAccounts ( { onFilterChange }: IFilterAccounts ) {
	/* Получаем вильтры из Аккаунтов */
	const { filter } = useFilterAccountContext();
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	const [ formFilter, setFilter ] = useState<IFilterAccountsField>( initialStateFilterAccount );

	useEffect( () => {
		filter && setFilter( prev => ( { ...prev, ...filter } ) )
	}, [ filter ] )

	const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		const { id, value, checked } = e.currentTarget;
		let newFilter = formFilter;
		switch ( id ) {
			case "isActive":
			case "isBlock": {
				newFilter = { ...newFilter, active: { ...newFilter.active, [ id ]: checked } };
				break;
			}
			case "name": {
				newFilter = { ...newFilter, name: value };
				break;
			}
			/* balance */
			default : {
				newFilter = { ...newFilter, balance: { ...newFilter?.balance, [ id ]: +value } };
				break;
			}
		}

		setFilter( newFilter )
		onFilterChange( newFilter );
	};

	return <Box sx={ { minWidth: "300px" } }>
		<Title>{fnLocalizeFilter(locale, '_lang.module.common.filter.title')}</Title>
		<TitleBlock title={ fnLocalizeFilter(locale, '_lang.module.common.filter.titleStatus') }>
			<Box display={ "flex" } flexDirection={ "column" }>
				<InputCheckbox label={ fnLocalizeFilter(locale, '_lang.module.common.filter.titleIsActive') } id={ 'isActive' } onChange={ handleChange }
				               defaultChecked={ formFilter.active.isActive }/>
				<InputCheckbox label={ fnLocalizeFilter(locale, '_lang.module.common.filter.titleIsBlock') } id={ 'isBlock' } onChange={ handleChange }
				               defaultChecked={ formFilter.active.isBlock }/>
			</Box>

		</TitleBlock>
		<TitleBlock title={ fnLocalizeFilter(locale, '_lang.module.common.filter.titleBalance') } sx={ { marginTop: "1.5rem" } }>
			<Box display={ "flex" } flexDirection={ 'column' }>
				<Box display={ "flex" }>
					<FilterInput id={ 'from' } onChange={ handleChange } label={ fnLocalizeFilter(locale, '_lang.module.common.filter.titleFrom') } property={ 'left' }
					             value={ formFilter.balance.from } type={ "number" }/>
					<FilterInput id={ 'to' } onChange={ handleChange } label={ fnLocalizeFilter(locale, '_lang.module.common.filter.titleTo') } property={ 'right' }
					             value={ formFilter.balance.to } type={ "number" }/>
				</Box>
				<FilterInput id={ 'name' } onChange={ handleChange } label={ fnLocalizeFilter(locale, '_lang.module.common.filter.titleAccountName') } property={ 'fullWidth' }
				             value={ formFilter.name }/>
			</Box>
		</TitleBlock>
	</Box>
}