import InputFilled from "../Input/InputFilled";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import SaveAsIcon from '@mui/icons-material/SaveAs';
// import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
// import ClearIcon from '@mui/icons-material/Clear';
import { Grid, IconButton } from "@mui/material";
import { TTypeTextParams } from "../SettingBlock/TextParams";

interface ITextEditor {
	label: string;
	val: string;
	type: TTypeTextParams;

	onCancelEdit (): void;

	onSaveEdit ( val: string ): Promise<boolean>;
}

export const InputSave = styled( InputFilled )( () => ( {
	marginTop: 0,
	"& input": {
		paddingRight: '3.7rem',
	}
} ) );

const Container = styled( Grid )( () => ( {

	position: 'relative'
} ) );

const ContainerBtn = styled( Grid )( () => ( {
	position: 'absolute',
	right: 0,
	top: "45%"
} ) );

const IconButtonCustom = styled( IconButton )( ( props ) => ( {
	padding: ".2rem",
	"&:hover": {
		color: props.property === 'apply' ? '#4a9a4a' : '#c75b5b'
	}
} ) );

export default function TextEditor ( { label, val, type, onCancelEdit, onSaveEdit }: ITextEditor ) {
	const [ value, setValue ] = useState<string>( '' );

	useEffect( () => {
		setValue( val )
	}, [ val ] )

	const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		setValue( e.currentTarget.value );
	};

	const handleSaveChanges = async () => {
		/* Пытаемся сохранить запись на сервере */
		await onSaveEdit( value )
			.then( res => {
				if ( res ) {
					onCancelEdit();
				}
			} );
	};


	return <Container>
		<InputSave label={ label } onChange={ handleChange } value={ value }
		           type={ `${ type === 'phone' ? 'number' : 'text' }` } sx={ { marginTop: "0 !important" } }/>
		<ContainerBtn>
			<IconButtonCustom property={ 'apply' } onClick={ handleSaveChanges }><SaveAsIcon/></IconButtonCustom>
			<IconButtonCustom property={ 'cancel' } onClick={ onCancelEdit }><CancelIcon/></IconButtonCustom>
		</ContainerBtn>

	</Container>
}