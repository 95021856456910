import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useCustomStyles } from "../../../style/global";
import { styled } from "@mui/material/styles";

export type IModalType = "error" | "default" | "withoutBtn";

export interface IModalMessage {
	isOpen: boolean;
	type?: IModalType;
	children?: React.ReactElement<any, any>;
	btnOkTitle?: string;
	btnCancelTitle?: string;
	disabled?: boolean;

	test? (): void;

	handleBtnOk? (): void;

	handleBtnCancel? ( state: boolean ): void;
}

const CustomDialog = styled( Dialog )( () => ( {
	"& .MuiPaper-root": {
		maxWidth: "initial", borderRadius: "7px"
	}
} ) );

const CustomDialogContent = styled( DialogContent )( () => ( {
	overflowY: "auto", flex: "1 1 auto", whiteSpace: "pre-wrap"
} ) );


export default function Modal ( {
	                                isOpen = false,
	                                type = 'default',
	                                children = <></>,
	                                btnOkTitle = "Так",
	                                btnCancelTitle = "Відміна",
	                                handleBtnOk,
	                                disabled = false,
	                                handleBtnCancel,
	                                test
                                }: IModalMessage ) {
	const classes = useCustomStyles();

	const handleClose = () => {
		handleBtnCancel && handleBtnCancel( false );
	};

	const handleSubmit = () => {
		type !== 'error' && handleBtnOk && handleBtnOk()

	};

	return (

		<CustomDialog
			open={ isOpen }
			onClose={ () => handleClose() }
			className={ classes.titleDefault }
			scroll={ 'paper' }
		>

			<CustomDialogContent>{ children }</CustomDialogContent>

			{
				type === 'withoutBtn'
					? <></>
					: <DialogActions>
						{
							test && <Button onClick={ () => {
								test();
							} }>LOG</Button>
						}

						<Button variant={ 'contained' } color={ 'secondary' } disabled={ disabled } onClick={ () => {
							handleSubmit()
						} }>{ btnOkTitle }</Button>

						{
							[ 'error' ].indexOf( type ) === -1 &&
                <Button variant={ 'contained' } color={ 'secondary' } onClick={ () => {
									handleClose()
								} }>{ btnCancelTitle }</Button>
						}
					</DialogActions>
			}

		</CustomDialog>
	);
}