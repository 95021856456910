import React, { useState } from "react";
import GetNewPass from "../../../Auth/Register/GetNewPass";
import { Grid } from "@mui/material";
import GetPhone from "../../../Auth/Register/GetPhone";
import { fnLocalizeFilter } from "../../../../../services/localize.service";
import InputPhone from "../../../../UI/Input/InputPhone";
import { useGlobalContext } from "../../../../../services/context.service";
import SendOTP from "./SendOTP";
import { showTooltip } from "../../../../../store/actions/layoutActions";
import { accountAPI } from "../../../../../services/accountAPI.service";
import { useAppDispatch } from "../../../../../hooks/redux";
import InputNewPassword from "./InputNewPassword";
import { authAPI } from "../../../../../services/authAPI.service";

interface IStatePass {
	phone: string;
	pass: string;
	rePass: string;
	otp: string;
}

export default function ChangePassword () {

	const { locale } = useGlobalContext();
	const [ isSendOTP, setSendOTP ] = useState<boolean>( false );
	const [ newPass, setPass ] = useState<IStatePass>( {
		phone: '',
		pass: '',
		rePass: '',
		otp: ''
	} );

	const [ getRegister ] = authAPI.useLazyUserRegisterOrForgotQuery();
	const [ sendOTP ] = accountAPI.useLazySendOTPQuery();
	const dispatch = useAppDispatch();

	const handleChangePass = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		const { name, value } = e.target;
		const newState = {
			...newPass,
			[ name ]: value
		}

		setPass( newState );
	};

	const handleOnChangePhone = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		setPass( prev => ( { ...prev, phone: e.target.value } ) )
	};

	const handleSendOTP = () => {
		if ( newPass.phone.length < 12 ) {
			dispatch( showTooltip( { type: "error", message: fnLocalizeFilter( locale, '_lang.error.1004' ) } ) );
		} else {
			newPass.phone && sendOTP( { locale, phone: newPass.phone } ).then( res => {
				if ( res && res.data && res.data.errorCode === 0 ) {
					setSendOTP( true );
				}
			} )
		}
	};

	const handleUpdatePass = () => {
		/* Действие с паролем */
		getRegister( { locale, otp: newPass.otp, phone: newPass.phone, password: newPass.pass, type: 'forgotPass' } );
	};


	return <Grid container>
		<Grid item xs={ 12 } md={ 3 }>
			{
				isSendOTP
					? <InputNewPassword onChange={ handleChangePass } pass={ newPass.pass } rePass={ newPass.rePass } onUpdatePass={handleUpdatePass}/>
					: <SendOTP onChange={ handleOnChangePhone } value={ newPass.phone } onSendOTP={ handleSendOTP }/>
			}
		</Grid>
	</Grid>
}