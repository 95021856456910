import { GridLocaleText } from "@mui/x-data-grid";

export const GRID_UA_LOCALE_TEXT: GridLocaleText = {
	// Root
	noRowsLabel: 'Рядки відсутні',
	noResultsOverlayLabel: 'Результат не знайдено.',
	errorOverlayDefaultLabel: 'Виникла помилка.',
	rowReorderingHeaderName : '',

	// Density selector toolbar button text
	toolbarDensity: 'Щільність',
	toolbarDensityLabel: 'Щільність',
	toolbarDensityCompact: 'Компакт',
	toolbarDensityStandard: 'Стандарт',
	toolbarDensityComfortable: 'Удобный',

	// Columns selector toolbar button text
	toolbarColumns: '',
	toolbarColumnsLabel: 'Виберіть стовпці',

	// Filters toolbar button text
	toolbarFilters: 'Фільтри',
	toolbarFiltersLabel: 'Показати фільтри',
	toolbarFiltersTooltipHide: 'Приховати фільтри',
	toolbarFiltersTooltipShow: 'Показати фільтри',
	toolbarFiltersTooltipActive: (count: number) =>
		count !== 1 ? `${count} активні фільтри` : `${count} активний фільтр`,

	// Export selector toolbar button text
	toolbarExport: 'Експорт',
	toolbarExportLabel: 'Експорт',
	toolbarExportCSV: 'Завантажити у форматі CSV',
	toolbarExportPrint: 'Печать',

	// Columns panel text
	columnsPanelTextFieldLabel: 'Знайти стовпець',
	columnsPanelTextFieldPlaceholder: 'Назва стовпця',
	columnsPanelDragIconLabel: 'Змінити порядок стовпця',
	columnsPanelShowAllButton: 'Показати всі',
	columnsPanelHideAllButton: 'Приховати все',

	// Filter panel text
	filterPanelAddFilter: 'Add filter',
	filterPanelDeleteIconLabel: 'Delete',
	filterPanelLinkOperator: 'Logic operator',
	filterPanelOperators: 'Operator', // TODO v6: rename to filterPanelOperator
	filterPanelOperatorAnd: 'And',
	filterPanelOperatorOr: 'Or',
	filterPanelColumns: 'Columns',
	filterPanelInputLabel: 'Value',
	filterPanelInputPlaceholder: 'Filter value',

	// Filter operators text
	filterOperatorContains: 'содержит',
	filterOperatorEquals: 'equals',
	filterOperatorStartsWith: 'starts with',
	filterOperatorEndsWith: 'ends with',
	filterOperatorIs: 'is',
	filterOperatorNot: 'is not',
	filterOperatorAfter: 'is after',
	filterOperatorOnOrAfter: 'is on or after',
	filterOperatorBefore: 'is before',
	filterOperatorOnOrBefore: 'is on or before',
	filterOperatorIsEmpty: 'is empty',
	filterOperatorIsNotEmpty: 'is not empty',
	filterOperatorIsAnyOf: 'is any of',

	// Filter values text
	filterValueAny: 'any',
	filterValueTrue: 'true',
	filterValueFalse: 'false',

	// Column menu text
	columnMenuLabel: 'Меню',
	columnMenuShowColumns: 'Показати стовпці',
	columnMenuFilter: 'Фільтр',
	columnMenuHideColumn: 'Приховати',
	columnMenuUnsort: 'Скасувати сортування',
	columnMenuSortAsc: 'За збільшенням',
	columnMenuSortDesc: 'По зменшенню',

	// Column header text
	columnHeaderFiltersTooltipActive: (count: number) =>
		count !== 1 ? `${count} active filters` : `${count} active filter`,
	columnHeaderFiltersLabel: 'Показати фільтри',
	columnHeaderSortIconLabel: 'Сортування',

	// Rows selected footer text
	footerRowSelected: (count: number) =>
		count !== 1
			? `${count.toLocaleString()} рядки вибрано`
			: `${count.toLocaleString()} рядок вибрано`,

	// Total row amount footer text
	footerTotalRows: 'Усього рядків:',

	// Total visible row amount footer text
	footerTotalVisibleRows: (visibleCount: { toLocaleString: () => any; }, totalCount: { toLocaleString: () => any; }) =>
		`${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

	// Checkbox selection text
	checkboxSelectionHeaderName: 'Вибір прапорця',
	checkboxSelectionSelectAllRows: 'Вибрати усі рядки',
	checkboxSelectionUnselectAllRows: 'Скасувати вибір всіх рядків',
	checkboxSelectionSelectRow: 'Виберіть рядок',
	checkboxSelectionUnselectRow: 'Скасувати вибір рядка',

	// Boolean cell text
	booleanCellTrueLabel: 'да',
	booleanCellFalseLabel: 'ні',

	// Actions cell more text
	actionsCellMore: 'більше',

	// Column pinning text
	pinToLeft: 'Закріпити зліва',
	pinToRight: 'Закріпити праворуч',
	unpin: 'Відкріпити',

	// Tree Data
	treeDataGroupingHeaderName: 'Група',
	treeDataExpand: 'see children',
	treeDataCollapse: 'hide children',

	// Grouping columns
	groupingColumnHeaderName: 'Група',
	groupColumn: (name) => `Група по ${name}`,
	unGroupColumn: (name) => `Скасування угруповання по ${name}`,

	// Master/detail
	expandDetailPanel: 'Розкрити',
	collapseDetailPanel: 'Закрити',

	// Used core components translation keys
	MuiTablePagination: {},

	toolbarQuickFilterPlaceholder: "",
	toolbarQuickFilterLabel: "",
	toolbarQuickFilterDeleteIconLabel: "",
	toolbarExportExcel: ""
};
