import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from "@mui/material";

import './style/main.css';
import Auth from "./components/Module/Auth/Auth";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import Context from './services/context.service';
import MainLayout from "./components/Module/Main/Layout/MainLayout";
import { getDataFromLocalStorage } from "./utils/methods";
import { userLogin, userLogout } from "./store/actions/userAction";
import { IPermTypes } from "./store/reducers/userReducer";
import { ukUA } from "@mui/material/locale";
import ModalMessage from "./components/UI/Modal/ModalOld";

const theme = createTheme( {
	palette: {
		mode: "light",

		primary: {
			main: '#000',
			light: '#fff',
			dark: '#999',
			contrastText: '#d3d3d3'
		},
		secondary: {
			main: '#2C78D9',
			light: '#6ea6ff',
			dark: '#004da7',
			contrastText: '#fdfdfd'
		},
		contrastThreshold: 3,
		divider: '#000'
	}
}, ukUA );


function App () {

	/* Получение языковой локали */
	const { locale } = useAppSelector( state => state.user );

	const { isAuth } = useAppSelector( state => state.user.users )

	const dispatch = useAppDispatch();

	const [ customTheme, setCustomTheme ] = useState<any>();

	const getCustomTheme = async () => {
		const theme = await fetch( 'config.json' )
			.then( function ( response ) {
				return response.json();
			} );

		theme && setCustomTheme( createTheme( theme.profile ) );
	};


	/* Проверка на существующую авторизацию, сразу логинимся если есть прошлая атворизация в localStorag'e */
	useEffect( () => {

		const token: { token: string, isAuth: boolean } = JSON.parse( getDataFromLocalStorage( 'token' ) as string );

		if ( token && token.isAuth ) {

			const permissions: IPermTypes[] = JSON.parse( getDataFromLocalStorage( 'permissions' ) as string );

			dispatch( userLogin( { permissions } ) );
		} else {
			dispatch( userLogout() );
		}

		/* Попытка подвязать кастомную тему из json */
		getCustomTheme().then()

	}, [] );

	//const matches = useMediaQuery( '(min-width:600px)' );

	return (
		<ThemeProvider theme={ customTheme ? customTheme : theme }>
			<Context.Provider value={ { locale } }>
				{
					isAuth
						? <MainLayout/>
						: <Auth/>
				}
				<ModalMessage/>
			</Context.Provider>
		</ThemeProvider>
	);
}

export default App;
