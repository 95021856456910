import { holderAPI } from "../holderAPI.service";

export const updateQuery = ( {
	                             locale,
	                             holderId,
	                             newAccounts
                             }: any ) => holderAPI.util.updateQueryData( 'fetchAccountsClient',
	( { locale, holderId } ), ( draft: any ) => {
		Object.assign( draft, { accounts: newAccounts } )
	} )
