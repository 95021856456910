import { AppDispatch } from "../store";
import errorReducer, { IError } from "../reducers/errorReducer";


export const catchError = ( error: IError ) => async ( dispatch: AppDispatch ) => {
	try {

		dispatch( errorReducer.actions.catchError( {
			errorCode: error.errorCode,
			errorDescription: error.errorDescription
		} ) );

	} catch ( e: any ) {
		console.error( e.message )
	}
}