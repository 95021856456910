import Modal from "../../../../UI/Modal/Modal";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CustomTable from "../../../../UI/CustomTable";
import { fnLocalizeFilter } from "../../../../../services/localize.service";
import { cardAPI } from "../../../../../services/cardAPI.service";
import { useGlobalContext } from "../../../../../services/context.service";
import { getColumns } from "./card.columns";
import InputSearchLine from "../../../../UI/Input/InputSearchLine";
import { filterObject } from "../../../../../utils/methods";

interface IModalFilterCard {
	isShow: boolean;
	holderId: number;
	handleApplyCardFilter (card: string):void;
	handleClose (): void;
}

export default function ModalFilterCard ( { isShow, holderId, handleClose, handleApplyCardFilter}: IModalFilterCard ) {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	const [ getListCard, { data: dataCards = { cards: [] }, isLoading } ] = cardAPI.useLazyFetchCardsByHolderQuery();

	useEffect( () => {
		getListCard( { locale, holderId } );
	}, [] );

	/* Строка поиска по всем полям таблицы */
	const [ searchStr, setSearchStr ] = useState( '' );
	const [ selectedCard, setSelectedCard ] = useState( '' );

	/* Фильтр по столбцам таблицы  */
	const rows = useMemo( () => {
		let count = 10;

		let filteredArr = dataCards.cards;

		filteredArr = filterObject( {
			arr: filteredArr,
			fields: [ 'lastName', 'firstName', 'mobile', 'cardId' ],
			searchStr
		} );

		return { arr: filteredArr, count };
	}, [ dataCards, searchStr ] );

	/* Заполняем табличные заголовки */
	const columns = useMemo( () => {
		return getColumns( { locale } );
	}, [ locale ] );

	const handleApplyCard = ()=>{
		handleApplyCardFilter(selectedCard);
		handleClose();
	};

	return <Modal
		handleBtnOk={ handleApplyCard }
		isOpen={ isShow }
		handleBtnCancel={ handleClose }
		disabled={ false }
	>
		<Grid item sx={ {
			minWidth: '700px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between'
		} }>
			<Grid sx={ { marginBottom: '2rem' } } display={ 'flex' } alignItems={ 'center' }
			      justifyContent={ 'space-between' }>
				<InputSearchLine
					placeholder={ fnLocalizeFilter( locale, '_lang.module.common.title.titleSearchStr' ) }
					onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => {
						setSearchStr( e.currentTarget.value );
					} }
					style={ { borderRadius: "5px" } }
				/>
				<Typography>{fnLocalizeFilter(locale, '_lang.module.common.title.titleSelected')}: { selectedCard || ' - ' }</Typography>
			</Grid>
			<Grid>
				{
					<CustomTable
						rows={ rows.arr }
						columns={ columns }
						isLoading={ isLoading }
						labelEmptyRows={ fnLocalizeFilter( locale, '_lang.module.common.table.emptyRows' ) }
						isSimple
						onClick={ ( row ) => setSelectedCard( row.cardId ) }
					/>
				}
			</Grid>
		</Grid>
	</Modal>
}
