import React, { useMemo } from 'react';
import { IMaskInput } from 'react-imask';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { TextFieldProps } from "@mui/material/TextField";
import { useCustomStyles } from "../../../style/global";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";

interface CustomProps {
	onChange: ( e: { target: { name: string; value: string; } } ) => void;
	placeholder: string;
	name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
	function TextMaskCustom ( props, ref ) {
		const { onChange, placeholder, ...other } = props;

		const onAccept = ( value: any, mask: any ) => {
			onChange( { target: { name: props.name, value: `380${ mask._unmaskedValue }` } } )
		};

		return (
			<IMaskInput
				{ ...other }
				mask="38(\0xx) xxx-xx-xx"
				definitions={ { 'x': /[0-9]/ } }
				placeholder={ placeholder }
				onAccept={ onAccept }
				overwrite
			/>
		);
	},
);


type IInputMask = BaseTextFieldProps & TextFieldProps & {
	label?: string;
	value: string;
	sx?: React.CSSProperties;
}

export default function InputPhone ( { value, label = '', sx = {}, onChange }: IInputMask ) {
	const classes = useCustomStyles();

	const renderInputPhone = useMemo( () => {
		return label !== ''
			? <Box sx={ {
				'& > :not(style)': { m: 1 },
				overflow: "hidden",
				...sx
			} }>
				<FormControl variant="standard" focused >
					<InputLabel htmlFor="formatted-phone-mask-input" className={ classes.textDefault }
					            style={ { color: 'inherit' } }
					>{ label.toUpperCase() }</InputLabel>
					<Input
						id={ "formatted-phone-mask-input" }
						value={ value }
						onChange={ onChange }
						inputComponent={ TextMaskCustom as any }
						placeholder={ '38(0xx) xxx-xx-xx' }
					/>
				</FormControl>
			</Box>
			:
			<Box sx={ {
				'& > :not(style)': { m: 0 },
				overflow: "hidden",
				...sx
			} }>
				<Input
					id={ "formatted-phone-mask-input" }
					value={ value }
					onChange={ onChange }
					inputComponent={ TextMaskCustom as any }
					placeholder={ '38(0xx) xxx-xx-xx' }
					sx={ { font: 'inherit', '& input': { padding: 0 } } }
				/>


			</Box>
	}, [ label, onChange ] )

	return renderInputPhone;
}
