import { useSnackbar } from "notistack";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { clearTooltip } from "../../../store/actions/layoutActions";

/**
 * Модуль подсказок
 */
export default function Tooltip () {
	const { enqueueSnackbar } = useSnackbar();

	const dispatch = useAppDispatch();
	const { tooltip: { type, message } } = useAppSelector( state => state.layout );

	useEffect( () => {
		if ( message?.length > 0 ) {
			enqueueSnackbar( message, { variant: type } );
			dispatch( clearTooltip() );
		}
	}, [type, message, enqueueSnackbar, dispatch] );


	return <></>
}