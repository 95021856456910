import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import Loading from "../../../../../UI/Loading/Loading";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useCustomStyles } from "../../../../../../style/global";
import { GritEditItem } from "../../../../../UI/SettingBlock/common";
import InputFilled from "../../../../../UI/Input/InputFilled";
import { fnLocalizeFilter } from "../../../../../../services/localize.service";
import { useGlobalContext } from "../../../../../../services/context.service";
import { TIMEOUT_VISIBILITY_PIN_AFTER_CHANGE } from "../../../../../../helpers/api";
import { accountAPI } from "../../../../../../services/accountAPI.service";
import { handleUpdatePhone } from "../../../utils/local.request.api";
import { useAppDispatch } from "../../../../../../hooks/redux";
import { ICards } from "../../../../../../services/response.interface";
import InputPhone from "../../../../../UI/Input/InputPhone";
import { showTooltip } from "../../../../../../store/actions/layoutActions";
import ProgressTimer from "../../../../../UI/Loading/ProgressTimer";

const Modal = lazy( () => import('../../../../../UI/Modal/Modal') );

const timeoutVisibility: number = +TIMEOUT_VISIBILITY_PIN_AFTER_CHANGE;

const IconButtonCustom = styled( IconButton )( () => ( {
	padding: 0
} ) );

interface IRenewPhone {
	title: string;
	row: any;
	accountId: number;
	editable?: boolean;
	cards: ICards[];
}

export default function RenewPhone ( { title, accountId, cards, editable = true, row }: IRenewPhone ) {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	const dispatch = useAppDispatch();

	const classes = useCustomStyles();

	const [ isOpenModal, setOpenModal ] = useState( false );
	const [ phone, setPhone ] = useState<string>( '' );
	const [ otp, setOtp ] = useState<string>( '0000' );
	const [ isSendOTP, setSendOTP ] = useState<boolean>( false );

	useEffect( () => {
		setPhone( row.mobile );
		setOtp( '0000' );
	}, [ row ] )

	const [ sendOTP ] = accountAPI.useLazySendOTPQuery();


	const handleEnterPhone = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		setPhone( e.target.value )
	};

	const handleChangePhone = async () => {
		await handleUpdatePhone( {
			row, locale, phone, otp, cards, accountId, dispatch,
		} ).then( res => {
			if ( res ) {
				timeoutSendingOTP();
				handleCloseModal();
			}
		} );
	};

	/* Открываем модальное окно ввода нового номера телефона */
	const handleOpenModal = () => {
		setOpenModal( true )
	};
	const handleCloseModal = () => {
		timeoutSendingOTP();
		setOpenModal( false )
	};

	const handleChangeOTP = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		setOtp( e.target.value )
	};

	const handleSendOTP = () => {
		if ( phone.length < 12 ) {
			dispatch( showTooltip( { type: "error", message: fnLocalizeFilter( locale, '_lang.error.1004' ) } ) );
		} else {
//			setSendOTP( true );
			phone && sendOTP( { locale, phone } ).then( res => {
				if ( res && res.data && res.data.errorCode === 0 ) {
					setSendOTP( true );
				}
			} )
		}


	};

	const timeoutSendingOTP = () => {
		setSendOTP( false );
	};


	return <>
		<Box className={ classes.textDefault }>{ title }</Box>
		<GritEditItem className={ classes.titleDefault }>
			<Typography>{ row.mobile }</Typography>
			{
				editable &&
          <Grid item xs={ 1 }>
            <IconButtonCustom onClick={ handleOpenModal }> <EditIcon/> </IconButtonCustom>
          </Grid>
			}

		</GritEditItem>
		{
			isOpenModal && <Suspense fallback={ <Loading type={ 'fullScreen' }/> }>
          <Modal
              handleBtnOk={ handleSendOTP }
              isOpen={ isOpenModal }
              type={ 'withoutBtn' }
          >
            <Box sx={ { width: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' } }>

              <InputPhone label={ title } value={ phone } onChange={ handleEnterPhone }/>
							{
								!isSendOTP
									? <Button
										variant={ 'contained' }
										color={ 'secondary' }
										onClick={ handleSendOTP }
									>
										{ fnLocalizeFilter( locale, '_lang.module.cards.titleBtnSendOtp' ) }
									</Button>
									:
									<>

										<Button
											variant={ 'contained' }
											color={ 'secondary' }
											onClick={ handleSendOTP }
											sx={ { position: 'relative' } }
											disabled={ true }
										>
											<Typography sx={ {
												marginRight: '1rem',
												fontSize: '.9rem'
											} }>{ fnLocalizeFilter( locale, '_lang.module.cards.titleBtnSendOtp' ) }</Typography>
											<ProgressTimer timer={ timeoutVisibility } type={ 'small' } onStopTimer={ timeoutSendingOTP }/>
										</Button>

										<InputFilled label={ fnLocalizeFilter( locale, '_lang.module.cards.titleCodeFromSMS' ) }
										             onChange={ handleChangeOTP } value={ otp }
										             type={ 'number' } sx={ { margin: "2rem 0 1rem 0 !important" } }/>
										<Button
											variant={ 'contained' }
											color={ 'secondary' }
											onClick={ handleChangePhone }
										>
											{ fnLocalizeFilter( locale, '_lang.module.cards.titleBtnCommit' ) }
										</Button>
									</>
							}
              <Button
                  variant={ 'contained' }
                  onClick={ handleCloseModal }
                  sx={ { marginTop: '2rem' } }
              >
								{ fnLocalizeFilter( locale, '_lang.module.common.title.titleCancel' ) }
              </Button>
            </Box>
          </Modal>
        </Suspense>
		}
	</>
};
