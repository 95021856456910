import { useNavigate, useParams } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";
import InnerContainer from "../../../Common/InnerContainer";
import BackToLink from "../../../Common/BackToLink";
import { useAppDispatch } from "../../../../hooks/redux";
import ClientGroupTitle from "../../../Common/ClientGroupTitle";
import CardInfo from "./Setting/CardInfo/CardInfo";
import { cardAPI } from "../../../../services/cardAPI.service";
import { useEffect, useState } from "react";
import { ICards } from "../../../../services/response.interface";
import { useGlobalContext } from "../../../../services/context.service";
import { getMedia } from "../../../../helpers/media";
import CardLimits from "./Setting/Limits/CardLimits";

export const initialCardState: ICards = {
	id: '',
	cardId: '',
	cardStatus: false,
	status: 0,
	vin: '',
	activatedDate: '',
	isVirtual: false,
	pinSet: false,
	cardInfo: {
		firstName: '',
		lastName: '',
		mobile: '',
		email: ''
	},
	firstName: '',
	lastName: '',
	mobile: '',
	email: ''
}

export default function CardSetting () {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();
	/* Получаем адренсную строку */
	let params = useParams<{ cardId: string, accountId: string, holderId: string  }>();
	/* Управление состоянием */
	const dispatch = useAppDispatch();
	/* Инициализация медиа  */
	const matches = useMediaQuery( getMedia() );
	/* Управляем линками */
	let navigate = useNavigate();

	const [ card, setCard ] = useState<ICards>( initialCardState );
	const [ accountId, setAccount ] = useState<number>( 0 );
	/* Получаем список карты выбранного счета */
	const [ getCards, {
		data: dataCards = { cards: [] },
		isLoading: isLoadingCards,
		isSuccess: isSuccessCard
	} ] = cardAPI.useLazyFetchCardsByAccountQuery();

	/* Получаем все доступные статусы карт */
	const { data: cardStatuses = { statuses: [] } } = cardAPI.useFetchCardsStatusQuery( { locale } );
	/* Получаем все доступные лимиты карт */
	const { data: cardTypeLimits = { types: [] } } = cardAPI.useFetchCardLimitsQuery( { locale } );
	/* Получаем все доступные кошельки карт */
	const { data: cardPurses = { purses: [] } } = cardAPI.useFetchCardPursesQuery( { locale } );

	useEffect( () => {


		let newCard;
		if ( dataCards.cards.length !== 0 ) {
			newCard = dataCards.cards.filter( card => card.cardId === params.cardId )[ 0 ];
			setCard( newCard );
		} else {

			if ( params && params.accountId ) {
				getCards( { locale, accountId: +params.accountId } )
					.then( res => {
						/* Выбираем карту после запроса на сервер */
						newCard = ( res.data && res.data.cards.length > 0 ) ? res.data.cards.filter( card => card.cardId === params.cardId )[ 0 ] : initialCardState;
						setCard( newCard );
						params.accountId && setAccount( +params.accountId );
					} );
			}
		}


	}, [ dataCards.cards ] );

	const handleClose = () => {
		navigate( `/holder/${params.holderId}/account/${params.accountId}/cards`, { replace: true } );
	};


	return <>
		<InnerContainer direction={ 'left' } show={ true }>
			<BackToLink handleClose={ handleClose } backToType={ 'cards' }>
				<Grid container style={ { flexDirection: 'column' } }>
					<ClientGroupTitle isLoading={ isLoadingCards } title={ `Карта: ${ card.cardId }` }
					                  sx={ { marginLeft: `${ matches ? '1rem' : '0' }` } }/>

					<CardInfo card={ card } cards={ dataCards.cards } accountId={ accountId } statuses={ cardStatuses.statuses }/>
					<Grid item xs={ 12 } md={ 8 }>
						<CardLimits card={ card } cards={ dataCards.cards } cardPurses={ cardPurses.purses }
						            cardTypeLimits={ cardTypeLimits.types }/>
					</Grid>
				</Grid>


			</BackToLink>
		</InnerContainer>
	</>
}