import { cardAPI } from "../../../../services/cardAPI.service";
import { useGlobalContext } from "../../../../services/context.service";
import { useEffect } from "react";
import Loading from "../../../UI/Loading/Loading";
import { styled } from "@mui/material/styles";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { ICardLimitsPurses, IDriverCardLimit } from "../../../../services/response.interface";
import { fnLocalizeFilter } from "../../../../services/localize.service";

const Container = styled( Box )( () => ( {
	marginTop: "2rem",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
} ) );

const TableData = styled( Table )( () => ( {
	marginBottom: "2rem"
} ) );

const TableCellData = styled( TableCell )( () => ( {
	textAlign: "center"
} ) );

interface ILimits {
	cardId: string;
}

export default function LimitsCardDriver ( { cardId }: ILimits ) {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	/* Получаем все доступные лимиты карт */
	const {
		data: cardTypeLimits = { types: [] },
		isLoading: isLoadingTypes
	} = cardAPI.useFetchCardLimitsQuery( { locale } );

	/* Получаем все доступные кошельки карт */
	const {
		data: cardPurses = { purses: [] },
		isLoading: isLoadingPurses
	} = cardAPI.useFetchCardPursesQuery( { locale } );

	const [ getLimits, { data: dataLimits, isLoading: isLoadingData } ] = cardAPI.useLazyFetchLimitCardDriverQuery();

	useEffect( () => {
		getLimits( { locale, cardId } )
	}, [ cardId, locale ] )


	if ( isLoadingTypes || isLoadingData || isLoadingPurses ) {
		return <Container><Loading type={ "big" }/></Container>
	}

	const getNameLimit = ( id: number ) => {
		const type = cardTypeLimits.types.filter( t => ( t.id === id ) )[ 0 ];
		return type.remark;
	}

	const getNamePurse = ( id: number ) => {
		const purse = cardPurses.purses.filter( purse => ( purse.purseId === id ) )[ 0 ];
		return purse.remark;
	}


	return (
		<TableContainer sx={ { width: 310, margin: "1rem auto" } }>
			{
				dataLimits && dataLimits.limits?.map( ( limit: IDriverCardLimit ) => (
					<Box key={ limit.limitId } style={ { border: "1px solid #ccc", borderRadius: "5px", backgroundColor:"#f6f6f6"   } } mb={ 2 }>
						<TableData size="small">
							<TableHead><TableRow>
								<TableCellData>{ fnLocalizeFilter( locale, "_lang.module.cards.limits.newLimit.titleTypeLimit" ) }</TableCellData>
								<TableCellData>{ getNameLimit( limit.limitType ) }</TableCellData>
							</TableRow></TableHead>
						</TableData>
						<TableData size="small">
							<TableHead><TableRow>
								<TableCellData>{ fnLocalizeFilter( locale, "_lang.module.cards.limits.titlePurses" ) }</TableCellData>
							</TableRow></TableHead>
							<TableBody>
								{
									limit.attachedPurses.map( ( purse: ICardLimitsPurses ) => (
										<TableRow key={ purse.purseId }>
											<TableCellData>{ getNamePurse( purse.purseId ) }</TableCellData>
										</TableRow>
									) )
								}
							</TableBody>
						</TableData>
						<Table size="small">
							<TableHead><TableRow>
								<TableCellData>{ fnLocalizeFilter( locale, "_lang.module.cards.limits.titleLimitAmount" ) }</TableCellData>
								<TableCellData>{ fnLocalizeFilter( locale, "_lang.module.cards.limits.titleLimitBalance" ) }</TableCellData>
							</TableRow></TableHead>
							<TableBody>

								<TableRow>
									<TableCellData>{ limit.limitAmount }</TableCellData>
									<TableCellData>{ limit.limitBalance }</TableCellData>
								</TableRow>

							</TableBody>
						</Table>
					</Box>
				) )
			}
		</TableContainer>
	)
}
