import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { catchResponse, prepareFetchRequest } from "./common.service";
import { ILocale } from "./localize.service";
import {
	ICards,
	IQRCard,
	IResCardLimits,
	IResCards,
	IResDriverCardLimit,
	IResListCardLimits,
	IResListCardPurses,
	IResListCardStatuses
} from "./response.interface";


export const cardAPI = createApi( {
	reducerPath: 'cardAPI',
	baseQuery: fetchBaseQuery( prepareFetchRequest() ),
	tagTypes: [ 'cards', 'cardLimits', 'listCardStatuses', 'listCardLimits', 'listCardPurses' ],
	endpoints: ( build ) => ( {
		//TODO добавить получение списка карт
		/* Получение всех карт клиента */
		fetchCardsByHolder: build.query<IResCards, { locale: ILocale; holderId: number }>( {
			async queryFn ( { locale, holderId }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/holders/${ holderId }/cards`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );

				const data = result.data as IResCards;

				data.cards = data.cards.map( card => {
					return {
						...card,
						fio: `${ card.cardInfo?.lastName || '' } ${ card.cardInfo?.firstName || '' }`,
						cardStatus: card.status === 0,
						firstName: card.cardInfo?.firstName || '',
						lastName: card.cardInfo?.lastName || '',
						mobile: card.cardInfo?.mobile || '',
						email: card.cardInfo?.email || ''
					}
				} )

				return { data }

			}
		} ),
		/* Получение всех карт счета */
		fetchCardsByAccount: build.query<IResCards, { locale: ILocale; accountId: number }>( {
			async queryFn ( { locale, accountId }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/accounts/${ accountId }/cards`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );

				const data = result.data as IResCards;

				data.cards = data.cards.map( card => {
					return {
						...card,
						fio: `${ card.cardInfo?.lastName || '' } ${ card.cardInfo?.firstName || '' }`,
						cardStatus: card.status === 0,
						firstName: card.cardInfo?.firstName || '',
						lastName: card.cardInfo?.lastName || '',
						mobile: card.cardInfo?.mobile || '',
						email: card.cardInfo?.email || ''
					}
				} )

				return { data }

			}
		} ),
		/* Получение всех лимитов карты */
		fetchLimitsByCard: build.query<IResCardLimits, { locale: ILocale; cardId: string }>( {
			async queryFn ( { locale, cardId }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/cards/${ cardId }/limits`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );

				let data = result.data as IResCardLimits;
				const purses = await dispatch( cardAPI.endpoints.fetchCardPurses.initiate( { locale } ) );


				data.limits = data.limits.map( limit => {
					const listPursesId = limit.attachedPurses.map( purse => purse.purseId );
					let listPurses = purses.data && purses.data.purses.filter( purse => listPursesId.indexOf( purse.purseId ) !== -1 ).map( purse => purse.remark );
					if ( !listPurses ) listPurses = [ '' ];

					return {
						...limit,
						listPurses: listPurses.join( ', ' ),
						moneyDay: limit.limitType === 2 ? limit.limitAmount : 0,
						qtyDay: limit.limitType === 4 ? limit.limitAmount : 0,
						moneyMonth: limit.limitType === 1 ? limit.limitAmount : 0,
						qtyMonth: limit.limitType === 3 ? limit.limitAmount : 0,
						balance: limit.limitBalance
					}
				} )

				return { data }

			}
		} ),

		/* Получение статусов карт */
		fetchCardsStatus: build.query<IResListCardStatuses, { locale: ILocale }>( {
			async queryFn ( { locale }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/common/cards/statuses`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );

				const data = result.data as IResListCardStatuses;

				return { data }

			}
		} ),
		/* Получение лимитов карт */
		fetchCardLimits: build.query<IResListCardLimits, { locale: ILocale }>( {
			async queryFn ( { locale }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/common/limits/types`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );

				const data = result.data as IResListCardLimits;

				return { data }

			}
		} ),
		/* Получение кошельков карт */
		fetchCardPurses: build.query<IResListCardPurses, { locale: ILocale }>( {
			async queryFn ( { locale }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/common/purses`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );

				const data = result.data as IResListCardPurses;


				return { data }

			}
		} ),
		/* Получаем карту водителя */
		fetchCardDriver: build.query<ICards, { locale: ILocale }>( {
			async queryFn ( { locale }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/users/cards`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );

				let data = result.data as ICards;

				data = {
					...data,

					cardStatus: data.status === 0,
					firstName: data.cardInfo?.firstName || '',
					lastName: data.cardInfo?.lastName || '',
					mobile: data.cardInfo?.mobile || '',
					email: data.cardInfo?.email || ''
				};


				return { data }

			}
		} ),
		/* Получаем QR карты водителя */
		fetchQRCardDriver: build.query<IQRCard, { locale: ILocale, cardId: string }>( {
			async queryFn ( { locale, cardId }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/users/cards/${ cardId }/qr`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );

				const data = result.data as IQRCard;

				return { data }

			}
		} ),
		/* Получаем лимиты карты водителя */
		fetchLimitCardDriver: build.query<IResDriverCardLimit, { locale: ILocale, cardId: string }>( {
			async queryFn ( { locale, cardId }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/users/cards/${ cardId }/limits`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );

				const data = result.data as IResDriverCardLimit;

				return { data }

			}
		} ),
	} )
} );
