import { GridColumns } from "@mui/x-data-grid/models/colDef/gridColDef";
import { fnLocalizeFilter, ILocale } from "../../../../services/localize.service";
import { CellType, ct } from "../../../UI/Table/components/CellType/CellType";

export const getColumns = ( locale: ILocale, theme : any ): GridColumns => {

		const fnGetHeader = ( key: string ): string => {
			return fnLocalizeFilter( locale, key )
		};

		return [
			{
				field: "numLine",
				headerName: fnGetHeader( '_lang.module.common.table.num' ),
				width: 50,
			},
			{
				field: "id",
				headerName: fnGetHeader( '_lang.module.clientList.titleID' ),
				width: 100,
				hide: true,
				sortable: true
			},
			{
				field: "name", sortable: true,
				headerName: fnGetHeader( '_lang.module.clientList.titleNameClient' ),
				renderCell: ( { value } ) => CellType( { type: ct.DEFAULT, value } ),
				flex: 1
			},
			{
				field: "okpo", sortable: true,
				headerName: fnGetHeader( '_lang.module.clientList.titleOKPO' ),
				renderCell: ( { value } ) => CellType( { type: ct.DEFAULT, value } ),
				width: 150, align: "center", headerAlign: "center"
			},
			{
				field: "itn", sortable: true,
				headerName: fnGetHeader( '_lang.module.clientList.titleINN' ),
				renderCell: ( { value } ) => CellType( { type: ct.DEFAULT, value } ),
				width: 150, align: "center", headerAlign: "center"
			},
			{
				field: "phone", sortable: true,
				headerName: fnGetHeader( '_lang.module.clientList.titlePhone' ),
				renderCell: ( { value } ) => CellType( { type: ct.DEFAULT, value } ),
				width: 150, align: "center", headerAlign: "center"
			},
			{
				field: "btnAccount", sortable: false,
				headerName: fnGetHeader( '_lang.module.clientList.titleAccounts' ),
				renderCell: ( { row } ) => CellType( {
					type: ct.LINK_HOVER,
					value: {
						title: fnGetHeader( '_lang.module.clientList.titleAccounts' ), link: `/clients/${ row.id }/accounts`,
						style: {
							textDecoration: 'none',
							color: "inherit"
						},
						sx: {
							fontSize: "inherit",
							opacity: ".2",
							"&:hover": {
								opacity: "1",
								color: theme.palette.secondary.main
							}
						}
					}
				} ),
				width: 150, align: "center", headerAlign: "center"
			}
		]
	}
;
