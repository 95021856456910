import { AppDispatch } from "../store";
import { catchError } from "./errorActions";
import userReducer, { IPermTypes } from "../reducers/userReducer";
import { setDataToLocalStorage } from "../../utils/methods";

/*
*  Авторизация
*/
interface IUserLogin {
	token?: string;
	username?: string;
	role?: string;
	expireAt?: string;
	permissions: IPermTypes[]
}

export const userLogin = ( data: IUserLogin ) => async ( dispatch: AppDispatch ) => {
	try {

		const { token = '', username = '', permissions, role = '', expireAt = '' } = data;

		/* Если логин со страницы атворизации token должен быть заполнен, если нет, то это обновление страницы приложения */
		if ( token !== '' ) {
			setDataToLocalStorage( 'token', JSON.stringify( { token, isAuth: true } ) );
			setDataToLocalStorage( 'username', username );
			setDataToLocalStorage( 'permissions', JSON.stringify( permissions ) );
			setDataToLocalStorage( 'role', role );
			setDataToLocalStorage( 'expireAt', expireAt );
		}


		await dispatch( userReducer.actions.userLogin( permissions ) )

	} catch ( e: any ) {
		dispatch( catchError( { errorCode: 1000, errorDescription: e.message } ) );
	}
}

/*
*  Авторизация
*/
export const userLogout = () => async ( dispatch: AppDispatch ) => {
	try {

		dispatch( userReducer.actions.userLogout() )

	} catch ( e: any ) {
		dispatch( catchError( { errorCode: 1000, errorDescription: e.message } ) );
	}
}

