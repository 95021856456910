import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarContainer } from "@mui/x-data-grid";
import CustomButton from "../Button/CustomButton";
import { makeStyles } from "@mui/styles";
import { IButton } from "../Button/button.interface";

const useStyles = makeStyles( {
	rootToolbar: {
		flexDirection: "column",
		alignItems: "flex-start !important"

	},
	title: {
		margin: "1rem 0 1rem 0 !important"
	}
} );

interface ICustomToolbar {
	buttons: IButton[];
	title: string;
}

export default function CustomToolbar( { buttons, title }: ICustomToolbar ) {
	const classes = useStyles();

	const RenderBtn = () => {
		return <Box>

				<GridToolbarColumnsButton
					sx={ {
						borderRadius: "50%",
						padding: "5px",
						minWidth: "initial",
						"&:hover": {
							backgroundColor: "rgba(255, 255, 255, 0.08)"
						},
						"& .MuiButton-startIcon": {
							margin: 0
						}
					} }
				/>


			{
				buttons?.map( ( { tooltip, iconButton, type, onClick }, idx ) => (
					<CustomButton key={ idx } tooltip={ tooltip } onClick={ onClick } iconButton={ iconButton }
					              type={ type }/>
				) )
			}
		</Box>
	};

	return (
		<GridToolbarContainer className={ classes.rootToolbar }>

			<Typography className={ classes.title }>{ title }</Typography>

			<RenderBtn/>

		</GridToolbarContainer>
	)
}