import { fnLocalizeFilter, ILocale } from "../../../../services/localize.service";
import { IColumn } from "../../../UI/CustomTable/components/Columns/Columns";
import { ISingleRow } from "../../../UI/CustomTable/interface/interface.custom";
import { handleSwitchStatusCard } from "../utils/local.request.api";
import { ICards } from "../../../../services/response.interface";
import { AppDispatch } from "../../../../store/store";
import { checkPermission } from "../../../../helpers/permissions";


interface IGetColumnsCard {
	locale: ILocale;
	cards: ICards[];
	accountId: number;
	dispatch: AppDispatch;
}

export const getColumnsCard = ( { locale, accountId, dispatch, cards }: IGetColumnsCard ): IColumn[] => {

	const onToggleSwitch = async ( { row }: ISingleRow ) => {

		return await handleSwitchStatusCard( {
			row,
			locale,
			cards,
			accountId,
			dispatch
		} );

	};

	return [
		{
			field: 'cardId',
			label: fnLocalizeFilter( locale, '_lang.module.cards.titleID' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center',
			sortable: true
		},
		{
			field: 'fio',
			label: fnLocalizeFilter( locale, '_lang.module.cards.titleName' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center',
			sortable: true
		},
		{
			field: 'cardStatus',
			label: fnLocalizeFilter( locale, '_lang.module.common.table.active' ),
			formatHeader: 'default',
			cell: {
				format: checkPermission( { permissionName: 'Accounts' } ) > 1 ? 'switch-card' : 'boolean',
				onToggleSwitch
			},
			align: 'center',
			alignData: 'center',
			sortable: true
		},
		{
			field: 'vin',
			label: fnLocalizeFilter( locale, '_lang.module.cards.titleVin' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center',
			sortable: true
		},
		{
			field: 'mobile',
			label: fnLocalizeFilter( locale, '_lang.module.cards.titlePhone' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center',
			sortable: true
		},
		{
			field: 'btnAccount',
			label: fnLocalizeFilter( locale, '_lang.module.cards.titleSetting' ),
			formatHeader: 'default',
			cell: { format: 'link-opacity-card', label: fnLocalizeFilter( locale, '_lang.module.common.table.setting' ) },
			align: 'center',
			alignData: 'center',
			sortable: true
		}

	];
};