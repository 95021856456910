import { Box, CircularProgress, TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyleCell = styled( TableCell )( () => ( {
	textAlign: 'center',
	border: "initial",
	padding: "3rem 0"
} ) );

const FullScreenBox = styled( Box )( () => ( {
	position: "fixed",
	top: 0, bottom: 0, right: 0, left: 0,
	backgroundColor: "rgba(122,142,161,0.37)",
	zIndex: 9999,
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
} ) );

interface ILoading {
	type?: 'default' | 'small' | 'medium' | 'big' | 'fullScreen';
}

export default function Loading ( { type = 'default' }: ILoading ) {
	switch ( type ) {
		case "small": {
			return <CircularProgress color="secondary" size={ 20 }/>
		}

		case "medium": {
			return <CircularProgress color="secondary" size={ 40 }/>
		}

		case "big": {
			return <CircularProgress color="secondary" size={ 80 }/>
		}

		case "fullScreen": {
			return <FullScreenBox><CircularProgress color="secondary" size={ 60 }/></FullScreenBox>
		}

		default: {
			return (
				<TableRow>
					<StyleCell colSpan={ 25 }>
						<CircularProgress color="secondary"/>
					</StyleCell>
				</TableRow>
			)
		}
	}


}