import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { catchResponse, prepareFetchRequest } from "./common.service";
import { ILocale } from "./localize.service";
import { ICommonResponse, IResPurses } from "./response.interface";
import { IReqSendOTP } from "./request.interface";


export const accountAPI = createApi( {
	reducerPath: 'accountAPI',
	baseQuery: fetchBaseQuery( prepareFetchRequest() ),
	tagTypes: [ 'purses' ],
	endpoints: ( build ) => ( {
		/* Получение всех кошельков счета */
		fetchPursesByAccount: build.query<IResPurses, { locale: ILocale; accountId: number }>( {
			async queryFn ( { locale, accountId }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/accounts/${ accountId }/purses`,
					params: {
						lang: locale
					}
				} );

				await catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );
				const data = result.data as IResPurses;

				return { data }

			},
			providesTags: result => [ 'purses' ]
		} ),
		/* Отправка ОТП пароля  */
		sendOTP: build.query<ICommonResponse, IReqSendOTP>( {
			async queryFn ( { locale, phone }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				let result = await fetchWithBQ( {
					url: `/common/users/otp`,
					params: {
						lang: locale,
						phone
					}
				} );

				/* Если ответ пришел статусом но  */
				if ( !result.data ) result.data = result?.error?.data;

				let data = catchResponse( {
					data: result.data,
					meta: result.meta,
					getState,
					dispatch,
					isShowTooltip: true
				} ) as ICommonResponse;


				// const data = ( res ) ? result.data as ICommonResponse : { data: { errorCode: 1000, errorDescription: '' } }
				//const data = result.data as ICommonResponse;

				return { data }

			}
		} )
	} )
} );