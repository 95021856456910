import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import * as React from 'react';
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { closeModal } from "../../../store/actions/layoutActions";
import { useGlobalContext } from "../../../services/context.service";
import { useCustomStyles } from "../../../style/global";

export type IModalType = "info" | "success" | "warning" | "error" | "confirmation" | "danger" | "default";

export interface IModalMessage {
	type?: IModalType;
	title?: string,
	children?: React.ReactElement<any, any>;
	btnOkTitle?: string;
	btnCancelTitle?: string;

	test? (): void;

	handleBtnOk? (): void;

	handleBtnCancel? (): void;
}


export default function ModalMessage () {

	const {
		isModalOpen,
		type = "default",
		children = <></>,
		btnOkTitle = "OK",
		btnCancelTitle = "Cancel",

		handleBtnOk = () => {
		},
		handleBtnCancel = () => {
		},
		test
	}
		= useAppSelector( state => state.layout.modal )

	const classes = useCustomStyles();

	const dispatch = useAppDispatch();
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	const handleClose = () => {
		handleBtnCancel();
		dispatch( closeModal() );
	};

	const handleSubmit = () => {

		[ 'error' ].indexOf( type ) === -1
			? handleBtnOk()
			: dispatch( closeModal() );

	};

	return (

		<Dialog
			open={ isModalOpen }
			onClose={ () => handleClose() }
			className={ classes.titleDefault }
			sx={ { "& .MuiPaper-root": { maxWidth: "initial", borderRadius: "7px" } } }
			scroll={ 'paper' }
		>

			<DialogContent style={ { overflowY: "auto", flex: "1 1 auto", whiteSpace: "pre-wrap" } }>

				{ children }

			</DialogContent>

			<DialogActions>
				{
					test && <Button onClick={ () => {
						test();
					} }>LOG</Button>
				}

				<Button variant={ 'contained' } color={ 'secondary' } onClick={ () => {
					handleSubmit()
				} }>{ btnOkTitle }</Button>

				{
					[ 'error' ].indexOf( type ) === -1 &&
            <Button variant={ 'contained' } color={ 'secondary' } onClick={ () => {
							handleClose()
						} }>{ btnCancelTitle }</Button>
				}
			</DialogActions>
		</Dialog>

	);


}