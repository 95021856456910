import InputFilled from "../../../../UI/Input/InputFilled";
import { fnLocalizeFilter } from "../../../../../services/localize.service";
import { useGlobalContext } from "../../../../../services/context.service";
import { Suspense, useState } from "react";
import Loading from "../../../../UI/Loading/Loading";
import ModalFilterCard from "./ModalFilterCard";

interface ICardSelect {
	holderId: number;
	cardId: string;
	disabled: boolean;

	onApplyCardFilter ( card: string ): void;
}

export default function CardSelect ( { holderId, cardId, disabled = false, onApplyCardFilter }: ICardSelect ) {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	const [ isOpenModal, setOpenModal ] = useState( false );

	const onChangeSelectedCard = () => {
		!disabled && setOpenModal( true )
	};

	const handleApplyCardFilter = ( card: string ) => {
	    onApplyCardFilter( card );
	};

	return <>
		<InputFilled
			label={ fnLocalizeFilter( locale, '_lang.module.reports.titleCard' ) }
			variant={ 'filled' }
			sx={ { "& input": { paddingRight: '2.5rem' } } }
			onClick={ onChangeSelectedCard }
			onChange={ onChangeSelectedCard }
			value={ cardId }
			disabled={ disabled }
		/>
		{
			isOpenModal && <Suspense fallback={ <Loading type={ 'fullScreen' }/> }>
          <ModalFilterCard isShow={ isOpenModal } handleClose={ () => setOpenModal( false ) } holderId={ holderId }
                           handleApplyCardFilter={ handleApplyCardFilter }/>
        </Suspense>
		}
	</>
}
