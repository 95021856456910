import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from "../Table/components/CustomPagination";
import Columns from "./components/Columns/Columns";
import Rows from "./components/Rows/Rows";
import { ICustomTable } from "./interface/interface.table";
import { IOrderBy } from "./interface/interface.custom";
import Loading from "../Loading/Loading";
import CustomTableContext from "./helpers/table.context";


export default function CustomTable ( {
	                                      columns,
	                                      rows,
	                                      isLoading = false,
	                                      isSimple = false,
	                                      count,
	                                      rowsPerPageOptions = [ 25, 50, 100 ],
	                                      rowsPerPageDefault = rowsPerPageOptions[ 0 ],
	                                      labelEmptyRows = 'No rows',
	                                      fnRefreshData,
	                                      onDoubleClick,
	                                      onClick,
	                                      ...rest
                                      }: ICustomTable ) {

	/* Сортировка таблицы */
	const [ orderBy, setOrderBy ] = useState<IOrderBy>( { field: '', order: 'asc' } );
	const handleChangeOrderBy = ( orderBy: IOrderBy ) => {
		setOrderBy( orderBy );
	};

	const [ page, setPage ] = useState<number>( 1 );
	const handleChangePageNum = ( pageNum: number ) => {
		setPage( pageNum )
	};

	useEffect( () => {
		if ( fnRefreshData ) {
			fnRefreshData( { page, orderBy } )
		}
	}, [ page, orderBy ] );

	/* Подготовка полей для таблицы */
	const prepareRows = useMemo( () => {
		return rows;
	}, [ rows ] );

//	<FilterContext.Provider value={ { filter } }>
	return (
		<CustomTableContext.Provider value={ { param: { ...rest } } }>
			<TableContainer sx={ { minHeight: "90px" } }>
				<Table size={ 'small' } stickyHeader>
					<TableHead>
						<TableRow>
							<Columns columns={ columns } orderBy={ orderBy } onChangeOrderBy={ handleChangeOrderBy }/>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							isLoading
								? <Loading/>
								: <Rows rows={ prepareRows } columns={ columns } labelEmptyRows={ labelEmptyRows }
								        onDoubleClick={ onDoubleClick } onClick={ onClick }

								/>
						}
					</TableBody>
				</Table>
			</TableContainer>
			{
				!isSimple && <CustomPagination
              count={ count }
              rowsPerPageOptions={ rowsPerPageOptions }
              rowsPerPageDefault={ rowsPerPageDefault }
              onChangePage={ handleChangePageNum }
          />
			}

		</CustomTableContext.Provider>
	);
}
