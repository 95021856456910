import { ILocale } from "../../../../services/localize.service";
import { AppDispatch } from "../../../../store/store";
import { getAsyncData } from "../../../../services/axios.service";
import { asyncRequestTooltip } from "../../../../utils/methods";
import { IRow } from "../../../UI/CustomTable/components/Rows/Rows";
import { ICardLimits, ICards } from "../../../../services/response.interface";
import { updateQueryCard, updateQueryLimits } from "../../../../services/update/card.update.service";
import { IReqChangePhoneOnServer, IReqCreateLimit } from "../../../../services/request.interface";

interface ISwitchStatusAccount {
	locale: ILocale;
	dispatch: AppDispatch;
	row: IRow;
	accountId: number;
	cards: ICards[];
}

export const handleSwitchStatusCard = async ( { row, locale, cards, accountId, dispatch }: ISwitchStatusAccount ) => {

	const idx = cards.findIndex( c => c.id === row.id );

	const req = {
		status: cards[ idx ].status === 0 ? 1 : 0,
		vin: cards[ idx ].vin
	};

	const url = `/cards/${ row.id }/update?lang=${ locale }`;
	const res = await getAsyncData( { locale, url, req } );

	if ( res && res.errorCode === 0 ) {
		let newCards: ICards[] = [ ...cards ];
		newCards[ idx ] = { ...newCards[ idx ], status: row.status === 0 ? 1 : 0, cardStatus: !row.cardStatus };

		dispatch( updateQueryCard( { locale, accountId, newCards } ) );
		return true;
	}

	return asyncRequestTooltip( { res, dispatch, locale } );
}

export const handleChangeCardInfo = async ( { row, locale, cards, accountId, dispatch }: ISwitchStatusAccount ) => {

	const req = {
		cardInfo: {
			firstName: row.firstName,
			lastName: row.lastName,
			mobile: row.mobile,
			email: row.email
		}
	};

	const url = `/cards/${ row.id }/update/info?lang=${ locale }`;
	const res = await getAsyncData( { locale, url, req } );

	if ( res && res.errorCode === 0 ) {
		const idx = cards.findIndex( c => c.id === row.id );
		let newCards: ICards[] = [ ...cards ];
		newCards[ idx ] = { ...newCards[ idx ], ...req, ...req.cardInfo };

		dispatch( updateQueryCard( { locale, accountId, newCards } ) );
		return true;
	}

	return asyncRequestTooltip( { res, dispatch, locale } );
}

interface IChangeLimitField {
	locale: ILocale;
	dispatch: AppDispatch;
	row: IRow;
	cardId: string;
	limits: ICardLimits[];
}

export const handleChangeLimitField = async ( { row, locale, limits, cardId, dispatch }: IChangeLimitField ) => {
	const req = {
		limitAmount: row.limitAmount
	}

	const url = `/cards/${ cardId }/limits/${ row.id }/update?lang=${ locale }`;
	const res = await getAsyncData( { locale, url, req } );

	if ( res && res.errorCode === 0 ) {
		const idx = limits.findIndex( l => l.id === row.id );
		let newLimits: ICardLimits[] = [ ...limits ];
		newLimits[ idx ] = { ...row as ICardLimits };

		dispatch( updateQueryLimits( { locale, cardId, newLimits } ) );
		return true;
	}

	return asyncRequestTooltip( { res, dispatch, locale } );

};

export const handleDeleteLimit = async ( { row, locale, limits, cardId, dispatch }: IChangeLimitField ) => {

	const req = {};
	const url = `/cards/${ cardId }/limits/${ row.id }/delete?lang=${ locale }`;
	const res = await getAsyncData( { locale, url, req, type: 'delete' } );

	if ( res && res.errorCode === 0 ) {
		const idx = limits.findIndex( l => l.id === row.id );
		let newLimits: ICardLimits[] = [ ...limits.slice( 0, idx ), ...limits.slice( idx + 1 ) ];

		dispatch( updateQueryLimits( { locale, cardId, newLimits } ) );
	}

	return asyncRequestTooltip( { res, dispatch, locale } );
};

export const handleCreateLimit = async ( {
	                                         locale,
	                                         limitAmount,
	                                         limitType,
	                                         attachedPurses,
	                                         cardId,
	                                         dispatch
                                         }: IReqCreateLimit ) => {

	const req = { limitAmount, limitType, attachedPurses };
	const url = `/cards/${ cardId }/limits/create?lang=${ locale }`;
	const res = await getAsyncData( { locale, url, req, type: 'post' } );

	return asyncRequestTooltip( { res, dispatch, locale } );
};

export const handleUpdatePhone = async ( {
	                                         row,
	                                         locale,
	                                         phone,
	                                         otp,
	                                         cards,
	                                         accountId,
	                                         dispatch
                                         }: IReqChangePhoneOnServer ) => {

	const req = {};
	const url = `/cards/${ row.id }/update/phone?otp=${ otp }&phone=${ phone }&lang=${ locale }`;
	const res = await getAsyncData( { locale, url, req, type: 'post' } );

	//const res = { errorCode: 0, errorDescription: '' };

	if ( res && res.errorCode === 0 ) {
		const idx = cards.findIndex( c => c.id === row.id );
		let newCards: ICards[] = [ ...cards ];
		newCards[ idx ] = { ...newCards[ idx ], mobile: phone, cardInfo: { ...newCards[ idx ].cardInfo, mobile: phone } };
		dispatch( updateQueryCard( { locale, accountId, newCards } ) );
	}

	return asyncRequestTooltip( { res, dispatch, locale } );
};

interface IChangePin {
	locale: ILocale;
	row: IRow;
	dispatch: AppDispatch;
	newPin: string;
}

export const handleChangePin = async ( {
	                                       locale,
	                                       row,
	                                       newPin,
	                                       dispatch
                                       }: IChangePin ) => {

	const req = { pin: newPin };
	const url = `/cards/${ row.id }/update/pin?lang=${ locale }`;
	const res = await getAsyncData( { locale, url, req, type: 'post' } );

	return asyncRequestTooltip( { res, dispatch, locale } );
};
