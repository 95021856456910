import SmartCard from "./SmartCard/SmartCard";
import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { fnLocalizeFilter } from "../../../services/localize.service";
import { useGlobalContext } from "../../../services/context.service";

const BoxContainer = styled( Box )( () => ( {
	width: "100%",
	height: "100%",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	fontWeight: 600
} ) );

export default function DriverCards () {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();
	/* Отображаем QR только если разрешение менее 1024 */
	const matches = useMediaQuery( '(max-width:1024px)' );



	return <>
		{
			matches
				? <SmartCard />
				: <BoxContainer>{ fnLocalizeFilter( locale, "_lang.module.cards.qr.notMatchResolution" ) }</BoxContainer>
		}
	</>
}
