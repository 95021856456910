import { cardAPI } from "../cardAPI.service";

export const updateQueryCard = ( {
	                             locale,
	                             accountId,
	                             newCards
                             }: any ) => cardAPI.util.updateQueryData( 'fetchCardsByAccount',
	( { locale, accountId } ), ( draft: any ) => {
		Object.assign( draft, { cards: newCards } )
	} )

export const updateQueryLimits = ( {
	                                 locale,
	                                   cardId,
	                                   newLimits
                                 }: any ) => cardAPI.util.updateQueryData( 'fetchLimitsByCard',
	( { locale, cardId } ), ( draft: any ) => {
		Object.assign( draft, { limits: newLimits } )
	} )