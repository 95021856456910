import { ru } from "../locale/ru";
import { ua } from "../locale/ua";
import { IGlobalLocale } from "../locale/locale.interface";

const locales = {
	'ru': ru,
	'uk': ua
};

export type ILocale = "uk" | "ru";

export const fnLocalizeFilter = ( locale: ILocale, key: IGlobalLocale, fieldsForReplace: any = [] ): string => {
	let res: any = undefined;

	/* Убираем приставку _lang.* */
	key = key as string;
	key = key.slice(6);
	
	const localeObject =  key.split( "." )


	if ( localeObject.length > 0 ) {
		// @ts-ignore
		res = locales[ locale ][ localeObject[ 0 ] ];
	}

	for ( let i = 1; i < localeObject.length; i++ ) {
		res = res[ localeObject[ i ] ];
	}

	if ( !res ) res = `[Localize error] : key "${ key }" not found`;

	/* Заполняем %1%, %2%, %3% */
	if ( fieldsForReplace?.length > 0 ) {
		for ( let i = 0; i < fieldsForReplace.length; i++ ) {
			const regex = new RegExp( `%${ i + 1 }%`, 'g' );
			res = res.replace( regex, fieldsForReplace[ i ] );
		}
	}

	return res;
};