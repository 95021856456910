import * as React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Grid } from "@mui/material";
import ChangePassword from "./Tabs/ChangePassword/ChangePassword";
import { fnLocalizeFilter } from "../../../services/localize.service";
import { useGlobalContext } from "../../../services/context.service";
import ChangeUser from "./Tabs/User/ChangeUser";
import { holderAPI } from "../../../services/holderAPI.service";
import InnerContainer from "../../Common/InnerContainer";

export default function Setting () {
	const { locale } = useGlobalContext();
	const [ value, setValue ] = React.useState( '1' );
	holderAPI.useFetchAllClientsQuery( locale );

	const handleChange = ( event: React.SyntheticEvent, newValue: string ) => {
		setValue( newValue );
	};
	return <>
		<InnerContainer direction={ 'left' } show={ true }>
			<Grid item xs={ 2 }>
				<TabContext value={ value }>
					<TabList onChange={ handleChange } aria-label="lab API tabs example">
						<Tab label={ fnLocalizeFilter( locale, '_lang.module.setting.titleChangePass' ) } value="1"/>
						<Tab label={ fnLocalizeFilter( locale, '_lang.module.setting.titleChangeUser' ) } value="2"/>
					</TabList>
					<TabPanel value="1">
						<ChangePassword/>
					</TabPanel>
					<TabPanel value="2">
						<ChangeUser/>
					</TabPanel>

				</TabContext>
			</Grid>
		</InnerContainer>
	</>
}