import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { catchResponse, prepareFetchRequest } from "./common.service";
import { ILocale } from "./localize.service";
import { IResRoles, IResUsers, IRoles } from "./response.interface";


export const settingAPI = createApi( {
	reducerPath: 'settingAPI',
	baseQuery: fetchBaseQuery( prepareFetchRequest() ),
	tagTypes: [ 'setting' ],
	endpoints: ( build ) => ( {
		/* Получение всех ролей */
		fetchUserRoles: build.query<IResRoles, { locale: ILocale; }>( {
			async queryFn ( { locale }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/users/roles`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );

				let data = result.data as IResRoles;

				data.roles = data.roles.map( ( r: IRoles, idx ) => ( { ...r, id: idx + 1, option: r.roleName } ) )

				return { data }

			},
			providesTags: result => [ 'setting' ]
		} ),
		/* Получение всех доступных пользователей системы */
		fetchAllUsers: build.query<IResUsers, { locale: ILocale; }>( {
			async queryFn ( { locale }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/users`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );

				let data = result.data as IResUsers;

				data.users = data.users.map( u => ( {
					...u,
					listHolders: u.holders.map( h => h.holderId ).join( ', ' )
				} ) );

				return { data }

			},
			providesTags: result => [ 'setting' ]
		} )

	} )
} );