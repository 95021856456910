import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Suspense, useState } from "react";
import { TIMEOUT_VISIBILITY_PIN_AFTER_CHANGE } from "../../../../../../../helpers/api";
import { genNewPinCode } from "../../../../../../../services/common.service";
import Loading from "../../../../../../UI/Loading/Loading";
import { fnLocalizeFilter } from "../../../../../../../services/localize.service";
import Modal from "../../../../../../UI/Modal/Modal";
import { useGlobalContext } from "../../../../../../../services/context.service";
import { handleChangePin } from "../../../../utils/local.request.api";
import { useAppDispatch } from "../../../../../../../hooks/redux";
import ProgressTimer from "../../../../../../UI/Loading/ProgressTimer";

interface IGetNewPin {
	row: any;
	editable?: boolean;
}

const Container = styled( Box )( () => ( {
	alignSelf: 'center'
} ) );

const timeoutVisibility = +TIMEOUT_VISIBILITY_PIN_AFTER_CHANGE;

export default function GetNewPin ( { row, editable = true }: IGetNewPin ) {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	const dispatch = useAppDispatch();

	const [ isShowPin, setShowPin ] = useState<boolean>( false );
	const [ newPin, setNewPin ] = useState<string>( '0000' );
	const [ isOpenModal, setOpenModal ] = useState<boolean>( false );

	const handleBtnChangePin = () => {
		const newPin = genNewPinCode();

		handleChangePin( { locale, row, newPin, dispatch } ).then( res => {
			if ( res ) {
				/* Закрываем модлку */
				setOpenModal( false );
				/* Устанавливаем новый пин */
				setNewPin( newPin );
				/* Отображаем новый пин */
				setShowPin( prev => !prev );
			}
		} );
	};

	return <Container>
		{
			isShowPin
				? <>
					<Button color={ 'secondary' } variant={ 'contained' } disabled={ true }
					        sx={ { position: 'relative', width: '200px', padding: ".5rem" } }>
						<Typography sx={ {
							marginRight: '.3rem',
							fontSize: '.9rem'
						} }>{ `${ fnLocalizeFilter( locale, '_lang.module.cards.titleShowNewPin' ) }: ` }</Typography>
						<Typography sx={ { marginRight: '1rem', fontSize: '.9rem' } } color={ 'secondary' }>{ newPin }</Typography>
						<ProgressTimer timer={ timeoutVisibility } type={ 'small' } onStopTimer={ () => setShowPin( false ) }/>
					</Button>
				</>
				: editable && <Button sx={ { width: '200px' } } color={ 'secondary' } variant={ 'contained' }
                              onClick={ () => setOpenModal( true ) }>{ fnLocalizeFilter( locale, '_lang.module.cards.titleShowNewPin' ) }</Button>
		}
		{
			isOpenModal && <Suspense fallback={ <Loading type={ 'fullScreen' }/> }>
          <Modal
              handleBtnOk={ handleBtnChangePin }
              isOpen={ isOpenModal }
              handleBtnCancel={ setOpenModal }
          >
            <>{ fnLocalizeFilter( locale, '_lang.module.cards.titleConfirmRenewPin' ) }</>
          </Modal>
        </Suspense>
		}

	</Container>

}
