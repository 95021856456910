import * as React from 'react';
import { useEffect, useState } from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type ITypeProgress = 'small' | 'medium' | 'default';

function CircularProgressWithLabel (
	props: CircularProgressProps & { value: number, time: number, type: ITypeProgress },
) {
	let sizeProgress : number;
	let sizeFont : number;

	switch ( props.type ) {
		case 'small' : {
			sizeProgress = 20;
			sizeFont = 10;
			break;
		}
		case 'medium' : {
			sizeProgress = 50;
			sizeFont = 20;
			break;
		}
		default:
			sizeProgress = 40;
			sizeFont = 16;
			break;
	}


	return (
		<Box sx={ { position: 'relative', display: 'inline-flex' } }>
			<CircularProgress size={ sizeProgress } variant="determinate" { ...props } />
			<Box
				sx={ {
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				} }
			>
				<Typography
					fontSize={ sizeFont }
					variant="caption"
					component="div"
					color="text.secondary"
				>{ `${ props.time }` }</Typography>
			</Box>
		</Box>
	);
}

interface IProgressWithLabel {
	timer: number;
	type?: ITypeProgress;
	sx?: React.CSSProperties;
	isUpdateTimer?: any;

	onStopTimer? (): void;
}

export default function ProgressTimer( {
	                                       timer,
	                                       type = 'default',
	                                       isUpdateTimer,
	                                       sx = {},
	                                       onStopTimer
                                       }: IProgressWithLabel ) {
	const [ progress, setProgress ] = useState( 100 );
	const [ time, setTimer ] = useState<number>( timer );

	useEffect( () => {
		const delta = progress / timer;

		setTimer( timer );
		setProgress( 100 );

		const timerInterval = setInterval( () => {
			setProgress( ( prevProgress ) => prevProgress > 0 ? prevProgress - delta : 0 );
			setTimer( prev => prev > 0 ? prev - 1 : 0 );
			if ( time <= 0 ) {

				clearInterval( timerInterval )
			}

		}, 1000 );
		return () => {
			clearInterval( timerInterval );
		};

	}, [timer, isUpdateTimer] )


	useEffect( () => {
		if ( time <= 0 ) onStopTimer && onStopTimer()
	}, [ time] )

	return	<CircularProgressWithLabel value={ progress } time={ time } type={ type } sx={ { ...sx } }/>;
}
