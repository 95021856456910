import { fnLocalizeFilter } from "../../../../../../services/localize.service";
import { GridItem } from "../../../../../Common/ClientGroupTitle";
import { Grid, useMediaQuery } from "@mui/material";
import { useGlobalContext } from "../../../../../../services/context.service";
import SwitchBlock from "../../../../../UI/SettingBlock/SwitchBlock";
import { ICards, ICardsStatus } from "../../../../../../services/response.interface";
import { ISingleRow } from "../../../../../UI/CustomTable/interface/interface.custom";
import { handleChangeCardInfo, handleSwitchStatusCard } from "../../../utils/local.request.api";
import { useAppDispatch } from "../../../../../../hooks/redux";
import { useEffect, useState } from "react";
import { getMedia } from "../../../../../../helpers/media";
import { styled } from "@mui/material/styles";
import TextParams from "../../../../../UI/SettingBlock/TextParams";
import GetNewPin from "./RenewPin/GetNewPin";
import RenewPhone from "./RenewPhone";
import { checkPermission } from "../../../../../../helpers/permissions";

interface ICardInfo {
	/**
	 * Весь стэйт карт
	 */
	cards: ICards[];
	/**
	 * Выбранная карта
	 */
	card: ICards;
	/**
	 * Счет выбранных карт
	 */
	accountId: number;
	/**
	 * Статусы карт
	 */
	statuses: ICardsStatus[];
}

const LocalGridItem = styled( GridItem )( ( props ) => ( {
	boxSizing: 'border-box',
	marginRight: '3rem',
	padding: '1rem',
	[ props.theme.breakpoints.down( 'md' ) ]: {
		margin: '.5rem 1rem',
	}
} ) );

export default function CardInfo ( { card, cards, accountId, statuses }: ICardInfo ) {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	const dispatch = useAppDispatch();
	/* Инициализация медиа  */
	const matches = useMediaQuery( getMedia() );

	const onToggleSwitch = async ( { row }: ISingleRow ) => {
		return await handleSwitchStatusCard( {
			row,
			locale,
			cards,
			accountId,
			dispatch
		} );

	};

	const [ status, setStatus ] = useState<string>( '' );

	useEffect( () => {
		if ( card.id !== '' ) {
			const newStatus = statuses.filter( status => status.id === card.status )[ 0 ];
			newStatus?.remark && setStatus( newStatus.remark );
		}
	}, [ card, statuses ] );

	const onChangeCardInfo = async ( { row }: ISingleRow ) => {
		return await handleChangeCardInfo( {
			row,
			locale,
			cards,
			accountId,
			dispatch
		} );

	};

	return <>
		{/* Группа */ }
		<Grid item xs={ 12 } md={ 6 }
		      style={ { display: "flex", alignItems: "flex-start", flexWrap: "wrap", marginTop: "1rem" } }>
			<LocalGridItem item xs={ 12 } md={ 5 }>
				<SwitchBlock
					title={ fnLocalizeFilter( locale, '_lang.module.common.table.active' ) }
					onChangeSwitch={ onToggleSwitch }
					labelInfo={ status }
					row={ card }
					isLoading={ status === '' }
					value={ card.cardStatus }
					editable={ checkPermission( { permissionName: 'Cards' } ) > 1 }
				/>
			</LocalGridItem>

			<LocalGridItem item xs={ 12 } md={ 5 }>
				<TextParams
					title={ fnLocalizeFilter( locale, '_lang.module.common.filter.titleFirstName' ) }
					onChangeTextField={ onChangeCardInfo }
					row={ card }
					fieldValue={ 'firstName' }
					type={ checkPermission( { permissionName: 'Cards' } ) < 2 ? 'disabled' : undefined}
				/>
			</LocalGridItem>

			<LocalGridItem item xs={ 12 } md={ 5 }>
				<TextParams
					title={ fnLocalizeFilter( locale, '_lang.module.common.filter.titleLastName' ) }
					onChangeTextField={ onChangeCardInfo }
					row={ card }
					fieldValue={ 'lastName' }
					type={ checkPermission( { permissionName: 'Cards' } ) < 2 ? 'disabled' : undefined}
				/>
			</LocalGridItem>

			<LocalGridItem item xs={ 12 } md={ 5 }>
				<RenewPhone
					title={ fnLocalizeFilter( locale, '_lang.module.common.filter.titlePhone' ) }
					row={ card }
					accountId={ accountId }
					cards={ cards }
					editable={ checkPermission( { permissionName: 'Cards' } ) > 1 }
				/>
			</LocalGridItem>

			<LocalGridItem item xs={ 12 } md={ 5 }>
				<TextParams
					title={ fnLocalizeFilter( locale, '_lang.module.common.filter.titleVIN' ) }
					onChangeTextField={ onChangeCardInfo }
					row={ card }
					fieldValue={ 'vin' }
					type={ 'disabled' }
				/>
			</LocalGridItem>

			<LocalGridItem item xs={ 12 } md={ 5 }>
				<GetNewPin row={ card }
				           editable={ checkPermission( { permissionName: 'Cards' } ) > 1 }
				/>
			</LocalGridItem>

		</Grid>
	</>
}