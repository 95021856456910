import { ICards } from "../../../../../services/response.interface";
import { IActiveAccounts, TActive } from "../../../../../utils/interface";
import { IOrderBy } from "../../../../UI/CustomTable/interface/interface.custom";
import { fieldSort } from "../../../../../utils/methods";
import { IFilterCardsField } from "./interface.filter";

interface IFilterApply {
	/**
	 * Искомій массив
	 */
	cards: ICards[];
	/**
	 * поля фильтра
	 */
	filter: IFilterCardsField;
	/**
	 * Сортировка по полю и метод (asc, desc)
	 */
	orderBy: IOrderBy;
}

export const filterApply = ( { cards, filter, orderBy }: IFilterApply ): ICards[] => {
	let filteredArr = cards;
	/*Accounts*/
	const { active, firstName, lastName, vin, mobile, cardId } = filter;

	const filterActive = filterIsActiveAccount( active );

	filteredArr = filteredArr.filter( arr => {
		let checkActive = true;

		/* Фильтр активности выбран */
		if ( filterActive !== undefined ) {
			const active = arr.status === 0;
			checkActive = active === filterActive
		}

		/* Если поисковая строки заполнены */
		let checkCard = ( ( cardId !== '' && arr.cardId.includes( cardId ) ) || cardId === '' );
		let checkFirstName = ( ( firstName !== '' && arr.firstName.toLocaleUpperCase().includes( firstName.toLocaleUpperCase() ) ) || firstName === '' );
		let checkLastName = ( ( lastName !== '' && arr.lastName.toLocaleUpperCase().includes( lastName.toLocaleUpperCase() ) ) || lastName === '' );
		let checkMobile = ( ( mobile !== '' && arr.mobile.includes( mobile ) ) || mobile === '' );
		let checkVIN = ( ( vin !== '' && arr.vin.toLocaleUpperCase().includes( vin.toLocaleUpperCase() ) ) || vin === '' );


		return checkActive && checkCard && checkVIN && checkFirstName && checkLastName && checkMobile;

	} );

	/* Сортируем если это необходимо */
	if ( orderBy.field !== '' ) {

		filteredArr = fieldSort( { array: filteredArr, orderBy } );

	}

	return filteredArr;

}

const filterIsActiveAccount = ( active: IActiveAccounts ): TActive => {
	const { isActive, isBlock } = active;
	let res: TActive = undefined;

	if ( isActive && !isBlock ) res = true;
	if ( !isActive && isBlock ) res = false;

	return res;
}