import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentIcon from '@mui/icons-material/Assignment';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import ClientList from "../components/Module/Client/ClientList/ClientList";
import AccountsDetail from "../components/Module/Accounts/AccountsDetail";
import CardsList from "../components/Module/Cards/CardList/CardsList";
import CardSetting from "../components/Module/Cards/CardSetting/CardSetting";
import ReportTrn from "../components/Module/Reports/ReportTrn/ReportTrn";
import Setting from "../components/Module/Setting/Setting";
import { TPermission } from "./permissions";
import DriverCards from "../components/Module/DriverCards/DriverCards";
import HelperLinks from "../components/Module/Links/HelperLinks";

export interface IMenu {
	icon?: JSX.Element;
	title?: string;
	hint?: string;
	/* Линк в случае отсутствия параметров */
	linkApp?: string;
	link?: string;
	component?: JSX.Element;
	permission?: TPermission;
}

export const menus: IMenu[] = [
	{
		icon: <PeopleAltIcon/>,
		title: '_lang.module.menu.0.title',
		hint: '_lang.module.menu.0.hint',
		linkApp: "clients",
		link: "clients",
		component: <ClientList/>,
		permission: 'Holders'
	},
	{
		icon: <AssignmentIcon/>,
		title: '_lang.module.menu.1.title',
		hint: '_lang.module.menu.1.hint',
		linkApp: 'clients/0/accounts',
		link: "clients/:holderId/accounts",
		component: <AccountsDetail/>,
		permission: 'Accounts'
	},
	{
		icon: <CreditCardIcon/>,
		title: '_lang.module.menu.2.title',
		hint: '_lang.module.menu.2.hint',
		linkApp: "holder/0/account/0/cards",
		link: "holder/:holderId/account/:accountId/cards",
		component: <CardsList/>,
		permission: 'Cards'
	},
	{
		icon: <CreditCardIcon/>,
		title: '_lang.module.menu.2.title',
		hint: '_lang.module.menu.2.hint',
		linkApp: "driver/cards",
		link: "driver/cards",
		component: <DriverCards/>,
		permission: 'Driver'
	},
	{
		icon: <HistoryEduIcon/>,
		title: '_lang.module.menu.3.title',
		hint: '_lang.module.menu.3.hint',
		linkApp: "reports",
		link: "reports",
		component: <ReportTrn/>,
		permission: 'Reports'
	},
	{
		icon: <LibraryBooksIcon/>,
		title: '_lang.module.menu.4.title',
		hint: '_lang.module.menu.4.hint',
		linkApp: "clients5",
		link: "clients5",
		component: <div>Документация</div>
	},
	{
		icon: <SettingsIcon/>,
		title: '_lang.module.menu.5.title',
		hint: '_lang.module.menu.5.hint',
		linkApp: "setting",
		link: "setting",
		component: <Setting />,
		permission: 'PersonalAccounts'
	},
	{
		icon: <InfoIcon/>,
		title: '_lang.module.menu.4.title',
		hint: '_lang.module.menu.4.hint',
		linkApp: "info",
		link: "info",
		component: <HelperLinks />,
		permission: 'All'
	},
	{
		link: "holder/:holderId/account/:accountId/card/:cardId/setting",
		component: <CardSetting/>,
		permission: 'Cards'
	}
];
