import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { REACT_APP_BASE_URL } from "../helpers/api";
import { catchResponse, catchURLResponse } from "./common.service";
import { userLogin } from "../store/actions/userAction";
import { IPermTypes } from "../store/reducers/userReducer";
import { ICommonResponse } from "./response.interface";
import { ILocale } from "./localize.service";
import { TShowTypeForm } from "../components/Module/Auth/Auth";

interface IResult {
	token: string;
	username: string;
	role: string;
	expireAt: string;
	permissions: IPermTypes[]
}


interface IQuery {
	username: string;
	password: string;
}

interface IOtp {
	type: TShowTypeForm;
	otp: string;
	phone: string;
	password: string;
	locale: ILocale;
}

export const authAPI = createApi( {
	reducerPath: 'authAPI',
	baseQuery: fetchBaseQuery( { baseUrl: REACT_APP_BASE_URL } ),
	tagTypes: [ 'Auth' ],
	endpoints: ( build ) => ( {
		userAuth: build.query<IResult, IQuery>( {
			query: ( { username, password } ) => {

				return ( {
					url: `/common/users/authorization`,
					params: {
						username, password
					}
				} )

			},

			async onQueryStarted ( queryArg, { dispatch, queryFulfilled, getState } ) {

				const data = await catchURLResponse<any>( queryArg, { dispatch, queryFulfilled, getState } )

				// const { token, username, permissions, role, expireAt } = data as IResult;
				//
				// setDataToLocalStorage( 'token', JSON.stringify( { token, isAuth: true } ) );
				// setDataToLocalStorage( 'username', username );
				// setDataToLocalStorage( 'permissions', JSON.stringify( permissions ) );
				// setDataToLocalStorage( 'role', role );
				// setDataToLocalStorage( 'expireAt', expireAt );


				let permissions: IPermTypes[] = [];

				if ( data.role === "SmartCardsDriver" ) {
					permissions = [ {
						permissionId: "11111111-1111-1111-1111-111111111111",
						permissionName: "Driver",
						permissionValue: 2
					} ]
				}

				const login = permissions.length === 0 ? data : { ...data, permissions };

				await dispatch( userLogin( login ) );


			},

			// transformResponse: ( baseQueryReturnValue, meta, arg ): any => {
			//
			// 	return baseQueryReturnValue;
			// }

		} ),
		userRegisterOrForgot: build.query<ICommonResponse, IOtp>( {
			async queryFn ( { locale, otp, phone, password, type }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				let url = `/common/users/registration`

				if ( type === 'forgotPass' ) url = '/common/users/password/change';

				const result = await fetchWithBQ( {
					url,
					params: {
						lang: locale,
						otp, phone, password
					}
				} );


				/* Если ответ пришел статусом но  */
				if ( !result.data ) result.data = result?.error?.data;

				let data = catchResponse( { data: result.data, meta: result.meta, getState, dispatch, isShowTooltip: true } );

				//const data = result.data as ICommonResponse;

				return { data }

			}
		} )
	} )
} );
