import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCustomStyles } from "../../style/global";
import Loading from "../UI/Loading/Loading";
import React from "react";

export interface IClientGroupTitle {
	/**
	 * Заголовок
	 */
	title: string;
	/**
	 * Отступ сверху по умолчанию ( MarginTop: 3 )
	 */
	mt?: number;
	/**
	 * Индикация загрузки
	 */
	isLoading?: boolean;
	/**
	 * Кастомные стили
	 */
	sx?: React.CSSProperties;
}

export const GridItem = styled( Grid )( () => ( {
	marginBottom: "1rem",
} ) );

export default function ClientGroupTitle ( { title, isLoading = false, sx = {}, mt = 3 }: IClientGroupTitle ) {
	const classes = useCustomStyles();

	return (
		<GridItem item sx={ { ...sx } }>
			<Box
				style={ { marginTop: `${ mt }rem`, fontSize: "1.5rem" } }
				className={ classes.titleDefault }>
				{ isLoading ? <Loading type={ 'small' }/> : title }
			</Box>
		</GridItem>
	)
}