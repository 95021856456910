import InputFilled from "../../../../UI/Input/InputFilled";
import { fnLocalizeFilter } from "../../../../../services/localize.service";
import { Button } from "@mui/material";
import { useGlobalContext } from "../../../../../services/context.service";
import React, { useEffect, useState } from "react";

interface IInputNewPassword {
	pass: string;
	rePass: string;

	onChange ( e: React.ChangeEvent<HTMLInputElement> ): void;

	onUpdatePass (): void;
}

export default function InputNewPassword ( { pass, rePass, onChange, onUpdatePass }: IInputNewPassword ) {
	const { locale } = useGlobalContext();

	const [ isError, setError ] = useState( false );

	useEffect( () => {
		setError( pass !== rePass );
	}, [ pass, rePass ] );


	return <>
		<InputFilled label={ fnLocalizeFilter( locale, '_lang.module.register.titleEnterPass' ) } name={ "pass" }
		             sx={ { "& > label": { color: `${ isError && 'red' }` } } }
		             onChange={ onChange }
		             type={ 'password' }
		/>
		<InputFilled label={ fnLocalizeFilter( locale, '_lang.module.register.titleEnterRePass' ) } name={ "rePass" }
		             sx={ { "& > label": { color: `${ isError && 'red' }` } } }
		             onChange={ onChange }
		             type={ 'password' }
		/>

		<InputFilled label={ fnLocalizeFilter( locale, '_lang.module.register.titleOTP' ) } name={ "otp" }
		             style={ { marginTop: "2rem" } }
		             onChange={ onChange }
		/>

		<Button variant="contained"
		        style={ {
			        width: "100%",
			        marginTop: ".7rem",
			        padding: ".7rem 0"
		        } }
		        onClick={ onUpdatePass }
		>{ fnLocalizeFilter( locale, '_lang.module.register.btnUpdatePassOTP' ) }</Button>
	</>
}