import { initialStateActivityReportFilter, initialStateReportTrn } from "./Filter/interface.filter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Grid, useMediaQuery } from "@mui/material";
import InnerContainer from "../../../Common/InnerContainer";
import BackToLink from "../../../Common/BackToLink";
import { useNavigate } from "react-router-dom";
import { holderAPI } from "../../../../services/holderAPI.service";
import ComboBox from "../../../UI/Input/ComboBox";
import { fnLocalizeFilter } from "../../../../services/localize.service";
import { useGlobalContext } from "../../../../services/context.service";
import { IHolders, IKinds } from "../../../../services/response.interface";
import { getColumns } from "./report.columns";
import CustomTable from "../../../UI/CustomTable";
import { IFilter } from "../../../UI/CustomTable/interface/interface.custom";
import { reportAPI } from "../../../../services/reportAPI.service";
import { REPORT_COUNT_PER_ON_PAGE } from "../../../../helpers/api";
import DatePicker from "../../../UI/DatePicker/DatePicker";
import OutlineBox from "../../../UI/OutlineBox/OutlineBox";
import { getMedia } from "../../../../helpers/media";
import { IReqReportTransaction } from "../../../../services/request.interface";
import moment from "moment";
import CardSelect from "./Filter/CardSelect";
import { getReportFile, IReport } from "../../../../helpers/common";
import Loading from "../../../UI/Loading/Loading";
import { IStateActivityReportFilter } from "../../../../utils/interface";

/* Кол-во строк в таблице счетов, на странице инфо клиента */
const countPerOnPage: number = REPORT_COUNT_PER_ON_PAGE ? +REPORT_COUNT_PER_ON_PAGE : 5;

export default function ReportTrn () {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();
	/* Управляем линками */
	let navigate = useNavigate();
	/* Инициализация медиа  */
	const matches = useMediaQuery( getMedia() );

	const [ showLoading, setShowLoading ] = useState( false );

	/* Получаем список клиентов доступных пользователю */
	const {
		data: dataHolder = { holders: [] },
		isLoading: isLoadingClient
	} = holderAPI.useFetchAllClientsQuery( locale );

	/* Фильтр таблицы */
	const [ filter, setFilterState ] = useState<IReqReportTransaction>( initialStateReportTrn );
	const [ filterActivity, setFilterActivity ] = useState<IStateActivityReportFilter>( initialStateActivityReportFilter );

	const [ getReport, {
		data: dataReport = { transactions: [], countOfRows: countPerOnPage },
		isLoading
	} ] = reportAPI.useLazyGetReportTransactionQuery();

	/* Полуаем виды транзакий */
	const { data: dataTrnKinds = { kinds: [] } } = reportAPI.useFetchAllTransactionKindsQuery( { locale } );

	/* Создаем стэйт выбранного клиента */
	const [ client, setClient ] = useState<IHolders>();

	useEffect( () => {
		if ( dataHolder.holders && dataHolder.holders.length > 0 ) {
			setClient( dataHolder.holders[ 0 ] );
			setFilterState( prev => {
				return ( prev.holderId === -1 )
					? { ...prev, holderId: dataHolder.holders[ 0 ].holderId }
					: { ...prev }
			} )
		}

	}, [ dataHolder ] );

	/* Меняем состояния клиента */
	const handleChangeClient = ( selectedClient: any ) => {
		const newClient = dataHolder.holders.filter( ( holder: IHolders ) => holder.holderId === selectedClient )[ 0 ];
		const newFilter = { ...filter, holderId: newClient.holderId, cardId: '' };
		setFilterState( newFilter );
	};

	/* Меняем состояние выбора карт */
	const handleChangeCard = ( selectedCard: string ) => {
		const newFilter = { ...filter, cardId: selectedCard };
		setFilterState( newFilter );
	};

	/* Меняем тип отчёта */
	const handleChangeKinds = ( selectedKind: IKinds ) => {

		const newKinds: IKinds = dataTrnKinds.kinds.filter( ( kind: IKinds ) => kind.id === selectedKind.id )[ 0 ];
		const newFilter = { ...filter, operation_kind: newKinds.id, filterType: newKinds.type, fileName: newKinds.name };

		if ( newKinds.type === 'xlsReport' ) {
			const newFilterActivity = {
				useHolderSelector: newKinds.useHolderSelector,
				useCardSelector: newKinds.useCardSelector,
				useFromDate: newKinds.useFromDate,
				useToDate: newKinds.useToDate
			};


			setFilterActivity( newFilterActivity as IStateActivityReportFilter );
		} else {
			setFilterActivity( initialStateActivityReportFilter );
		}

		setFilterState( newFilter );
	};

	const handleChangeRangeOfDate = ( rangeDate: any ) => {

		let filterDateRange = {};

		if ( rangeDate.length !== 2 ) {

			if ( filterActivity.useFromDate && !filterActivity.useToDate ) filterDateRange = {
				...filterDateRange,
				fromDate: moment( rangeDate ).format( 'YYYY-MM-DD' )
			};

			if ( !filterActivity.useFromDate && filterActivity.useToDate ) filterDateRange = {
				...filterDateRange,
				toDate: moment( rangeDate ).format( 'YYYY-MM-DD' )
			};
		} else {
			filterDateRange = {
				fromDate: moment( rangeDate[ 0 ] ).format( 'YYYY-MM-DD' ),
				toDate: moment( rangeDate[ 1 ] ).format( 'YYYY-MM-DD' )
			}
		}

		const newFilter = {
			...filter,
			...filterDateRange
		};


		setFilterState( newFilter );

	};


	const handleClose = () => {
		navigate( '/clients', { replace: true } );
	};

	/* Заполняем табличные заголовки */
	const columns = useMemo( () => {
		return getColumns( { locale } );
	}, [ locale ] );


	/* Текущая страница счетов */
	const handleChangePage = ( { page, orderBy }: IFilter ) => {


		let customFilter = filter.holderId === -1 ?
			{
				...filter,
				holderId: dataHolder.holders[ 0 ]?.holderId
			} : { ...filter }


		getReport( {
			locale, ...customFilter,
			page: page - 1,
			limit: countPerOnPage
		} )
	};

	/* Применение фильтра */
	const handleCommitFilter = useCallback( async () => {
			let customFilter = filter.holderId === -1 ?
				{
					...filter,
					holderId: dataHolder.holders[ 0 ].holderId
				} : { ...filter }


			/* Проверяем это веб версия отчета или XLS */
			if ( customFilter.filterType === 'webReport' ) {
				getReport( { locale, ...customFilter, page: 0, limit: countPerOnPage } )
			} else {

				const reqData: IReport = {
					reportId: customFilter.operation_kind,
					holders: [ customFilter.holderId ],
					cards: [ customFilter.cardId ],
					fromDate: customFilter.fromDate,
					toDate: customFilter.toDate,
					fileName: `${ customFilter.fileName }.xlsx`
				};
				try {
					setShowLoading( true );
					await getReportFile( reqData );
				} finally {
					setShowLoading( false );
				}
			}
		},
		[ filter, locale, dataHolder.holders ] );

	const handleClearFilter = useCallback( () => {
			let customFilter = {
				...initialStateReportTrn,
				operation_kind: 0,
				holderId: dataHolder.holders[ 0 ].holderId,
				cardId: '',
				fromDate: moment().format( 'YYYY-MM-01' ),
				toDate: moment().format( 'YYYY-MM-DD' ),
			};

			setFilterState( customFilter );

			setFilterActivity( initialStateActivityReportFilter );

		},
		[ filter, locale, client, dataHolder.holders ] );


	/* Если меняем клиента обновляем таблицу счетов */
	useEffect( () => {

		let customFilter = filter.holderId === -1 ?
			{
				...filter,
				holderId: dataHolder.holders[ 0 ]?.holderId
			} : { ...filter }

		client && getReport( {
			locale, ...customFilter,
			holderId: client.holderId,
			page: 0,
			limit: countPerOnPage
		} )

	}, [ client, dataHolder.holders ] );

	/* Фильтр по столбцам таблицы  */
	const rows = useMemo( () => {
		let count = 10;

		let filteredArr = dataReport.transactions;

		return { arr: filteredArr, count };
	}, [ dataReport ] );

	const dateTimePicker = useMemo( () => {

		const dateStart = moment( filter.fromDate ).format( 'YYYY-MM-DD' );
		const dateEnd = moment( filter.toDate ).format( 'YYYY-MM-DD' );

		let picker: [ string, string ] = [ dateStart, dateEnd ]

		return picker;

	}, [ filter ] )

	return <>
		<InnerContainer direction={ 'left' } show={ true }>
			<BackToLink handleClose={ handleClose } backToType={ 'client' }>
				<Grid container style={ { flexDirection: 'row' } }>


					<Grid item xs={ 12 } md={ 11 } style={ { flexDirection: 'column', padding: "1rem" } }>

						<OutlineBox>
							<Grid container style={ {
								display: "flex",
								alignItems: "center",
								justifyContent: `${ matches ? 'center' : 'flex-start' }`
							} }>

								<Grid item xs={ 12 } md={ 3 }>
									{
										<ComboBox
											textStyle={ { fontWeight: '700' } }
											data={ dataTrnKinds.kinds }
											fieldLabelOption={ 'name' }
											label={ fnLocalizeFilter( locale, '_lang.module.reports.titleTrnKind' ) }
											fnOnChange={ handleChangeKinds }
											callbackType={ 'fullData' }
											defaultValue={ filter.operation_kind }
										/>
									}
								</Grid>
								<Grid item xs={ 12 } md={ 2 }>
									<CardSelect
										holderId={ filter.holderId }
										onApplyCardFilter={ handleChangeCard }
										cardId={ filter.cardId }
										disabled={ !filterActivity.useCardSelector }
									/>
								</Grid>
								<Grid item xs={ 12 } md={ 3 }>
									<DatePicker
										onChange={ handleChangeRangeOfDate }
										pickerValue={ dateTimePicker }
										disabled={ ( !filterActivity.useFromDate && !filterActivity.useToDate ) }
									/>
								</Grid>
								<Grid item xs={ 12 } md={ 2 }>
									{
										<ComboBox
											textStyle={ { fontWeight: '700' } }
											data={ dataHolder.holders }
											fieldLabelOption={ 'name' }
											label={ fnLocalizeFilter( locale, '_lang.module.common.title.titleClientComboBox' ) }
											fnOnChange={ handleChangeClient }
											defaultValue={ filter.holderId }
											disabled={ !filterActivity.useHolderSelector }
										/>
									}
								</Grid>

								<Button
									color={ 'secondary' }
									sx={ {
										justifySelf: 'center',
										marginTop: `${ matches ? '1rem' : 0 }`,
										marginLeft: `${ matches ? 0 : '.5rem' }`
									} }
									onClick={ handleCommitFilter }
								>
									{ fnLocalizeFilter( locale, '_lang.module.common.filter.titleSubmit' ) }
								</Button>

								<Button
									color={ 'secondary' }
									sx={ {
										justifySelf: 'center',
										marginTop: `${ matches ? '1rem' : 0 }`,
										marginLeft: `${ matches ? 0 : '.5rem' }`
									} }
									onClick={ handleClearFilter }
								>
									Сброс
								</Button>


							</Grid>
						</OutlineBox>
					</Grid>

					<Grid item xs={ 12 } md={ 11 } style={ { flexDirection: 'column', padding: "1rem" } }>

						{ showLoading && <Loading type={ 'fullScreen' }/> }
						{ filter.filterType === 'webReport' &&
                <CustomTable
                    rows={ rows.arr }
                    columns={ columns }
                    isLoading={ isLoading || isLoadingClient }
                    count={ dataReport.countOfRows }
                    labelEmptyRows={ fnLocalizeFilter( locale, '_lang.module.common.table.emptyRows' ) }
                    rowsPerPageOptions={ [ countPerOnPage ] }
                    fnRefreshData={ handleChangePage }
                    holderId={ client?.holderId }
                />
						}
					</Grid>


				</Grid>

			</BackToLink>
		</InnerContainer>
	</>
}
