import { Collapse, TableCell, TableRow } from "@mui/material";
import FormatCell from "../Cell/FormatCell";
import { IRow } from "./Rows";
import React, { useEffect, useMemo, useState } from "react";
import { IColumn } from "../Columns/Columns";
import AccountPurses from "../Cell/FetchFormats/Collapsible/AccountPurses";
import { TTypeCollapsibleColumn } from "../../interface/interface.custom";
import ReportTrnGoodsList from "../Cell/FetchFormats/Collapsible/ReportTrnGoodsList";

export interface ICollapsibleRow {
	row: IRow;
	columns: IColumn[];
	type?: TTypeCollapsibleColumn;
}

export default function CollapsibleRow ( { row, columns, type }: ICollapsibleRow ) {
	const [ open, setOpen ] = useState<boolean>( false );

	useEffect( () => {
		setOpen( false );
	}, [row] )

	const handleToggleCollapsibleTable = () => {
		setOpen( prev => !prev );
	};

	const renderCollapseLine = useMemo( () => {
		let fieldCollapseArray: string = '';
		switch ( type ) {
			case 'report-trn': {
				fieldCollapseArray = 'products';
				break;
			}
		}

		return (
			/* И исходя из формата колонки каждой строки формируем вывод, на основании данных о колонках */
			columns.map( column => {
				const { cell: { format } } = column;

				if ( type === 'report-trn' && row[ fieldCollapseArray ].length === 0 && format === 'collapse' ) {
					column = { ...column, cell: { ...column.cell, format: 'default' } };
				}

				return <FormatCell key={ column.field } column={ column } row={ row }
				                   collapsible={ { isOpen: open, handleToggleCollapsibleTable } }/>
			} )
		)
	}, [ columns, row ] );

	const renderCollapseData = useMemo( () => {
		switch ( type ) {
			case 'accounts': {
				return <AccountPurses type={ type } row={ row }/>
			}
			case 'report-trn': {
				return <ReportTrnGoodsList type={ type } row={ row }/>
			}
		}
	}, [ row ] );

	return <>
		<TableRow hover sx={ { '& > *': { borderBottom: 'unset' } } }>
			{
				renderCollapseLine
			}
		</TableRow>
		<TableRow>
			<TableCell style={ { paddingBottom: 0, paddingTop: 0 } } colSpan={ columns.length }>
				<Collapse in={ open } timeout="auto" unmountOnExit>
					{
						renderCollapseData
					}
				</Collapse>
			</TableCell>
		</TableRow>
	</>
}