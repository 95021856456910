import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILocale } from "../../services/localize.service";
import { delDataFromLocalStorage } from "../../utils/methods";

interface IUser {
	isAuth: boolean;
	permTypes: IPermTypes[];
}

export interface IPermTypes {
	permissionId: string;
	permissionName: string;
	permissionValue: number;
}

export interface UserState {
	users: IUser;
	locale: ILocale
}

const initialState: UserState = {
	users: {
		isAuth: false,
		permTypes: []
	},
	locale: "uk" // "ru"
}

const userReducer = createSlice( {
	name: 'user',
	initialState,
	reducers: {
		/* Авторизация пользователя */
		userLogin ( state: UserState, action: PayloadAction<IPermTypes[]> ) {
			state.users.isAuth = true;
			state.users.permTypes = action.payload;
		},
		/* Разлогинивание пользователя */
		userLogout ( state: UserState ) {

			/* Очищаем созданные ключи в локальном хранилище */
			[ 'token', 'username', 'permissions', 'role', 'expireAt' ].forEach( storage => {
				delDataFromLocalStorage( storage )
			} );

			state.users.isAuth = false;
		},

		/* Изменение языкового стейта */
		handleChangeLocale ( state: UserState, action: PayloadAction<ILocale> ) {
			state.locale = action.payload;
		},
	}
} )

export default userReducer;