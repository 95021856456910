import { Button, Grid, Typography } from "@mui/material";
import { useCustomStyles } from "../../../../style/global";

import React from "react";
import { fnLocalizeFilter } from "../../../../services/localize.service";
import classNames from 'classnames';
import { useStyles } from "../style";
import { useGlobalContext } from "../../../../services/context.service";
import InputPhone from "../../../UI/Input/InputPhone";


interface ILogin {
	phone: string;

	onChange ( e: React.ChangeEvent<HTMLInputElement> ): void;

	onSendOTP (): void;

	goHome (): void;

}

export default function GetPhone ( { goHome, phone, onChange, onSendOTP }: ILogin ) {

	const classes = useStyles();
	const global = useCustomStyles();
	const { locale } = useGlobalContext();

	return <>
		<Grid
			item
			className={ global.textDefault }
			sx={ { marginBottom: "1rem" } }
		>{
			fnLocalizeFilter( locale, '_lang.module.register.welcomeTextPhone' )
		}</Grid>

		<InputPhone
			label={ fnLocalizeFilter( locale, '_lang.module.register.titlePhone' ) }
			value={ phone }
			onChange={ onChange }
			sx={ { width: "97%" } }
		/>


		<Button
			variant="contained"
			style={ {
				width: "100%",
				marginTop: "1rem",
				padding: ".7rem 0"
			} }
			onClick={ onSendOTP }>
			{ fnLocalizeFilter( locale, '_lang.module.register.btnRegisterPhone' ) }
		</Button>

		<Typography className={ classNames( global.textDefault, classes.btnRegister ) }
		            style={ { margin: ".5rem auto", fontSize: "12px", } }
		            onClick={ goHome }
		>{ fnLocalizeFilter( locale, '_lang.module.login.btnEnter' ) }</Typography>

	</>
}