import { Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const textPosition: React.CSSProperties = {
	position: "absolute",
	fontSize: ".8rem",
	padding: "0 .2rem",
	top: "-.65rem",
	color: " rgba(0, 0, 0, 0.54)",
	backgroundColor: "#fff"
};

const useStyles = makeStyles( ( theme ) => ( {
	container: {
		padding: '1rem',
		border: "1px solid rgba(0,0,0,.2)",
		borderRadius: "5px",
		position: "relative",
		"&:hover": {
			cursor: " pointer",
			borderColor: " rgba(0,0,0,.5)"
		}
	},
	textPositionLeft: {
		...textPosition,
		right: "1rem"
	},
	textPositionRight: {
		...textPosition,
		left: "1rem"
	}
} ) );

interface IOutlineBox {
	textPosition?: 'left' | 'right' | undefined;
	label?: string;
	children: React.ReactElement<any, any>;
	sx?: React.CSSProperties;
}

export default function OutlineBox ( { children, textPosition = 'right', label, sx = {} }: IOutlineBox ) {
	const classes = useStyles();

	return (
		<Grid className={ classes.container } sx={ { ...sx } }>

			{
				textPosition &&
          <Typography
              className={ textPosition === 'left' ? classes.textPositionLeft : classes.textPositionRight }
          >
						{ label }
          </Typography>
			}

			{ children }

		</Grid>
	);
}