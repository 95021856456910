import { Button, IconButton, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { IButton } from "./button.interface";

/* add */
import AddIcon from '@mui/icons-material/Add';
/* edit */
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
/* delete */
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
/* delete without cross */
import DeleteIcon from '@mui/icons-material/Delete';
/* refresh */
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
/* filter */
import FilterAltIcon from '@mui/icons-material/FilterAlt';
/* apply filter */
import SearchIcon from '@mui/icons-material/Search';
/* cancelSearch */
import SearchOffIcon from '@mui/icons-material/SearchOff';

/**
 * Рендер кнопки
 * @param title - Надпись на кнопке если кнопка type = basic
 * @param onClick - Функция выполняющаяся при нажатии
 * @param type - тТп кнопки (basic | iconButton )
 * @param iconButton - Иконка кнопки
 * @param tooltip - Подсказка при наведении на кнопку
 * @param sx - Кастомный стиль кнопки
 * @constructor
 */
export default function CustomButton( {
	                                      title = "Кнопка",
	                                      onClick,
	                                      type = "iconButton",
	                                      iconButton = "refresh",
	                                      tooltip = "",
	                                      sx
                                      }: IButton ): JSX.Element {

	return useMemo( () => {

		let resBtn: JSX.Element = <></>;

		if ( type === "basic" ) {
			resBtn = <Button onClick={ onClick }> { title } </Button>
		}

		if ( type === "iconButton" ) {

			switch ( iconButton ) {

				case "add":
					resBtn = <AddIcon fontSize="inherit"/>;
					break;

				case "edit":
					resBtn = <ModeEditOutlineRoundedIcon fontSize="inherit"/>;
					break;

				case "deleteWithCross":
					resBtn = <DeleteForeverIcon fontSize="inherit"/>;
					break;

				case "deleteWithOutCross":
					resBtn = <DeleteIcon fontSize="inherit"/>;
					break;

				case "refresh":
					resBtn = <CachedRoundedIcon fontSize="inherit"/>;
					break;

				case "filter":
					resBtn = <FilterAltIcon fontSize="inherit"/>;
					break;

				case "applyFilter" : {
					resBtn = <SearchIcon fontSize="large" />;
					break;
				}

				case "cancelFilter" : {
					resBtn = <SearchOffIcon fontSize="large" />;
					break;
				}
			}

			resBtn = <IconButton sx={ sx } onClick={ onClick } size={ "small" }> { resBtn } </IconButton>
		}

		return <Tooltip title={ tooltip }>{ resBtn }</Tooltip>;

	}, [ iconButton, onClick, title, type, tooltip ] );
};