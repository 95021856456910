import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const Container = styled( Grid )( () => ( {
	flexDirection: 'column'
} ) );

export const GritEditItem = styled( Grid )( () => ( {
	paddingTop: ".8rem",
	flexDirection: 'row',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between'
} ) );