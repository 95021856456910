import moment from "moment";
import { IReqReportTransaction } from "../../../../../services/request.interface";
import { REPORT_COUNT_PER_ON_PAGE } from "../../../../../helpers/api";
import { IStateActivityReportFilter } from "../../../../../utils/interface";

/* Кол-во строк в таблице счетов, на странице инфо клиента */
const countPerOnPage: number = REPORT_COUNT_PER_ON_PAGE ? +REPORT_COUNT_PER_ON_PAGE : 5;

export const initialStateReportTrn: IReqReportTransaction = {
	fromDate: moment().format( 'YYYY-MM-01' ),
	toDate: moment().format( 'YYYY-MM-DD' ),
	holderId: -1,
	cardId: '',
	page: 0,
	limit: countPerOnPage,
	operation_kind: 0,
	with_cancelation: true,
	filterType: 'webReport',
	fileName: ''
}

export const initialStateActivityReportFilter: IStateActivityReportFilter = {
	useHolderSelector: true,
	useCardSelector: true,
	useFromDate: true,
	useToDate: true
}
