import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { catchResponse, catchURLResponse, prepareFetchRequest } from "./common.service";
import { ILocale } from "./localize.service";
import { IResAccounts, IResAttributes, IResHolders } from "./response.interface";


export const holderAPI = createApi( {
	reducerPath: 'holderAPI',
	baseQuery: fetchBaseQuery( prepareFetchRequest() ),
	tagTypes: [ 'holder', 'accounts', 'attributes' ],
	endpoints: ( build ) => ( {
		/* Получение клиентов */
		fetchAllClients: build.query<IResHolders, ILocale>( {
			query: ( locale ) => {

				return ( {
					url: `/holders`,
					params: {
						lang: locale
					}
				} )

			},
			providesTags: result => [ 'holder' ],
			async onQueryStarted ( queryArg, { dispatch, queryFulfilled, getState } ) {

				await catchURLResponse<any>( queryArg, { dispatch, queryFulfilled, getState } )

			},
			transformResponse: ( baseQueryReturnValue, meta, arg ): any => {
				return baseQueryReturnValue;
			}

		} ),

		/* Получение счетов клиента */
		fetchAccountsClient: build.query<IResAccounts, { locale: ILocale; holderId: number }>( {
			async queryFn ( { locale, holderId }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/holders/${ holderId }/accounts`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );
				let data = result.data as IResAccounts;

				data = {
					...data,
					accounts: data.accounts.map( account => ( {
						...account,
						totalBalance: account.totalBalance + account.overdraft
					} ) )
				};

				return { data }

			},


		} ),

		/* Получение аттрибутов клиента */
		fetchAttributesClient: build.query<IResAttributes, { locale: ILocale; holderId: number }>( {
			query: ( { locale, holderId } ) => {

				return ( {
					url: `/holders/${ holderId }/attributes`,
					params: {
						lang: locale
					}
				} )

			},
			providesTags: result => [ 'attributes' ],
			async onQueryStarted ( queryArg, { dispatch, queryFulfilled, getState } ) {

				await catchURLResponse<any>( queryArg, { dispatch, queryFulfilled, getState } )

			},
			transformResponse: ( baseQueryReturnValue, meta, arg ): any => {
				return baseQueryReturnValue;
			}

		} )

	} )
} );

