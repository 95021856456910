import { AppDispatch } from "../store";
import { catchError } from "./errorActions";
import layoutReducer, { ITooltip } from "../reducers/layoutReducer";
import { IModalMessage } from "../../components/UI/Modal/ModalOld";


/*
 * Показ подсказок
 */
export const showTooltip = ( props: ITooltip ) => async ( dispatch: AppDispatch ) => {
	try {

		dispatch( layoutReducer.actions.showToast( props ) );

	} catch ( e: any ) {
		catchError( { errorCode: 1000, errorDescription: e.message } );
	}
}


/*
*  Очистка стєйта
*/
export const clearTooltip = () => async ( dispatch: AppDispatch ) => {
	try {

		dispatch( layoutReducer.actions.clearToast() );

	} catch ( e: any ) {
		catchError( { errorCode: 1000, errorDescription: e.message } );
	}
}

export const showModal = ( props: IModalMessage ) => async ( dispatch: AppDispatch ) => {
	try {

		dispatch( layoutReducer.actions.showModalWindow( props ) );

	} catch ( e: any ) {
		catchError( { errorCode: 1000, errorDescription: e.message } );
	}
}

export const closeModal = () => async ( dispatch: AppDispatch ) => {
	try {

		dispatch( layoutReducer.actions.closeModalWidow() );

	} catch ( e: any ) {
		catchError( { errorCode: 1000, errorDescription: e.message } );
	}
}