import { Box, CardMedia, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';

import { Theme } from "@mui/material/styles/createTheme";
import { useState } from "react";
import Login from "./Login/Login";
import Register from "./Register/Register";


const useStyles = makeStyles<Theme>( ( theme ) => ( {
	container: {
		display: "inherit"
	},
	loginContainer: {},
	loginImage: {
		position: "absolute",
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		height: "100vh"
	},
	enterLogin: {
		display: "flex",
		alignItems: "left",
		position: "absolute",
		right: "15px",
		bottom: "15px",
		top: "15px",
		width: "25vw",
		borderRadius: "10px",
		backgroundColor: "#fff",
		padding: "3%",
		flexDirection: "column",
		justifyContent: "center",
		overflow: "hidden",
		[ theme.breakpoints.down( 'lg' ) ]: {
			width: "40vw"
		},
		[ theme.breakpoints.down( 'md' ) ]: {
			width: "inherit",
			height: "inherit",
			right: "10px",
			left: "10px",
			bottom: "15px",
			top: "7vh"
		}
	},
	btnRegister: {
		"&:hover ": {
			color: theme.palette.primary.main,
			cursor: "pointer"
		}
	}
} ) );

export type TShowTypeForm = 'register' | 'forgotPass' | 'default';

export default function Auth (): JSX.Element {
	const classes = useStyles();

	const [ showForm, setShowForm ] = useState<TShowTypeForm>( 'default' );

	const handleChangeForm = ( type: TShowTypeForm ) => {
		setShowForm( type );
	};

	return <Box className={ classes.container }>
		<Grid item xs={ 12 } className={ classes.loginContainer }>
			<Grid item xs={ 8 }>
				<CardMedia className={ classes.loginImage }
				           component="img"
				           image="./img/img_login.jpg"
				           alt="logoImage"
				/>
			</Grid>

			<Grid item xs={ 3 } className={ classes.enterLogin } style={ { flexDirection: "column" } }>
				{
					[ 'register', 'forgotPass' ].indexOf( showForm ) !== -1 &&
            <Register linkLogin={ handleChangeForm } showForm={ showForm }/>
				}

				{
					showForm === 'default' && <Login handleRegisterOrForgot={ handleChangeForm }/>
				}

			</Grid>
		</Grid>
	</Box>
}
