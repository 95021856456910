import { Grid, Typography } from "@mui/material";
import { useCustomStyles } from "../../../../style/global";
import { LoadingButton } from '@mui/lab';
import React, { useState } from "react";
import InputFilled from "../../../UI/Input/InputFilled";
import { fnLocalizeFilter } from "../../../../services/localize.service";
import classNames from 'classnames';
import { useStyles } from "../style";
import { useGlobalContext } from "../../../../services/context.service";
import { authAPI } from "../../../../services/authAPI.service";
import { styled } from "@mui/material/styles";
import { TShowTypeForm } from "../Auth";

interface ILogin {
	handleRegisterOrForgot ( type: TShowTypeForm ): void;
}

const LinkRegister = styled( Typography )( () => ( {
	margin: ".5rem",
	fontSize: "12px"
} ) );

export default function Login ( { handleRegisterOrForgot }: ILogin ) {

	const classes = useStyles();
	const global = useCustomStyles();
	const { locale } = useGlobalContext();

	const [ login, setLogin ] = useState<string>( "" );
	const [ pass, setPass ] = useState<string>( "" );
	const [ userAuth, { isLoading } ] = authAPI.useLazyUserAuthQuery();

	const handleChangeLogin = ( e: any ) => {
		setLogin( e.currentTarget.value );
	};

	const handleChangePass = ( e: any ) => {
		setPass( e.currentTarget.value );
	};

	const handleUserAuth = async () => {
		await userAuth( { username: login, password: pass } )
	};

	const handleBtn = ( e: any ) => {
		handleRegisterOrForgot( e.target.id )
	};

	return <>
		<Grid item className={ global.titleBold }
		      style={ {
			      margin: "0 auto 1vw 0",
			      fontSize: "36px",
			      fontWeight: 700
		      } }> { fnLocalizeFilter( locale, '_lang.module.login.enterCRM' ) }</Grid>
		<Grid item
		      className={ global.textDefault }> { fnLocalizeFilter( locale, '_lang.module.login.welcomeText' ) }</Grid>

		<InputFilled label={ fnLocalizeFilter( locale, '_lang.module.login.titleLogin' ) } id={ "login" }
		             onChange={ handleChangeLogin } value={ login }
		/>
		<InputFilled label={ fnLocalizeFilter( locale, '_lang.module.login.titlePassword' ) } id={ "pass" }
		             onChange={ handleChangePass } type={ 'password' } value={ pass }/>
		<LoadingButton
			variant="contained"
			loading={ isLoading }
			style={ {
				width: "100%",
				marginTop: ".7rem",
				padding: ".7rem 0"
			} }
			onClick={ handleUserAuth }
		>
			{ fnLocalizeFilter( locale, '_lang.module.login.btnEnter' ) }
		</LoadingButton>
		<Grid sx={ { display: 'flex', alignItems: 'center', justifyContent: 'center' } }>
			<LinkRegister className={ classNames( global.textDefault, classes.btnRegister ) } id={ 'register' }
			              onClick={ handleBtn }
			>{ fnLocalizeFilter( locale, '_lang.module.login.btnRegister' ) }</LinkRegister> /
			<LinkRegister className={ classNames( global.textDefault, classes.btnRegister ) } id={ 'forgotPass' }
			              onClick={ handleBtn }
			>{ fnLocalizeFilter( locale, '_lang.module.login.btnForgotPass' ) }</LinkRegister>
		</Grid>
	</>
}
