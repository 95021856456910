import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import errorReducer from "./reducers/errorReducer";
import { authAPI } from "../services/authAPI.service";
import layoutReducer from "./reducers/layoutReducer";
import { holderAPI } from "../services/holderAPI.service";
import { accountAPI } from "../services/accountAPI.service";
import { cardAPI } from "../services/cardAPI.service";
import { reportAPI } from "../services/reportAPI.service";
import { settingAPI } from "../services/settingAPI.service";


const rootReducer = combineReducers( {
	[ layoutReducer.name ]: layoutReducer.reducer,
	[ userReducer.name ]: userReducer.reducer,
	[ errorReducer.name ]: errorReducer.reducer,
	[ authAPI.reducerPath ]: authAPI.reducer,
	[ holderAPI.reducerPath ]: holderAPI.reducer,
	[ accountAPI.reducerPath ]: accountAPI.reducer,
	[ cardAPI.reducerPath ]: cardAPI.reducer,
	[ reportAPI.reducerPath ]: reportAPI.reducer,
	[ settingAPI.reducerPath ]: settingAPI.reducer
} )

export const setupStore = () => {

	return configureStore( {
		reducer: rootReducer,
		middleware: ( getDefaultMiddleware => getDefaultMiddleware()
				.concat( authAPI.middleware )
				.concat( holderAPI.middleware )
				.concat( accountAPI.middleware )
				.concat( cardAPI.middleware )
				.concat( reportAPI.middleware )
				.concat( settingAPI.middleware )
		)
	} )
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];