import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useCustomStyles } from "../../../style/global";
import { Bool } from "../CustomTable/components/Cell/FetchFormats/Bool";
import { ISingleRow } from "../CustomTable/interface/interface.custom";
import Loading from "../Loading/Loading";
import { Container, GritEditItem } from "./common";

interface ISwitchBlock {
	row: any;
	title: string;
	labelInfo?: string;
	value: boolean;
	isLoading?: boolean;
	editable?: boolean;

	onChangeSwitch ( { row }: ISingleRow ): Promise<boolean>;

	sx?: React.CSSProperties;
}

const style = {
	"& .MuiSwitch-root": {
		margin: 0
	}
};

export default function SwitchBlock ( {
	                                      title,
	                                      row,
	                                      value,
	                                      labelInfo = '',
	                                      editable = true,
	                                      isLoading = false,
	                                      onChangeSwitch,
	                                      sx = {}
                                      }: ISwitchBlock ) {
	const classes = useCustomStyles();

	return <>
		<Box sx={ { ...sx, ...style } }>
			<Container container>
				<Grid className={ classes.textDefault }>{ title }</Grid>
				<GritEditItem className={ classes.titleDefault }>
					{
						isLoading
							? <Loading type={ 'small' }/>
							: <>
								<Typography>{ labelInfo }</Typography>
								{ editable &&
                    <Grid item xs={ 1 }> <Bool row={ row } onToggleSwitch={ onChangeSwitch } value={ value }/> </Grid> }
							</>
					}

				</GritEditItem>
			</Container>
		</Box>
	</>
}