import { showTooltip } from "../store/actions/layoutActions";
import moment from "moment";
import { fnLocalizeFilter } from "./localize.service";
import { REACT_APP_BASE_URL, REACT_APP_IS_LOG_REQUEST } from "../helpers/api";
import { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { getDataFromLocalStorage } from "../utils/methods";
import { userLogout } from "../store/actions/userAction";
import { ICommonResponse } from "./response.interface";
import { AppDispatch } from "../store/store";

export interface IError {
	errorCode: number;
	errorDescription: string;
}


export async function catchURLResponse<T> ( queryArg: T, { dispatch, queryFulfilled, getState }: any ) {
	const locale = getState().user.locale;

	try {
		const { data, meta } = await queryFulfilled;

		if ( REACT_APP_IS_LOG_REQUEST === 'true' ) console.log( `${ moment().format( 'DD.MM.YYYY HH:mm:ss.SS' ) } request`, {
			data,
			meta
		} );

		return data;
		// @ts-ignore
	} catch ( err: IErrorURL ) {
		if ( REACT_APP_IS_LOG_REQUEST === 'true' ) console.log( `${ moment().format( 'DD.MM.YYYY HH:mm:ss.SS' ) } request (ERROR)`, err );

		try {

			const { error: { data, status } } = err;

			let errorMessage = "";

			/* Отлавливаем ошибочный описанный статус */
			if ( [ 400, 401, 403 ].indexOf( status ) !== -1 ) {

				switch ( status ) {

					case 400: {
						errorMessage = fnLocalizeFilter( locale, `_lang.error.1005` );
						break;
					}

					case 401: {
						errorMessage = fnLocalizeFilter( locale, `_lang.error.1001` );
						dispatch( userLogout() );
						break;
					}

					case 403: {
						errorMessage = fnLocalizeFilter( locale, `_lang.error.1006` );
						break;
					}

					default : {
						errorMessage = fnLocalizeFilter( locale, `_lang.error.${ data.errorCode }` )

						/* Если ошибка не найдена, то возвращем "Неизвестную ошибку"  */
						if ( errorMessage.includes( '[Localize error]' ) ) {
							errorMessage = fnLocalizeFilter( locale, `_lang.error.1000` )
						}

						break;
					}
				}


			}

			dispatch( showTooltip( { type: "error", message: errorMessage } ) );


		} catch ( e ) {
			console.error( e );
		}

	}
}


interface ICatchResponse {
	data: ICommonResponse | any;
	dispatch: AppDispatch;
	getState: any;
	meta: any;
	isShowTooltip?: boolean;
}

export function catchResponse ( {
	                                data,
	                                meta,
	                                getState,
	                                dispatch,
	                                isShowTooltip = false
                                }: ICatchResponse ) {

	const locale = getState().user.locale;

	if ( REACT_APP_IS_LOG_REQUEST === 'true' ) console.log( `${ moment().format( 'DD.MM.YYYY HH:mm:ss.SS' ) } request`, {
		data,
		meta
	} );

	const { response: { status } } = meta;
	let errorMessage = "";

	/* Отлавливаем ошибочный описанный статус */
	if ( [ 400, 401, 403, 500 ].indexOf( status ) !== -1 ) {
		switch ( status ) {

			case 401: {
				errorMessage = fnLocalizeFilter( locale, `_lang.error.1001` );
				dispatch( userLogout() );
				break;
			}

			default : {
				if ( data?.errorCode ) errorMessage = fnLocalizeFilter( locale, `_lang.error.${ data.errorCode }` )
				/* Если ошибка не найдена, то возвращем "Неизвестную ошибку"  */
				else errorMessage = fnLocalizeFilter( locale, `_lang.error.1000` )
				break;
			}
		}
		dispatch( showTooltip( { type: "error", message: errorMessage } ) );
		return { errorCode: 1000, errorDescription: errorMessage };

	} else {

		/* Если необходимо подтверждение */
		if ( isShowTooltip ) {
			if ( data?.errorCode )
				errorMessage = fnLocalizeFilter( locale, `_lang.error.${ data.errorCode }` );
			else
				errorMessage = fnLocalizeFilter( locale, `_lang.error.1002` );
		}
		dispatch( showTooltip( { type: "success", message: errorMessage } ) );

		return { errorCode: 0, errorDescription: errorMessage };
	}
}

export function prepareFetchRequest (): FetchBaseQueryArgs {
	return {
		baseUrl: REACT_APP_BASE_URL,
		prepareHeaders: headers => {

			const storage = JSON.parse( getDataFromLocalStorage( 'token' ) as string );

			if ( storage && storage.token ) headers.set( `authorization`, `Bearer ${ storage && storage.token }` )
			else console.log( 'token is null' )

			return headers;

		}
	}
}

export function genNewPinCode (): string {
	const min: number = 0;
	const max: number = 9999;

	let newPinMath = Math.floor( Math.random() * ( max - min ) ) + min;
	let newPinStr = `${ newPinMath }`;


	while ( newPinStr.length <= 3 ) {
		newPinStr = '0' + newPinStr;
	}

	return newPinStr;

}
