import { fnLocalizeFilter, ILocale } from "../../../../../../services/localize.service";
import { ICardLimits, ICards } from "../../../../../../services/response.interface";
import { AppDispatch } from "../../../../../../store/store";
import { IColumn } from "../../../../../UI/CustomTable/components/Columns/Columns";
import { ISingleRow } from "../../../../../UI/CustomTable/interface/interface.custom";
import { handleChangeLimitField, handleDeleteLimit } from "../../../utils/local.request.api";
import { checkPermission } from "../../../../../../helpers/permissions";


interface IGetColumnsCard {
	locale: ILocale;
	cards: ICards[];
	limits: ICardLimits[];
	cardId: string;
	dispatch: AppDispatch;
}

export const getColumnsLimits = ( { locale, cardId, dispatch, limits }: IGetColumnsCard ): IColumn[] => {

	const onChangeField = async ( { row }: ISingleRow ) => {
		return await handleChangeLimitField( {
			row,
			locale,
			limits,
			cardId,
			dispatch
		} );

	};

	const onClickDelete = async ( { row }: ISingleRow ) => {
		return await handleDeleteLimit( {
			row,
			locale,
			limits,
			cardId,
			dispatch
		} );

	};

	return [
		{
			field: 'listPurses',
			label: fnLocalizeFilter( locale, '_lang.module.cards.limits.titlePurses' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center',
			sortable: true,
			width: 200
		},
		{
			field: 'moneyDay',
			label: fnLocalizeFilter( locale, '_lang.module.cards.limits.titleMoneyByDay' ),
			formatHeader: 'default',
			cell: {
				format: checkPermission( { permissionName: 'Limits' } ) > 1 ? 'text-edit-limits' : 'default', onChangeField
			},
			align: 'center',
			alignData: 'center',
			width: 130,
			sortable: true
		},
		{
			field: 'qtyDay',
			label: fnLocalizeFilter( locale, '_lang.module.cards.limits.titleQtyByDay' ),
			formatHeader: 'default',
			cell: {
				format: checkPermission( { permissionName: 'Limits' } ) > 1 ? 'text-edit-limits' : 'default', onChangeField
			},
			align: 'center',
			alignData: 'center',
			width: 130,
			sortable: true
		},
		{
			field: 'moneyMonth',
			label: fnLocalizeFilter( locale, '_lang.module.cards.limits.titleMoneyByMonth' ),
			formatHeader: 'default',
			cell: {
				format: checkPermission( { permissionName: 'Limits' } ) > 1 ? 'text-edit-limits' : 'default', onChangeField
			},
			align: 'center',
			alignData: 'center',
			width: 130,
			sortable: true
		},
		{
			field: 'qtyMonth',
			label: fnLocalizeFilter( locale, '_lang.module.cards.limits.titleQtyByMonth' ),
			formatHeader: 'default',
			cell: {
				format: checkPermission( { permissionName: 'Limits' } ) > 1 ? 'text-edit-limits' : 'default', onChangeField
			},
			align: 'center',
			alignData: 'center',
			width: 130,
			sortable: true
		},
		{
			field: 'balance',
			label: fnLocalizeFilter( locale, '_lang.module.cards.limits.titleBalance' ),
			formatHeader: 'default',
			cell: {
				format: checkPermission( { permissionName: 'Limits' } ) > 1 ? 'text-edit-limits' : 'default', onChangeField
			},
			align: 'center',
			alignData: 'center',
			width: 130,
			sortable: true
		},
		{
			field: 'btnAccount',
			label: '',
			formatHeader: 'default',
			cell: {
				format: checkPermission( { permissionName: 'Limits' } ) > 1 ? 'icon-delete' : 'empty',
				onClickDelete,
				labelConfirm: fnLocalizeFilter( locale, '_lang.module.common.modal.labelConfirmation' )
			},
			align: 'center',
			alignData: 'center'
		}

	];
};