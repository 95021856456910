import { TableCell, TableRow, Typography } from "@mui/material";
import { IColumn } from "../Columns/Columns";
import FormatCell from "../Cell/FormatCell";
import CollapsibleRow from "./CollapsibleRow";
import { styled } from "@mui/material/styles";

const StyleCell = styled( TableCell )( () => ( {
	textAlign: 'center',
	border: "initial",
	padding: "3rem 0"
} ) );

export interface IRow {
	id: number | string;

	[ key: string ]: any;
}

export interface IRows {
	columns: IColumn[];
	rows: IRow[];
	labelEmptyRows?: string;

	onDoubleClick? ( { row }: IRow ): void;

	onClick? ( { row }: IRow ): void;
}

export default function Rows ( { rows, columns, labelEmptyRows, onDoubleClick, onClick }: IRows ) {

	return ( <>
		{
			/* Проверяем кол-во строк на странице */
			rows.length <= 0
				?
				<TableRow>
					<StyleCell colSpan={ 25 }>
						<Typography>{ labelEmptyRows }</Typography>
					</StyleCell>
				</TableRow>
				:
				/* Берем отправленную строку */
				rows.map( ( row, idx ) => {

					/* Проверяем есть ли формат колонки collapse */
					const columnsWithType = columns.findIndex( column => column.cell.format === 'collapse' );
					/* Находим в каком типе подчиенная таблица */
					const type = ( columnsWithType !== -1 ) ? columns[ columnsWithType ].cell.type : 'default';

					return (
						/* Проверяем есть ли расскрывающаяся строка */
						( type !== 'default' )
							? <CollapsibleRow key={ idx } row={ row } columns={ columns } type={ type }/>
							: <TableRow hover key={ idx } sx={ { '& > *': { borderBottom: 'unset' } } }
							            onDoubleClick={ onDoubleClick && onDoubleClick.bind( null, row ) }
							            onClick={ onClick && onClick.bind( null, row ) }
							>
								{
									/* И исходя из формата колонки каждой строки формируем вывод, на основании данных о колонках */
									columns.map( column => {
										return <FormatCell key={ column.field } column={ column } row={ row }/>
									} )
								}
							</TableRow>
					)
				} )
		}
	</> )
}
