import { Grid, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from "@mui/material";
import { TTypeCollapsibleColumn } from "../../../../interface/interface.custom";
import { useEffect } from "react";
import { accountAPI } from "../../../../../../../services/accountAPI.service";
import { useGlobalContext } from "../../../../../../../services/context.service";
import { IRow } from "../../../Rows/Rows";
import { getMedia } from "../../../../../../../helpers/media";
import { IPurse } from "../../../../../../../services/response.interface";
import Loading from "../../../../../Loading/Loading";
import { fnLocalizeFilter } from "../../../../../../../services/localize.service";

interface ICollapsibleType {
	type?: TTypeCollapsibleColumn;
	row: IRow;
}

export default function AccountPurses ( { type, row }: ICollapsibleType ) {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();
	/* Инициализация медиа  */
	const matches = useMediaQuery( getMedia() );

	const [ getPurses, { data: dataPurses = { purses: [] }, isLoading } ] = accountAPI.useLazyFetchPursesByAccountQuery()

	useEffect( () => {
		getPurses( { accountId: +row.id, locale } )
	}, [ row ] );

	return (
		<Grid item xs={ 6 } sx={ { margin: `${ matches ? "1rem 0 1rem 1rem" : "1rem 0 1rem 10rem" }` } }>
			<Table size="small" aria-label="purchases">
				<TableHead>
					<TableRow>
						<TableCell>{fnLocalizeFilter(locale, '_lang.module.accounts.titlePurses')}</TableCell>
						<TableCell>{fnLocalizeFilter(locale, '_lang.module.accounts.titleTotalBalanceWithoutHold')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						isLoading
							? <Loading/>
							: dataPurses.purses && dataPurses.purses.map( ( p: IPurse ) => (
							<TableRow hover key={ p.id }>
								<TableCell>{ p.remark }</TableCell>
								<TableCell>{ p.amount }</TableCell>
							</TableRow>
						) )
					}
				</TableBody>
			</Table>
		</Grid>
	)
}