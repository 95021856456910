import InnerContainer from "../../../Common/InnerContainer";
import BackToLink from "../../../Common/BackToLink";
import { useGlobalContext } from "../../../../services/context.service";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../hooks/redux";
import { holderAPI } from "../../../../services/holderAPI.service";
import { Grid, useMediaQuery } from "@mui/material";
import ComboBox from "../../../UI/Input/ComboBox";
import { fnLocalizeFilter } from "../../../../services/localize.service";
import { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import ClientGroupTitle from "../../../Common/ClientGroupTitle";
import FilterTable from "../../../UI/Filter/FilterTable";
import CustomTable from "../../../UI/CustomTable";
import { cardAPI } from "../../../../services/cardAPI.service";
import { IFilter, IOrderBy } from "../../../UI/CustomTable/interface/interface.custom";
import { COUNT_PER_ON_PAGE } from "../../../../helpers/api";
import { getColumnsCard } from "./cards.columns";
import { filterApply } from "./Filter/filter";
import { IFilterCardsField, initialStateFilterCard } from "./Filter/interface.filter";
import FilterContext from "./Filter/context.card";
import DriverCards from "../../DriverCards/DriverCards";
import { getDataFromLocalStorage } from "../../../../utils/methods";

const AccountCombobox = styled( Grid )( ( props ) => ( {
	marginLeft: "2rem",
	[ props.theme.breakpoints.down( 'md' ) ]: {
		marginLeft: "0",
		marginTop: "1rem"
	}
} ) );

/* Кол-во строк в таблице счетов, на странице инфо клиента */
const countPerOnPage: number = +COUNT_PER_ON_PAGE;

export default function CardsList () {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	/* Получаем адренсную строку */
	let params = useParams();
	/* Управление состоянием */
	const dispatch = useAppDispatch();
	/* Управляем линками */
	let navigate = useNavigate();

	/* Создаем стэйт выбранного клиента */
	const [ holderId, setHolder ] = useState<number>( 0 );
	/* Создаем стэйт выбранного счёта */
	const [ accountId, setAccount ] = useState<number>( 0 );




	useEffect( () => {
		if ( params ) {

			const holderId: number = params.holderId ? parseInt( params.holderId ) : 0;
			const accountId: number = params.accountId ? parseInt( params.accountId ) : 0;

			setHolder( holderId );
			setAccount( accountId );
		}

	}, [ params ] );

	/* Получаем список клиентов доступных пользователю */
	const {
		data: dataHolder = { holders: [] },
		isSuccess: isSuccessHolder,
		isLoading: isLoadingClient
	} = holderAPI.useFetchAllClientsQuery( locale );

	/* Получаем список счетов выбранного клиента */
	const [ getAccount, {
		data: dataAccount = { accounts: [] },
		isLoading: isLoadingAccount,
		isSuccess: isSuccessAccount
	} ] = holderAPI.useLazyFetchAccountsClientQuery();

	/* Получаем список карты выбранного счета */
	const [ getCards, {
		data: dataCards = { cards: [] },
		isLoading: isLoadingCards
	} ] = cardAPI.useLazyFetchCardsByAccountQuery();

	/* Получаем из справочник счетов в случае изменения клиента */
	useEffect( () => {

		const reqHolderId = holderId === 0
			? params && params.holderId !== "0"
				? params && params.holderId && +params.holderId
				: dataHolder?.holders.length > 0 && dataHolder?.holders[ 0 ].holderId
			: holderId;

		reqHolderId && getAccount( { locale, holderId: reqHolderId } );
		reqHolderId && setHolder( reqHolderId );

	}, [ holderId, dataHolder ] )

	/* Получаем из справочник счетов в случае изменения клиента */
	useEffect( () => {

		const reqAccountId = accountId === 0
			? dataAccount.accounts.length > 0 && dataAccount.accounts[ 0 ].accountId
			: accountId;

		reqAccountId && setAccount( reqAccountId );

		reqAccountId && getCards( { locale, accountId: reqAccountId } );

	}, [ isSuccessHolder, isSuccessAccount, accountId ] )



	/* Меняем справочник счетов, при выборе другого клиента */
	const handleChangeClient = ( selectedClient: any ) => {
		/* Выбираем новые счета */
		getAccount( { locale, holderId: selectedClient } )
			.then( res => {
					/* Выбираем первый аккаунта из выбранного клиента, для получения списка карт */
					const newAccountId = ( res.data && res.data.accounts.length > 0 ) ? res.data.accounts[ 0 ].id : 0;
					setAccount( newAccountId );
				}
			);
		setHolder( selectedClient );
	};

	const handleChangeAccount = ( selectedAccount: any ) => {
		getCards( { locale, accountId: selectedAccount } );
		setAccount( selectedAccount );
	}

	const handleClose = () => {
		navigate( '/clients', { replace: true } );
	};

	/* Фильтр таблицы */

	const [ filter, setFilterState ] = useState<IFilterCardsField>( initialStateFilterCard );
	const [ isFilterApply, setApplyFilter ] = useState<boolean>( false );
	/* изменение фильтра */
	const handleChangeFilter = ( newFilter: IFilterCardsField ) => {
		setFilterState( newFilter );
	};
	const handleClearFilter = () => {
		setFilterState( initialStateFilterCard );
		handleCommitFilter();
	};
	/* Применение фильтра */
	const handleCommitFilter = useCallback( () => {
		setApplyFilter( prev => !prev );
	}, [ filter ] );
	/* Текущая страница счетов */
	const [ pageNum, setPage ] = useState<number>( 0 );
	const [ orderBy, setOrderBy ] = useState<IOrderBy>( { field: "", order: "asc" } );
	const handleChangePage = ( { page, orderBy }: IFilter ) => {
		//setRefresh( true );
		setOrderBy( orderBy );
		setPage( page - 1 );
	};

	/* Фильтр по столбцам таблицы  */
	const rows = useMemo( () => {
		let count = 0;
		/* Применение фильтра */
		let filteredArr = filterApply( { cards: dataCards.cards, filter, orderBy } );
		count = filteredArr.length;

		/* Обрезаем массив до необходимых размеров отображения на странице */
		const startRow = pageNum === 0 ? 0 : ( pageNum * countPerOnPage );
		const stopRow = ( pageNum + 1 ) * countPerOnPage;

		/* Обрезаем по строкам на странице после фильтра */
		filteredArr = filteredArr.slice( startRow, stopRow );

		return { arr: filteredArr, count };

	}, [ pageNum, isFilterApply, orderBy, dataCards, holderId ] );

	/* Заполняем табличные заголовки */
	const columns = useMemo( () => {
		return getColumnsCard( {
			locale,
			cards: dataCards.cards,
			accountId,
			dispatch
		} );
	}, [ dataCards.cards, dispatch, accountId, locale ] );

	const role = getDataFromLocalStorage("role");
	const matches = useMediaQuery( '(max-width:1024px)' );

	if ( role === "SmartCardsDriver" && matches ) {
		return <DriverCards/>
	}

	return <>
		<FilterContext.Provider value={ { filter } }>
			<InnerContainer direction={ 'left' } show={ true } sx={ { position: "relative", zIndex: 0 } }>
				<BackToLink handleClose={ handleClose } backToType={ 'client' }>
					<Grid container style={ { flexDirection: 'row' } }>

						<Grid item xs={ 12 } md={ 9 } style={ { flexDirection: 'column', padding: "1rem" } }>
							{/* Выпадающий список клиентов */ }
							<Grid container style={ { display: "flex", alignItems: "center", } }>

								<Grid item xs={ 12 } md={ 4 }>
									<ComboBox
										textStyle={ { fontWeight: '700' } }
										data={ dataHolder.holders }
										fieldLabelOption={ 'name' }
										label={ fnLocalizeFilter( locale, '_lang.module.common.title.titleClientComboBox' ) }
										fnOnChange={ handleChangeClient }
										defaultValue={ holderId }
									/>
								</Grid>

								<AccountCombobox item xs={ 12 } md={ 4 } sx={ { marginLeft: "2rem" } }>
									<ComboBox
										textStyle={ { fontWeight: '700' } }
										data={ dataAccount.accounts.map( acc => ( { ...acc, remark: `[${ acc.id }] ${ acc.remark }` } ) ) }
										fieldLabelOption={ 'remark' }
										label={ fnLocalizeFilter( locale, '_lang.module.dataGroup.accounts' ) }
										fnOnChange={ handleChangeAccount }
										defaultValue={ accountId }
									/>
								</AccountCombobox>
							</Grid>

						</Grid>

						<Grid item xs={ 12 } md={ 9 }
						      style={ { display: "flex", justifyContent: "flex-start", padding: "1rem", alignItems: "center" } }>
							<ClientGroupTitle mt={ 1 }
							                  title={ `${ fnLocalizeFilter( locale, '_lang.module.common.title.titleCards' ) }` }/>
							{/* Фильтр таблицы */ }

							<FilterTable
								type={ 'cards' }
								onFilterClear={ handleClearFilter }
								onFilterChange={ handleChangeFilter }
								onFilterCommit={ handleCommitFilter }
							/>

						</Grid>

						<Grid item xs={ 12 } md={ 10 } style={ { flexDirection: 'column', padding: "1rem" } }>

							<CustomTable
								rows={ rows.arr }
								columns={ columns }
								isLoading={ isLoadingAccount || isLoadingCards || isLoadingClient }
								count={ rows.count }
								labelEmptyRows={ fnLocalizeFilter( locale, '_lang.module.common.table.emptyRows' ) }
								rowsPerPageOptions={ [ countPerOnPage ] }
								fnRefreshData={ handleChangePage }
								accountId={ accountId }
								holderId={ holderId }
							/>

						</Grid>


					</Grid>
				</BackToLink>
			</InnerContainer>
		</FilterContext.Provider>
	</>
}
