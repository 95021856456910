import { Button, Grid, Typography } from "@mui/material";
import { useCustomStyles } from "../../../../style/global";

import InputFilled from "../../../UI/Input/InputFilled";
import { fnLocalizeFilter } from "../../../../services/localize.service";
import classNames from 'classnames';
import { useStyles } from "../style";
import { useGlobalContext } from "../../../../services/context.service";
import React, { useEffect, useState } from "react";
import { TShowTypeForm } from "../Auth";
import { authAPI } from "../../../../services/authAPI.service";


interface ISendOTP {
	phone: string;
	type: TShowTypeForm;

	goHome (): void;
}

interface IStatePass {
	pass: string;
	rePass: string;
	otp: string;
	isError: boolean;
}

export default function GetNewPass ( {  phone, type, goHome }: ISendOTP ) {
	const classes = useStyles();
	const global = useCustomStyles();
	const { locale } = useGlobalContext();

	const [ getRegister ] = authAPI.useLazyUserRegisterOrForgotQuery();

	const [ newPass, setPass ] = useState<IStatePass>( {
		pass: '',
		rePass: '',
		isError: false,
		otp: ''
	} );

	useEffect( () => {
		if ( newPass.pass !== newPass.rePass ) {
			setPass( prev => ( { ...prev, isError: true } ) )
		} else {
			setPass( prev => ( { ...prev, isError: false } ) )
		}
	}, [ newPass.pass, newPass.rePass ] );

	const handleChangePass = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		const { name, value } = e.target;
		const newState = {
			...newPass,
			[ name ]: value
		}

		setPass( newState );
	};

	const handleRegister = () => {
		/* Действие с паролем */
		getRegister( { locale, otp: newPass.otp, phone, password: newPass.pass, type } )
			.then( res => {
				if ( res && res.data && res.data.errorCode === 0 ) {
					goHome();
				}
			} );
	};

	return <>
		<Grid item
		      className={ global.textDefault }>{ fnLocalizeFilter( locale, '_lang.module.register.welcomeTextOTP' ) }
		</Grid>

		<InputFilled label={ fnLocalizeFilter( locale, '_lang.module.register.titleEnterPass' ) } name={ "pass" }
		             sx={ { "& > label": { color: `${ newPass.isError && 'red' }` } } }
		             onChange={ handleChangePass }
		             type={ 'password' }
		/>
		<InputFilled label={ fnLocalizeFilter( locale, '_lang.module.register.titleEnterRePass' ) } name={ "rePass" }
		             sx={ { "& > label": { color: `${ newPass.isError && 'red' }` } } }
		             onChange={ handleChangePass }
		             type={ 'password' }
		/>

		<InputFilled label={ fnLocalizeFilter( locale, '_lang.module.register.titleOTP' ) } name={ "otp" }
		             style={ { marginTop: "2rem" } }
		             onChange={ handleChangePass }
		/>

		<Button variant="contained"
		        style={ {
			        width: "100%",
			        marginTop: ".7rem",
			        padding: ".7rem 0"
		        } }
		        onClick={ handleRegister }
		> {
			( type === 'register' )
				? fnLocalizeFilter( locale, '_lang.module.register.btnRegisterOTP' )
				: fnLocalizeFilter( locale, '_lang.module.register.btnUpdatePassOTP' )
		}</Button>

		<Typography className={ classNames( global.textDefault, classes.btnRegister ) }
		            style={ { margin: ".5rem auto", fontSize: "12px", } }
		            onClick={ goHome }
		>{ fnLocalizeFilter( locale, '_lang.module.login.btnEnter' ) }</Typography>

	</>
}