import { ITable } from "./Interface/Table/ITable";
import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "./components/CustomToolbar";
import CustomPagination from "./components/CustomPagination";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { GridCallbackDetails } from "@mui/x-data-grid/models/api";
import { GRID_RU_LOCALE_TEXT } from "./config/RU_textConstants";
import { GRID_UA_LOCALE_TEXT } from "./config/UA_textConstants";
import { GRID_US_LOCALE_TEXT } from "./config/US_textConstants";
import { IButton } from "./components/Button/button.interface";
import { GridColumns } from "@mui/x-data-grid/models/colDef/gridColDef";
import Loading from "../Loading/Loading";

const Filter = lazy( () => import('./components/Filter') );

const StyledBox = styled( Box )( ( { theme } ) => ( {
	display: 'flex',
	flexDirection: 'column',
	minHeight: 600,
	width: '100%',
	'& .MuiFormGroup-options': {
		alignItems: 'center',
		paddingBottom: theme.spacing( 1 ),
		'& > div': {
			minWidth: 100,
			margin: theme.spacing( 2 ),
			marginLeft: 0,
		},
	},
} ) );

const StyledDataGrid = styled(DataGrid)(()=>({
	"& .MuiDataGrid-renderingZone": {
		maxHeight: "none !important"
	},
	"& .MuiDataGrid-cell": {
		lineHeight: "unset !important",
		maxHeight: "none !important",
		whiteSpace: "normal"
	},
	"& .MuiDataGrid-row": {
		maxHeight: "none !important"
	},
	"& .MuiDataGrid-virtualScroller":{

	}
}));

/**
 * Отображение данных DataGrid
 * @param title - Заголовок таблицы
 * @param loading - Отображение спинера  пока загружются данные в таблицу
 * @param buttons - Кнопки редактирования
 * @param count - Общее кол-во строк
 * @param columns - Заголовки колонок
 * @param id - Текстовое название поля идентификатора (если отличается от "id")
 * @param rows - строки данных
 * @param isSimple - Простая таблица
 * @param showPagination - Простая таблица, но с пацинацией, применяется вместе с isSimple = true
 * @param filter - фильтр отображания таблицы
 * @param sorting
 * @param rowsPerPageOptions - Массив кол-ва строк на странице
 * @param rowsPerPageDefault - Кол-во строк в таблице по умолчанию
 * @param fnRefreshData - Функция вызываема при изменении внутреннего состояния фильтров
 * @param fnRowClick - Функция вызываемая при нажатии на строку
 * @param fnCellClick - Функция вызываемая при нажатии на ячейку
 * @param fnRowDoubleClick - Функция вызываемая при двойном нажатии на строку
 * @param sxTable - кастомный стиль для таблицы
 * @param sx - кастомный стиль для оберточного блока
 * @param locale - язык модуля UA, RU, US (по уиолчанию UA )
 * @param viewFilter - отображение кнопки фильтра для таблицы (по умолчанию отображается)
 * @param themeMode - переменная с данными текущей темы
 * @constructor
 */
const Table = ( {
	                title = "",
	                loading,
	                buttons,
	                count,
	                columns,
	                id,
	                rows,
	                isSimple = false,
	                showPagination = false,
	                filter = [],
	                sorting,
	                rowsPerPageOptions = [ 25, 50, 100 ],
	                rowsPerPageDefault = rowsPerPageOptions[ 0 ],
	                fnRefreshData,
	                fnRowClick,
	                fnCellClick,
	                fnRowDoubleClick,

	                sxTable,
	                sx,
	                locale = "uk",
	                viewFilter = true,
	                themeMode
                }: ITable ) => {

	const [ openFilter, setOpenFilter ] = useState<boolean>( false );
	const [ pageNum, setPageNum ] = useState<number>( 0 );
	const [ rowsPerPage, setRowsPerPage ] = useState<number>( rowsPerPageDefault );

	useEffect( () => {
		rowsPerPageDefault && setRowsPerPage( rowsPerPageDefault )
	}, [ rowsPerPageDefault ] );


	const rowsTable = useMemo( () => {
		return ( id )
			? rows.map( ( row, idx ) => ( { numLine: idx + 1, id: row[ id ], ...row } ) )
			: rows
	}, [ rows ] );


	/* Установка языка модуля */
	const returnLocale = useMemo( () => {
		switch ( locale ) {
			case "uk": {
				return GRID_UA_LOCALE_TEXT;
			}
			case "ru": {
				return GRID_RU_LOCALE_TEXT;
			}
			case "US": {
				return GRID_US_LOCALE_TEXT;
			}
		}
	}, [ locale ] );

	/**
	 * Колбэк выполняющийся после изменения поля сортировки
	 */
	const onSortModelChange = async ( model: GridSortModel, details: GridCallbackDetails ) => {
		const { field, sort } = model[ 0 ] || {};
		const newSort = field ? { field, sort } : {};

		if ( fnRefreshData ) {
			fnRefreshData( filter, { ...sorting, ...newSort } );
		}
	};

	const onFilterChange = async ( filter: any ) => {
		if ( fnRefreshData ) {
			setPageNum( 1 );
			fnRefreshData( filter, { ...sorting, pageNum: 1 } );
		}
	};

	const onChangeRowsPerPage = ( newRowsPerPage: number ) => {

		if ( fnRefreshData ) {
			setRowsPerPage( newRowsPerPage );
			fnRefreshData( filter, { ...sorting, countOnPage: newRowsPerPage } );
		}

	};

	const onChangePage = ( newPage: number ) => {

		if ( fnRefreshData ) {
			setPageNum( newPage );
			fnRefreshData( filter, { ...sorting, pageNum: newPage } );
		}

	};

	const renderButton = useMemo( (): IButton[] => {
		/* Отображаем кнопки только если это не упрощенная таблица */
		if ( viewFilter && !isSimple ) {
			const filterButton: IButton = {
				tooltip: "",
				iconButton: "filter",
				onClick: () => {
					setOpenFilter( true )
				}
			};

			return [ ...buttons as IButton[], filterButton ]

		} else {
			return buttons as IButton[];
		}

	}, [ viewFilter, buttons ] );


	return <StyledBox sx={ { ...sx } }>
		<StyledDataGrid
			columns={ columns as GridColumns }
			rows={ rowsTable }
			localeText={ returnLocale }
			sx={ { ...sxTable } }
			components={
				/**
				 *  Проверяем, если это просто режим, но необходима пагинация, либо обычный включающий в себя все фильтро-кнопки
				 */
				isSimple
					? showPagination ? { Pagination: CustomPagination } : {}
					: {
						Pagination: CustomPagination,
						Toolbar: CustomToolbar,
					}
			}
			componentsProps={ {
				toolbar: { buttons: renderButton, title, columns },
				pagination: {
					pageNum,
					rowsPerPageDefault: rowsPerPage,
					rowsPerPageOptions,
					count,
					onChangeRowsPerPage,
					onChangePage,
					disabled: true
				}
			} }

			hideFooterPagination={ 	isSimple ? !showPagination  : false }
			autoHeight

			hideFooter={ isSimple ? !showPagination : false }


			disableColumnFilter
			density={ "compact" }
			loading={ loading }

			onPageSizeChange={ ( newPageSize ) => console.log( 'newPageSize', newPageSize ) }
			rowsPerPageOptions={ rowsPerPageOptions }

			onSortModelChange={ onSortModelChange }

			onRowClick={ ( data ) => {
				fnRowClick && fnRowClick( data );
			} }


			onRowDoubleClick={ ( data ) => {
				fnRowDoubleClick && fnRowDoubleClick( data );
			} }

			onCellClick={ ( data ) => {
				fnCellClick && fnCellClick( data );
			} }
			onCellEditCommit={ ( e ) => {
				//	console.log( 'onCellEditCommit', e )
			} }

		/>
		{
			openFilter && <Suspense fallback={<Loading type={'fullScreen'}/> }>
          <Filter
              open={ openFilter }
              columns={ columns }
              filter={ filter }
              onClose={ () => setOpenFilter( false ) }
              onFilterChange={ onFilterChange }
              themeMode={ themeMode }
          />
        </Suspense>
		}
	</StyledBox>
};

export default Table;