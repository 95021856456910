import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { catchResponse, prepareFetchRequest } from "./common.service";
import { IKinds, IResKinds, IResReportList, IResReportTrn } from "./response.interface";
import { IReqReportTransaction } from "./request.interface";
import moment from "moment";
import { ILocale } from "./localize.service";


export const reportAPI = createApi( {
	reducerPath: 'reportAPI',
	baseQuery: fetchBaseQuery( prepareFetchRequest() ),
	tagTypes: [ 'report', 'trnKinds' ],
	endpoints: ( build ) => ( {
		fetchAllTransactionKinds: build.query<IResKinds, { locale: ILocale }>( {
			async queryFn ( { locale }, { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/common/transactions/kinds`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );

				const resultXLS = await fetchWithBQ( {
					url: `/common/reports`,
					params: {
						lang: locale
					}
				} );

				catchResponse( { data: resultXLS.data, meta: resultXLS.meta, getState, dispatch } );


				// let data = {kinds : [...result.data.kinds, ...resultXLS.data.reports]} as IResKinds;

				let data = result.data as IResKinds;
				data.kinds = data.kinds.map( kind => ( { ...kind, type: 'webReport' } ) );

				let dataXLS = resultXLS.data as IResReportList;

				for ( let i = 0; i < dataXLS.reports.length; i++ ) {
					const { reportId, name, useHolderSelector, useCardSelector, useToDate, useFromDate } = dataXLS.reports[ i ];
					const newItem: IKinds = {
						id: reportId,
						name,
						type: 'xlsReport',
						useCardSelector,
						useHolderSelector,
						useFromDate,
						useToDate
					};

					data.kinds.push( newItem )
				}

				return { data }

			},
			providesTags: result => [ 'trnKinds' ]
		} ),
		/* Получение всех кошельков счета */
		getReportTransaction: build.query<IResReportTrn, IReqReportTransaction>( {
			async queryFn ( {
				                locale, holderId, cardId = '',
				                fromDate = moment().format( 'YYYY-MM-01' ),
				                toDate = moment().format( 'YYYY-MM-DD' ),
				                limit, page, operation_kind, with_cancelation
			                },
			                { getState, dispatch }, extraOptions, fetchWithBQ ) {

				const result = await fetchWithBQ( {
					url: `/common/transactions`,
					params: {
						lang: locale,
						holderId, cardId, fromDate, toDate, limit, page, operation_kind, with_cancelation
					}
				} );

				catchResponse( { data: result.data, meta: result.meta, getState, dispatch } );

				const data = result.data as IResReportTrn;

				data.transactions = data.transactions.map( trn => {
					return {
						...trn,
						sumWithDiscount: trn.amount + trn.discount
					}
				} )

				return { data }

			},
			providesTags: result => [ 'report' ]
		} ),

	} )
} );

export const {} = reportAPI;
