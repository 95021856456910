import { fnLocalizeFilter } from "../../../../../services/localize.service";
import InputPhone from "../../../../UI/Input/InputPhone";
import { useGlobalContext } from "../../../../../services/context.service";
import React from "react";
import { Button } from "@mui/material";

interface ISendOTP {
	value: string;

	onChange ( e: React.ChangeEvent<HTMLInputElement> ): void;
	onSendOTP ( ): void;
}

export default function SendOTP ( { value, onChange, onSendOTP }: ISendOTP ) {
	const { locale } = useGlobalContext();
	

	return <><InputPhone
		label={ fnLocalizeFilter( locale, '_lang.module.register.titlePhone' ) }
		value={ value }
		onChange={ onChange }
		sx={ { width: "97%" } }
	/>
		<Button
			variant="contained"
			style={ {
				width: "100%",
				marginTop: "1rem",
				padding: ".7rem 0"
			} }
			onClick={ onSendOTP }>
			{ fnLocalizeFilter( locale, '_lang.module.register.btnRegisterPhone' ) }
		</Button>
	</>
}