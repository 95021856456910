import { createContext, useContext } from 'react';
import { ILocale } from "./localize.service";


export type GlobalContext = {
	locale: ILocale;
}

export const Context = createContext<GlobalContext>({
	locale: "uk"
});

export const useGlobalContext = () => useContext(Context)

export default Context;