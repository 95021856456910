import { Grid } from "@mui/material";
import { useCustomStyles } from "../../../../style/global";

import React, { useState } from "react";
import { fnLocalizeFilter } from "../../../../services/localize.service";
import { useGlobalContext } from "../../../../services/context.service";
import GetNewPass from "./GetNewPass";
import GetPhone from "./GetPhone";
import { TShowTypeForm } from "../Auth";
import { showTooltip } from "../../../../store/actions/layoutActions";
import { accountAPI } from "../../../../services/accountAPI.service";
import { useAppDispatch } from "../../../../hooks/redux";


interface IRegister {
	linkLogin ( type: TShowTypeForm ): void;

	showForm: TShowTypeForm;
}


export default function Register ( { linkLogin, showForm }: IRegister ) {

	const global = useCustomStyles();
	const { locale } = useGlobalContext();
	const dispatch = useAppDispatch();

	const [ isSendOTP, setSendOTP ] = useState<boolean>( false );

	const [ phone, setPhone ] = useState<string>( '' );
	const [ sendOTP ] = accountAPI.useLazySendOTPQuery();

	const handleChangePhone = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		setPhone( e.target.value );
	}

	const handleGoHome = () => {
		linkLogin( 'default' );
	};

	const handleSendOTP = () => {
		if ( phone.length < 12 ) {
			dispatch( showTooltip( { type: "error", message: fnLocalizeFilter( locale, '_lang.error.1004' ) } ) );
		} else {
			phone && sendOTP( { locale, phone } ).then( res => {
				if ( res && res.data && res.data.errorCode === 0 ) {
					setSendOTP( true );
				}
			} )
		}
	};

	return <>
		<Grid item className={ global.titleBold }
		      style={ {
			      margin: "0 auto 1vw 0",
			      fontSize: "36px",
			      fontWeight: 700
		      } }> { showForm === 'register' ? fnLocalizeFilter( locale, '_lang.module.register.registerCRM' ) : fnLocalizeFilter( locale, '_lang.module.register.updateCRM' ) } </Grid>

		{/* меняем текст в случае отправки ОТП пароля */ }

		{
			isSendOTP
				? <GetNewPass goHome={ handleGoHome } phone={ phone } type={ showForm }/>
				: <GetPhone
					onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => setPhone( e.target.value ) }
					onSendOTP={ handleSendOTP }
					phone={ phone }
					goHome={ handleGoHome }
				/>
		}


	</>
}