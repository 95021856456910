import { fnLocalizeFilter, ILocale } from "../../../services/localize.service";
import { IColumn } from "../../UI/CustomTable/components/Columns/Columns";
import { ISingleRow } from "../../UI/CustomTable/interface/interface.custom";
import { handleSwitchStatusAccount } from "./utils/local.request.api";
import { IAccounts } from "../../../services/response.interface";
import { AppDispatch } from "../../../store/store";
import { checkPermission } from "../../../helpers/permissions";

interface IGetColumnsAccount {
	locale: ILocale;
	accounts: IAccounts[];
	holderId: number;
	dispatch: AppDispatch;
}

export const getColumns = ( { locale, holderId, dispatch, accounts }: IGetColumnsAccount ): IColumn[] => {

	const onToggleSwitch = async ( { row }: ISingleRow ) => {

		return await handleSwitchStatusAccount( {
			row,
			locale,
			accounts,
			holderId,
			dispatch
		} );

	};

	return [
		{
			field: 'collapse',
			label: '',
			formatHeader: 'empty',
			cell: { format: 'collapse', type: 'accounts' },
			align: 'right'
		},
		{
			field: 'accountId',
			label: fnLocalizeFilter( locale, '_lang.module.accounts.titleID' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center',
			sortable: true
		},
		{
			field: 'remark',
			label: fnLocalizeFilter( locale, '_lang.module.accounts.titleRemark' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			sortable: true,
		},
		{
			field: 'totalBalance',
			label: fnLocalizeFilter( locale, '_lang.module.accounts.titleTotalBalance' ),
			formatHeader: 'default',
			cell: { format: 'numericalDigit' },
			align: 'center',
			alignData: 'center',
			sortable: true
		},
		{
			field: 'totalBalanceWithoutHold',
			label: fnLocalizeFilter( locale, '_lang.module.accounts.titleTotalBalanceWithoutHold' ),
			hint: fnLocalizeFilter( locale, '_lang.module.accounts.titleTotalBalanceWithoutHoldHint' ),
			formatHeader: 'default',
			cell: { format: 'numericalDigit' },
			align: 'center',
			alignData: 'center',
			sortable: true
		},
		{
			field: 'overdraft',
			label: fnLocalizeFilter( locale, '_lang.module.accounts.titleOverdraft' ),
			formatHeader: 'default',
			cell: { format: 'numericalDigit' },
			align: 'center',
			alignData: 'center',
			sortable: true
		},
		{
			field: 'active',
			label: fnLocalizeFilter( locale, '_lang.module.common.table.active' ),
			formatHeader: 'default',
			cell: {
				format: checkPermission( { permissionName: 'Accounts' } ) > 1 ? 'switch-account' : 'boolean',
				onToggleSwitch
			},
			align: 'center',
			alignData: 'center',
			sortable: true
		},
		{
			field: 'btnAccount',
			label: fnLocalizeFilter( locale, '_lang.module.accounts.titleCardsQty' ),
			formatHeader: 'default',
			cell: { format: 'link-opacity-account', label: fnLocalizeFilter( locale, '_lang.module.accounts.titleCards' ) },
			align: 'center',
			alignData: 'center',
			sortable: true
		}
	];
};
