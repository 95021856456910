import { IGlobalLocale } from "./locale.interface";

export const ru: IGlobalLocale = {
	module: {
		menu: {
			0: {
				title: "Клиенты",
				hint: "Справочник клиентов"
			},
			1: {
				title: "Счета",
				hint: "Справочник счетов"
			},
			2: {
				title: "Карты",
				hint: "Карты клиентов"
			},
			3: {
				title: "Отчёты",
				hint: "Отчёты"
			},
			4: {
				title: "Документация",
				hint: "Документация"
			},
			5: {
				title: "Настройки",
				hint: "Настройки"
			}
		},
		login: {
			enterCRM: 'Войти в CRM',
			welcomeText: 'Используйте данные для входа, полученные у администратора CRM',
			titleLogin: 'Логин',
			titlePassword: 'Пароль',
			btnRegister: 'Регистрация',
			btnForgotPass: 'Забыли пароль',
			btnEnter: 'Войти',
			btnLogout: 'Выйти'
		},
		register: {
			registerCRM: 'Регистрация CRM',
			updateCRM: 'Замена пароля в CRM',
			welcomeTextPhone: 'Для регистрации или напоминания, пожалуйста введите номер телефона',
			welcomeTextOTP: 'Введите ОТП пароль, который Вам было отправлено на указанный номер телефона',
			titlePhone: 'Телефон',
			titleOTP: 'ОТП пароль',
			btnRegisterPhone: 'Отправить ОТП пароль',
			btnRegisterOTP: 'Зарегистрироваться',
			btnUpdatePassOTP: 'Обновить данные',
			titleEnterPass: 'Введите пароль',
			titleEnterRePass: 'Повтор пароля'
		},
		clientList: {
			placeholderSearchString: 'Поиск по названию или ОКПО',
			titleAccounts: 'Счета',
			titlePage: 'Клиенты',
			titleID: 'ID',
			titleNameClient: 'Название клиента',
			titleOKPO: 'ОКПО',
			titleINN: 'ИНН',
			titlePhone: 'Телефон',
			titleEmail: 'Почта',
			titleAddress: 'Адрес',
			titleLegalAddress: 'Юридический адрес',
			titleNumContract: 'Номер договора',
			titleStatus: 'Статус клиента',
		},
		accounts: {
			titleID: 'ID',
			titleRemark: 'Описание',
			titleRest: 'Остаток',
			titleOverdraft: 'Овердрафт',
			titleTotalBalance: 'Баланс счета',
			titleTotalBalanceHint: 'Доступно',
			titlePurses: 'Кошельки',
			titleTotalBalanceWithoutHold: 'Баланс',
			titleTotalBalanceWithoutHoldHint: 'Баланс счета без учёта холда',
			titleCardsQty: 'Карты(кол-во)',
			titleCards: 'Карты',
			btnAccountLink: 'Перейти к счетам'
		},
		cards: {
			titleID: 'Карта',
			titleVin: 'VIN',
			titlePhone: 'Телефон',
			titleName: 'Фамилия, имя',
			titleSetting: 'Налаштування',
			titleConfirmRenewPin: 'Вы действительно хотите заменить текущий pin?',
			titleShowNewPin: 'Новый PIN',
			titleBtnSendOtp: 'Отправить ОТП',
			titleCodeFromSMS: 'Код из смс',
			titleBtnCommit: 'Заменить номер',
			limits: {
				titleBtnAddLimit: 'Добавить лимит',
				titlePurses: 'Кошельки',
				titleMoneyByDay: 'Грн/Сутки',
				titleQtyByDay: 'Літри/Сутки',
				titleMoneyByMonth: 'Грн/Месяц',
				titleQtyByMonth: 'Літри/Месяц',
				titleBalance: 'Оборот',
				titleLimitAmount : 'Значение лимита',
				titleLimitBalance : 'Остаток по лимиту',
				newLimit: {
					title: 'Счёт',
					titleIndividual: 'Индивидаульный',
					titleCommon: 'Общий',
					titleTypeLimit: 'Тип лимита',
					titleLimitAmount: 'Сумма'
				}
			},
			qr: {
				notMatchResolution : "не походит разрешение экрана, пожалуйста, поверните телефон",
				contextMenu: {
					blocked: 'Заблокировать',
					confirmBlockedCard: 'Вы действительно хотите заблокировать карту?',
					limits: 'Просмотр лимитов'
				},
				status: {
					0: "Заблокирована",
					1: "Активная"
				}
			}
		},
		reports: {
			titleTrnKind: 'Вид отчёта',
			titleDate: 'Дата',
			titleCard: 'Карта',
			titleAddress:'Адрес АЗС',
			titlePurse: 'Кошелек',
			titleQty: 'Кол-во',
			titlePrice :'Цена',
			titleSum: 'Сумма',
			titleDiscount: 'Скидка',
			titleSumWithDiscount :'Сумма со скидкой',
			titleName: 'Название',
			titleUseFromDate: 'От даты',
			titleUseToDate: 'До даты'
		},
		setting :{
			titleChangePass: 'Сменить пароль',
			titleChangeUser: 'Пользователи',
			titleAddNewUser: 'Добавить нового пользователя',
			users: {
				titleFio: 'Фамилия, имя',
				titleFirstName: 'Имя',
				titleLastName: 'Фамилия',
				titlePhone: 'Телефон',
				titleEmail: 'Email',
				titleApiRole:'Роль'
			}
		},
		dataGroup: {
			infoClient: 'Информация клиента',
			accounts: 'Счета',
			analytics: 'Аналитика',
			limits: 'Лимиты',
			additionalInfo: 'Дополнительная информация',
			analyticInfo: 'Аналитика'
		},
		common: {
			table: {
				num: '#',
				active: 'Статус',
				filter: 'Фильтр',
				clearFilter: 'Очистить фильтр',
				emptyRows: 'Строки отсутствуют',
				setting: 'Настройки'
			},
			filter: {
				title: 'Фильтр',
				titleSubmit: 'Применить',
				titleStatus: 'Статус',
				titleIsActive: 'Активный',
				titleIsBlock: 'Заблокировано',
				titleBalance: 'Баланс',
				titleNumCard: 'Номер карты',
				titlePhone: 'Телефон',
				titleVIN: 'VIN',
				titleFirstName: 'Имя',
				titleLastName: 'Фамилия',
				titleFrom: 'От',
				titleTo: 'До',
				titleAccountName: 'Название',
				titleSelectAll: 'Все'
			},
			title: {
				backToClients: 'Назад к списку клиентов',
				backToCards: 'Назад к списку карт',
				titleClientComboBox: 'Клиент',
				titleAccountComboBox: 'Счет',
				titleCards: 'Карты',
				titleDataNotFound: 'Данные не найдены',
				titleNotInitialized: 'Не определено',
				titleCancel: 'Отмена',
				titlePeriod: 'Период',
				titleDocumentation: 'Документация',
				titleSearchStr: 'Строка поиска',
				titleSelected: 'Выбрано'
			},
			modal: {
				type: {
					info: 'Информация',
					success: 'Успешное выполнение',
					warning: 'Предупреждение',
					error: 'Ошибка',
					confirmation: 'Подтверждение',
					default: ''
				},
				labelConfirmation: 'Вы действительно хотите удалить строку?'
			}
		}
	},
	statusClient: {
		0: "Активный",
		1: "Заблокированный",
		2: "Удаленный",
		3: "Перемещенный"
	},
	error: {
		0: "Нет ошибки",
		1: "Введенный данные не являюся номером телефона",
		2: "Номер телефона не найден",
		3: "Неверный OTP",
		4: "Користувач був зареєстрований раніше",
		5: "Користувач не знайдений",
		6: "Невірний пароль або номер телефону (email)",
		7: "Клієнт не належить користувачеві. (Якщо йде запит холдера (рахунки, карти), який не прив'язаний до користувача)",
		8: "Ошибочные параметры",
		9: "Ошибка при создании лимита",
		10: "Не удалось установить новый пин",
		1000: "Неизвестная ошибка",
		1001: "Пользователь не авторизован",
		1002: "Операция выполнена успешно",
		1003: "Во время выполнения произошла ошибка",
		1004: "Не корректный номер телефона",
		1005: "Ошибка запроса",
		1006: "Запрос не авторизован",
		validation : {
			fieldRequired: 'Обзательное поле',
			fieldErrorEmail: 'Некорректный email'
		}
	}
}
