import { Box, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useGlobalContext } from "../../../services/context.service";
import DownloadLink from "../../UI/Links/DownloadLink";
import { fnLocalizeFilter } from "../../../services/localize.service";
import { useEffect, useMemo, useState } from "react";

const BoxContainer = styled( Box )( () => ( {
	width: "100%",
	height: "100%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	fontWeight: 600,
	gap: 15
} ) );

export default function HelperLinks () {

	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();
	/* Отображаем QR только если разрешение менее 1024 */
	const matches = useMediaQuery( '(max-width:1024px)' );

	const [ files, setFiles ] = useState<{ [ key: string ]: any }>();

	const getFiles = async () => {
		const config = await fetch( 'config.json' )
			.then( function ( response ) {

				return response.json();
			} );

		config && setFiles( config.files )
	};

	useEffect( () => {
		getFiles().then();
	}, [] );

	const renderFiles = useMemo( () => {
		const arrLink = [];
		for ( const key in files ) {
			arrLink.push( { link: key, title: files[ key ] } )
		}

		return arrLink.map( ( item, idx ) => (
			<Box><Typography sx={ { display: "inline" } }>{ idx + 1 }. </Typography> <DownloadLink
				url={ `./info/${ item.link }` } title={ `${ item.title }` }/></Box> ) );
	}, [ files ] );


	return <>
		<Typography
			textAlign={ 'center' }>{ fnLocalizeFilter( locale, '_lang.module.common.title.titleDocumentation' ) }</Typography>
		<BoxContainer>{ renderFiles }</BoxContainer>
	</>
}
