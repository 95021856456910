import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TitleBlock from "../../TitleBlock/TitleBlock";
import InputFilled from "../../Input/InputFilled";
import React, { useEffect, useState } from "react";
import InputCheckbox from "../../Input/InputCheckbox";
import { IFilterAccountsField } from "../../../Module/Accounts/Filter/interface.filter";
import { IFilterCardsField, initialStateFilterCard } from "../../../Module/Cards/CardList/Filter/interface.filter";
import { useFilterCardContext } from "../../../Module/Cards/CardList/Filter/context.card";
import { useGlobalContext } from "../../../../services/context.service";
import { fnLocalizeFilter } from "../../../../services/localize.service";

const Title = styled( Typography )( () => ( {
	fontSize: "2rem",
	fontWeight: 600,
	textAlign: "center"
} ) );

const FilterInput = styled( InputFilled )( ( props ) => {
	const { property } = props;
	const style = property === 'fullWidth'
		? { maxWidth: "initial", marginTop: "2rem" }
		: { maxWidth: "150px", marginTop: "0 !important" };

	return ( {
		...style,
		marginRight: property === 'left' ? ".5rem" : "0",
		marginLeft: property === 'right' ? ".5rem" : "0"
	} )
} );


interface IFilterAccounts {
	onFilterChange ( filter: IFilterAccountsField | any ): void;
}

export default function FilterCards ( { onFilterChange }: IFilterAccounts ) {
	/* Получаем вильтры из Аккаунтов */
	const { filter } = useFilterCardContext();
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	const [ formFilter, setFilter ] = useState<IFilterCardsField>( initialStateFilterCard );

	useEffect( () => {
		filter && setFilter( prev => ( { ...prev, ...filter } ) )
	}, [ filter ] )

	const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		const { id, value, checked } = e.currentTarget;
		let newFilter = formFilter;
		switch ( id ) {
			case "isActive":
			case "isBlock": {
				newFilter = { ...newFilter, active: { ...newFilter.active, [ id ]: checked } };
				break;
			}
			/* Все текстовые варианты */
			default : {
				newFilter = { ...newFilter, [ id ]: value };
				break;
			}
		}

		setFilter( newFilter )
		onFilterChange( newFilter );
	};

	return <Box sx={ { minWidth: "300px" } }>
		<Title>{ fnLocalizeFilter( locale, '_lang.module.common.filter.title' ) }</Title>
		<TitleBlock title={ 'Статус' }>
			<Box display={ "flex" } flexDirection={ "column" }>
				<InputCheckbox label={ fnLocalizeFilter( locale, '_lang.module.common.filter.titleIsActive' ) }
				               id={ 'isActive' } onChange={ handleChange }
				               defaultChecked={ formFilter.active.isActive }/>
				<InputCheckbox label={ fnLocalizeFilter( locale, '_lang.module.common.filter.titleIsBlock' ) } id={ 'isBlock' }
				               onChange={ handleChange }
				               defaultChecked={ formFilter.active.isBlock }/>
			</Box>

		</TitleBlock>
		<TitleBlock title={ fnLocalizeFilter( locale, '_lang.module.common.filter.titleBalance' ) }
		            sx={ { marginTop: "1.5rem" } }>
			<Box display={ "flex" } flexDirection={ 'column' }>

				<FilterInput id={ 'cardId' } onChange={ handleChange }
				             label={ fnLocalizeFilter( locale, '_lang.module.common.filter.titleNumCard' ) }
				             property={ 'fullWidth' } value={ formFilter.cardId }/>
				<FilterInput id={ 'mobile' } onChange={ handleChange }
				             label={ fnLocalizeFilter( locale, '_lang.module.common.filter.titlePhone' ) }
				             property={ 'fullWidth' } value={ formFilter.mobile }/>
				<FilterInput id={ 'vin' } onChange={ handleChange }
				             label={ fnLocalizeFilter( locale, '_lang.module.common.filter.titleVIN' ) }
				             property={ 'fullWidth' } value={ formFilter.vin }/>
				<FilterInput id={ 'firstName' } onChange={ handleChange }
				             label={ fnLocalizeFilter( locale, '_lang.module.common.filter.titleFirstName' ) }
				             property={ 'fullWidth' } value={ formFilter.firstName }/>
				<FilterInput id={ 'lastName' } onChange={ handleChange }
				             label={ fnLocalizeFilter( locale, '_lang.module.common.filter.titleLastName' ) }
				             property={ 'fullWidth' } value={ formFilter.lastName }/>

			</Box>
		</TitleBlock>
	</Box>
}