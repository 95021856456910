import { ILocale } from "../../../../services/localize.service";
import { AppDispatch } from "../../../../store/store";
import { getAsyncData } from "../../../../services/axios.service";
import { asyncRequestTooltip } from "../../../../utils/methods";
import { IRow } from "../../../UI/CustomTable/components/Rows/Rows";
import { updateQuery } from "../../../../services/update/holder.update.service";
import { IAccounts } from "../../../../services/response.interface";

interface ISwitchStatusAccount {
	locale: ILocale;
	dispatch: AppDispatch;
	row: IRow;
	holderId: number;
	accounts : IAccounts[];
}

export const handleSwitchStatusAccount = async ( { row, locale, accounts, holderId, dispatch }: ISwitchStatusAccount ) => {

	const url = `/accounts/${ row.id }/status?active=${ !row.active }&lang=${ locale }`;
	const res = await getAsyncData( { locale, url } );

	if ( res ) {
		const idx = accounts.findIndex( a => a.id === row.id );
		let newAccounts: any = [ ...accounts ];
		newAccounts[ idx ] = { ...newAccounts[ idx ], active: !newAccounts[ idx ].active };
		dispatch( updateQuery( { locale, holderId, newAccounts } ) );
		return true;
	}

	return asyncRequestTooltip( { res, dispatch, locale } );
}

