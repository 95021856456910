import { REACT_APP_BASE_URL } from "../helpers/api";
import { ILocale } from "./localize.service";
import { getDataFromLocalStorage } from "../utils/methods";
import axios from "axios";

interface IGetAsyncData {
	locale: ILocale;
	url: string;
	req?: any;
	type?: 'post' | 'get' | 'delete';
}

export async function getAsyncData ( { locale, url, req = {}, type = 'post' }: IGetAsyncData ): Promise<any> {

	const reqUrl = REACT_APP_BASE_URL + url;
	const storage = JSON.parse( getDataFromLocalStorage( 'token' ) as string );

	let res;
	switch ( type ) {
		case 'get':
		case 'post': {
			res = await axios[ type ]( reqUrl, req, {
				headers: { 'Authorization': `Bearer ${ storage.token }` },
				validateStatus: ( status: number ) => {
					return true;
				}
			} );
			break;
		}
		case 'delete' : {
			res = await axios[ type ]( reqUrl, {
				headers: { 'Authorization': `Bearer ${ storage.token }` },
				data: req,
				validateStatus: ( status: number ) => {
					return true;
				}
			} );
			break;
		}
	}


	if ( res.status !== 200 ) {
		res.data = ( res.data.errorCode ) ? { ...res.data } : { errorCode: 1003, errorDescription: '' }
	}

	return res.data;

}