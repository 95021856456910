import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Box, IconButton } from "@mui/material";
import { fnLocalizeFilter } from "../../../services/localize.service";
import { useGlobalContext } from "../../../services/context.service";
import Tooltip from '@mui/material/Tooltip';
import FilterAccounts from "./Module/FilterAccount";
import { IFilterAccountsField } from "../../Module/Accounts/Filter/interface.filter";
import { lazy, Suspense, useMemo, useState } from "react";
import Loading from "../Loading/Loading";
import FilterCards from "./Module/FilterCards";

const Modal = lazy( () => import('../Modal/Modal') );

interface IFilterTable {
	type: 'accounts' | 'cards';

	onFilterChange ( newFilter: IFilterAccountsField | any ): void;

	onFilterClear? (): void;

	onFilterCommit (): void;
}

export default function FilterTable ( {
	                                      type,
	                                      onFilterClear,
	                                      onFilterChange,
	                                      onFilterCommit
                                      }: IFilterTable ) {
	const [ isOpenModal, setOpenModal ] = useState( false );
	const { locale } = useGlobalContext();

	const handleFilterClear = () => {
		onFilterClear && onFilterClear();
	};

	const handleOpenFilter = () => {
		setOpenModal( true );
	};

	const handleCommitFilter = () => {
		onFilterCommit();
		setOpenModal( false );
	};

	const returnFilter = useMemo( () => {
		switch ( type ) {
			case 'cards': {
				return <FilterCards onFilterChange={ onFilterChange }/>
			}
			case 'accounts': {
				return <FilterAccounts onFilterChange={ onFilterChange }/>
			}
			default : {
				return <>Фільтр не налаштован належним чином!</>
			}
		}
	}, [ type ] );

	return (
		<Box sx={ { marginLeft: "2rem" } }>

			<Tooltip title={ fnLocalizeFilter( locale, '_lang.module.common.table.clearFilter' ) } placement="top-end">
				<IconButton color={ 'secondary' } onClick={ handleFilterClear }> <FilterAltOffIcon/> </IconButton>
			</Tooltip>

			<Tooltip title={ fnLocalizeFilter( locale, '_lang.module.common.table.filter' ) } placement="top-end">
				<IconButton color={ 'secondary' } onClick={ handleOpenFilter }> <FilterAltIcon/> </IconButton>
			</Tooltip>

			{ isOpenModal && <Suspense fallback={ <Loading type={ 'fullScreen' }/> }>
        <Modal
            handleBtnOk={ handleCommitFilter }
            isOpen={ isOpenModal }
            handleBtnCancel={ setOpenModal }
        >
					{ returnFilter }
        </Modal>
      </Suspense> }
		</Box>
	)

};