import { IFilterApply } from "./interface.filter";
import { IAccounts } from "../../../../services/response.interface";
import { IActiveAccounts, TActive } from "../../../../utils/interface";
import { fieldSort } from "../../../../utils/methods";


export const filterApply = ( { accounts, filter, orderBy }: IFilterApply ): IAccounts[] => {
	let filteredArr = accounts;

	/*Accounts*/
	const { balance, active, name } = filter;

	const filterActive = filterIsActiveAccount( active );

	filteredArr = filteredArr.filter( arr => {
		let checkActive = true;
		let checkBalance = true;

		/* Фильтр активности выбран */
		if ( filterActive !== undefined ) {
			checkActive = arr.active === filterActive
		}

		/* Баланс выбран, не нулевой, from <= to и баланс должен быть between*/
		if ( balance.from <= balance.to && balance.to !== 0 ) {
			checkBalance = balance.from <= arr.totalBalance && arr.totalBalance <= balance.to;
		}

		/* Если поисковая строка заполнена */
		let checkName = ( ( name !== '' && arr.remark.toLocaleUpperCase().includes( name.toLocaleUpperCase() ) ) || name === '' );

		return checkActive && checkBalance && checkName;
	} );

	/* Сортируем если это необходимо */
	if ( orderBy.field !== '' ) {

		filteredArr = fieldSort( { array: filteredArr, orderBy } );

	}

	return filteredArr;

}

const filterIsActiveAccount = ( active: IActiveAccounts ): TActive => {
	const { isActive, isBlock } = active;
	let res: TActive = undefined;

	if ( isActive && !isBlock ) res = true;
	if ( !isActive && isBlock ) res = false;

	return res;
}