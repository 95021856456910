import React from "react";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCustomStyles } from "../../../style/global";

interface IInfoBlock {
	title: string;
	children: React.ReactElement<any, any>;
	sx?: React.CSSProperties;
}

const Container = styled( Grid )( () => ( {
	flexDirection: 'column'
} ) );

export default function TitleBlock ( { title, sx = {}, children }: IInfoBlock ) {
	const classes = useCustomStyles();
	return <>
		<Box sx={ { ...sx } }>
			<Container container>
				<Grid className={ classes.textDefault }>{ title }</Grid>
				<Grid className={ classes.titleDefault }> { children }</Grid>
			</Container>
		</Box>
	</>
}