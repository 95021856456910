import { IHolders, IRoles, IUsers } from "../../../../../services/response.interface";
import { fnLocalizeFilter, ILocale, } from "../../../../../services/localize.service";
import { IColumn } from "../../../../UI/CustomTable/components/Columns/Columns";
import { AppDispatch } from "../../../../../store/store";
import { ISingleRow } from "../../../../UI/CustomTable/interface/interface.custom";
import { handleSwitchStatusAccount } from "../../../Accounts/utils/local.request.api";
import { handleDeleteUser, handleSwitchStatusUser } from "./utils/local.request.api";
import { checkPermission } from "../../../../../helpers/permissions";

interface IGetColumnsAccount {
	locale: ILocale;
	users: IUsers[];
	roles: IRoles[];
	holders: IHolders[];
	dispatch: AppDispatch;
}

export const getColumns = ( { locale, dispatch, users, roles, holders }: IGetColumnsAccount ): IColumn[] => {


	const onToggleSwitch = async ( { row }: ISingleRow ) => {
		return await handleSwitchStatusUser( { row, locale, users, dispatch } );
	};

	const onClickDelete = async ( { row }: ISingleRow ) => {
		return handleDeleteUser( { row, locale, users, dispatch } );
	};

	return [
		{
			field: 'lastName',
			label: fnLocalizeFilter( locale, '_lang.module.setting.users.titleLastName' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center',
			width: 150
		},
		{
			field: 'firstName',
			label: fnLocalizeFilter( locale, '_lang.module.setting.users.titleFirstName' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center',
			width: 150
		},
		{
			field: 'phoneNumber',
			label: fnLocalizeFilter( locale, '_lang.module.setting.users.titlePhone' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center',
			width: 190
		},
		{
			field: 'email',
			label: fnLocalizeFilter( locale, '_lang.module.setting.users.titleEmail' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center',
			width: 250
		},
		{
			field: 'apiRole',
			label: fnLocalizeFilter( locale, '_lang.module.setting.users.titleApiRole' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center',
			width: 230
		},
		{
			field: 'active',
			label: fnLocalizeFilter( locale, '_lang.module.common.table.active' ),
			formatHeader: 'default',
			cell: {
				format: checkPermission( { permissionName: 'PersonalAccounts' } ) > 1 ? 'switch-account' : 'boolean',
				onToggleSwitch
			},
			align: 'center',
			alignData: 'center',
			width: 100
		},
		{
			field: 'listHolders',
			label: fnLocalizeFilter( locale, '_lang.module.clientList.titlePage' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center',
			width: 200
		},

		{
			field: 'btnDeleteUser',
			label: '',
			formatHeader: 'default',
			cell: {
				format: checkPermission( { permissionName: 'PersonalAccounts' } ) > 1 ? 'icon-delete' : 'empty',
				onClickDelete,
				labelConfirm: fnLocalizeFilter( locale, '_lang.module.common.modal.labelConfirmation' )
			},
			align: 'center',
			alignData: 'center'
		}
	];
};