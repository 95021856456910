import { IReqCreateUser } from "../../../../../../services/request.interface";
import { getAsyncData } from "../../../../../../services/axios.service";
import { asyncRequestTooltip } from "../../../../../../utils/methods";
import { updateQuery } from "../../../../../../services/update/user.update.service";
import { ILocale } from "../../../../../../services/localize.service";
import { AppDispatch } from "../../../../../../store/store";
import { IRow } from "../../../../../UI/CustomTable/components/Rows/Rows";
import { ICardLimits, IUsers } from "../../../../../../services/response.interface";
import { updateQueryLimits } from "../../../../../../services/update/card.update.service";

export const handleCreateUser = async ( {
	                                        locale,
	                                        firstName, lastName, phoneNumber, holders, email, apiRole,
	                                        dispatch
                                        }: IReqCreateUser ) => {

	const req = { firstName, lastName, phoneNumber, holders, apiRole, email };
	const url = `/users/create?lang=${ locale }`;
	const res = await getAsyncData( { locale, url, req, type: 'post' } );

	return asyncRequestTooltip( { res, dispatch, locale } );
};

export const handleUpdateUser = async ( {
	                                        locale,
	                                        firstName, lastName, phoneNumber, holders, email, apiRole, active, id,
	                                        dispatch
                                        }: IReqCreateUser ) => {

	const req = { firstName, lastName, phoneNumber, holders, apiRole, email, active };
	const url = `/users/${ id }?lang=${ locale }`;
	const res = await getAsyncData( { locale, url, req, type: 'post' } );

	return asyncRequestTooltip( { res, dispatch, locale } );
};

interface ISwitchStatusAccount {
	locale: ILocale;
	dispatch: AppDispatch;
	row: IRow;
	users: IUsers[];
}

export const handleSwitchStatusUser = async ( { row, locale, users, dispatch }: ISwitchStatusAccount ) => {

	const { firstName, lastName, phoneNumber, holders, email, apiRole, active } = row;
	const req = { firstName, lastName, phoneNumber, holders, apiRole, email, active: !active };

	const url = `/users/${ row.id }?lang=${ locale }`;
	const res = await getAsyncData( { locale, url, req, type: 'post' } );

	if ( res ) {
		const idx = users.findIndex( a => a.id === row.id );
		let newUsers: IUsers[] = [ ...users ];
		newUsers[ idx ] = { ...newUsers[ idx ], active: !newUsers[ idx ].active };
		dispatch( updateQuery( { locale, newUsers } ) );
		return true;
	}

	return asyncRequestTooltip( { res, dispatch, locale } );
}


export const handleDeleteUser = async ( { row, locale, users, dispatch }: ISwitchStatusAccount ) => {

	const req = {};
	const url = `/users/${ row.id }?lang=${ locale }`;
	const res = await getAsyncData( { locale, url, req, type: 'delete' } );

	if ( res && res.errorCode === 0 ) {
		const idx = users.findIndex( u => u.id === row.id );
		let newUsers: IUsers[] = [ ...users.slice( 0, idx ), ...users.slice( idx + 1 ) ];

		dispatch( updateQuery( { locale, newUsers } ) );
	}

	return asyncRequestTooltip( { res, dispatch, locale } );
};