import Table from "../../../UI/Table";
import { useGlobalContext } from "../../../../services/context.service";
import React, { lazy, Suspense, useMemo, useState } from "react";
import { getColumns } from "./columns";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { fnLocalizeFilter } from "../../../../services/localize.service";
import { styled } from "@mui/material/styles";
import InputSearchLine from "../../../UI/Input/InputSearchLine";
import { holderAPI } from "../../../../services/holderAPI.service";
import { getMedia } from "../../../../helpers/media";
import { filterObject } from "../../../../utils/methods";
import { IHolders } from "../../../../services/response.interface";
import Loading from "../../../UI/Loading/Loading";
import { useTheme } from "@mui/styles";

const ClientDetail = lazy( () => import('../ClientDetail/ClientDetail') );

const Container = styled( Grid )( () => ( {
	display: "flex",
	flexDirection: "column",
	overflow: "hidden"

} ) );

const TitleClient = styled( Typography )( ( props ) => {
		return ( {
			fontSize: "2rem",
			fontWeight: 500,
			margin: "3rem 2rem",
			[ props.theme.breakpoints.down( 'md' ) ]: {
				margin: "1rem 1rem"
			}
		} )
	}
);


export default function ClientList () {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	const theme = useTheme();

	/* Инициализация медиа  */
	const matches = useMediaQuery( getMedia() );

	/* Флаг показа детального описание клиента  */
	const [ isOpenDetail, setOpenDetail ] = useState<boolean>( false );

	/* Получаем список клиентов доступных пользователю */
	const { data = { holders: [] }, isLoading } = holderAPI.useFetchAllClientsQuery( locale );

	/* Выбранный клиент */
	const [ selectedClient, setSelectedClient ] = useState<IHolders | undefined>();

	/* Заполняем табличные заголовки */
	const columns = useMemo( () => {
		return getColumns( locale, theme );
	}, [ locale, theme ] );

	/* Строка поиска по всем полям таблицы */
	const [ searchStr, setSearchStr ] = useState( '' );

	/* Фильтр по столбцам таблицы  */
	const rows = useMemo( () => {
		return filterObject( { arr: data?.holders, fields: [ 'name', 'okpo', 'itn', 'phone' ], searchStr } );
	}, [ data.holders, searchStr ] );

	const handleDoubleClick = ( holders: IHolders ) => {
		setSelectedClient( holders );
		setOpenDetail( true );
	};

	return <>
		<Container container>
			<Grid>
				<InputSearchLine placeholder={ fnLocalizeFilter( locale, '_lang.module.common.title.titleSearchStr' ) }
				                 onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => {
					                 setSearchStr( e.currentTarget.value )
				                 } }/>
			</Grid>

			<Grid>
				<TitleClient>{ fnLocalizeFilter( locale, '_lang.module.clientList.titlePage' ) }</TitleClient>
			</Grid>
			<Grid item xs={ 12 }>

				<Table
					columns={ columns }
					rows={ rows }
					id={ 'holderId' }
					isSimple
					loading={ isLoading }
					sxTable={ { border: "none" } }
					fnRowDoubleClick={ ( data ) => handleDoubleClick( data.row ) }
					sx={ {
						minHeight: "10px",
						marginRight: ".5rem",
						width: matches ? "100%" : "90%",
						overflowY: "auto"
					} }
				/>

			</Grid>

		</Container>

		{

			isOpenDetail && <Suspense fallback={ <Loading type={ 'fullScreen' }/> }>
          <ClientDetail
              open={ isOpenDetail }
              detailClient={ selectedClient as IHolders }
              handleClose={ () => setOpenDetail( false ) }
          />
        </Suspense>
		}

	</>
}
