import React from "react";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCustomStyles } from "../../../style/global";
import Loading from "../Loading/Loading";

interface IInfoBlock {
	title: string;
	value: string;
	isLoading?: boolean;
	sx?: React.CSSProperties;
}

const Container = styled( Grid )( () => ( {
	flexDirection: 'column'
} ) );

export default function InfoBlock ( { title, value, isLoading = false, sx = {} }: IInfoBlock ) {
	const classes = useCustomStyles();
	return <>
		<Box sx={ { ...sx } }>
			<Container container>
				<Grid className={ classes.textDefault }>{ title }</Grid>
				<Grid className={ classes.titleDefault }
				      style={ { paddingTop: ".3rem" } }>{ isLoading ? <Loading type={'small'}/> : [ '', null ].indexOf( value ) !== -1 ? '-' : value }</Grid>
			</Container>
		</Box>
	</>
}