import { holderAPI } from "../../../services/holderAPI.service";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, useMediaQuery } from "@mui/material";
import InnerContainer from "../../Common/InnerContainer";
import { fnLocalizeFilter } from "../../../services/localize.service";
import { useGlobalContext } from "../../../services/context.service";
import BackToLink from "../../Common/BackToLink";
import ComboBox from "../../UI/Input/ComboBox";
import InfoBlock from "../../UI/InfoBlock/InfoBlock";
import { getMedia } from "../../../helpers/media";

import { IHolders } from "../../../services/response.interface";
import { useNavigate, useParams } from "react-router-dom";
import ClientGroupTitle from "../../Common/ClientGroupTitle";
import { getColumns } from "./accounts.columns";
import FilterTable from "../../UI/Filter/FilterTable";
import CustomTable from "../../UI/CustomTable";
import { IFilter, IOrderBy } from "../../UI/CustomTable/interface/interface.custom";
import { useAppDispatch } from "../../../hooks/redux";
import { IFilterAccountsField, initialStateFilterAccount } from "./Filter/interface.filter";
import FilterContext from "./Filter/context.account";
import { filterApply } from "./Filter/filter";
import { COUNT_PER_ON_PAGE } from "../../../helpers/api";


/* Кол-во строк в таблице счетов, на странице инфо клиента */
const countPerOnPage: number = COUNT_PER_ON_PAGE ? +COUNT_PER_ON_PAGE : 5;

export default function AccountsDetail () {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	/* Получаем список клиентов доступных пользователю */
	const {
		data: dataHolder = { holders: [] },
		isSuccess,
		isLoading: isLoadingClient
	} = holderAPI.useFetchAllClientsQuery( locale );

	/* Получаем список счетов выбранного клиента */
	const [ getAccount, {
		data: dataAccount = { accounts: [] },
		isLoading
	} ] = holderAPI.useLazyFetchAccountsClientQuery();

	/* Получаем адренсную строку */
	let params = useParams();

	/* Получаем клиента */
	const [ holderId, setHolder ] = useState<number>( 0 );

	/* Создаем стэйт выбранного клиента */
	const [ client, setClient ] = useState<IHolders>();

	useEffect( () => {

		if ( params && params.holderId && dataHolder.holders.length > 0 ) {
			/* Преобразуем параметр в число */
			let holderId = +params.holderId;

			/* Если перешли по сылке из пунктов меню и holderId=0 берем первого холдера если нет, выбираем указанного */
			let selectedClient = ( holderId === 0 )
				? dataHolder.holders[ 0 ]
				: dataHolder.holders.filter( ( holder: IHolders ) => holder.holderId === +holderId )[ 0 ];

			setClient( selectedClient );

			if ( selectedClient ) {
				setHolder( selectedClient.holderId );
				getAccount( { locale, holderId: selectedClient.holderId } );
			}

		}

	}, [ params, dataHolder ] )

	/* Управляем линками */
	let navigate = useNavigate();
	const dispatch = useAppDispatch();

	/* Инициализация медиа  */
	const matches = useMediaQuery( getMedia() );

	/* Форматируем для правильного отображаения выпадающего списка */
	const renderHolders = useMemo( () => {
		return dataHolder.holders.map( holder => ( {
			id: holder.holderId,
			label: holder.name
		} ) )

	}, [ isSuccess ] );

	/* Меняем состояния клиента */
	const handleChangeClient = ( selectedClient: any ) => {

		const newClient = dataHolder.holders.filter( ( holder: IHolders ) => holder.holderId === selectedClient )[ 0 ];
		setClient( newClient )
	};

	const handleClose = () => {
		navigate( '/clients', { replace: true } );
	};

	/* Заполняем табличные заголовки */
	const columns = useMemo( () => {
		return getColumns( {
			locale,
			accounts: dataAccount.accounts,
			holderId,
			dispatch
		} );
	}, [ dataAccount.accounts, dispatch, holderId, locale ] );

	/* Текущая страница счетов */
	const [ pageNum, setPage ] = useState<number>( 0 );
	const [ orderBy, setOrderBy ] = useState<IOrderBy>( { field: "", order: "asc" } );
	const handleChangePage = ( { page, orderBy }: IFilter ) => {
		//setRefresh( true );
		setOrderBy( orderBy );
		setPage( page - 1 );
	};

	/* Фильтр таблицы */
	const [ filter, setFilterState ] = useState<IFilterAccountsField>( initialStateFilterAccount );
	const [ isFilterApply, setApplyFilter ] = useState<boolean>( false );

	/* изменение фильтра */
	const handleChangeFilter = ( newFilter: IFilterAccountsField ) => {
		setFilterState( newFilter );
	};
	const handleClearFilter = () => {
		setFilterState( initialStateFilterAccount );
		handleCommitFilter();
	};
	/* Применение фильтра */
	const handleCommitFilter = useCallback( () => {
		setApplyFilter( prev => !prev );
	}, [ filter ] );

	/* Если меняем клиента обновляем таблицу счетов */
	useEffect( () => {

		setPage( 0 );
		client && client.holderId && getAccount( { locale, holderId: client.holderId } );

	}, [ client ] );

	/* Фильтр по столбцам таблицы  */
	const rows = useMemo( () => {
		let count = 0;

		/* Применение фильтра */
		let filteredArr = filterApply( { accounts: dataAccount.accounts, filter, orderBy } );
		count = filteredArr.length;

		/* Обрезаем массив до необходимых размеров отображения на странице */
		const startRow = pageNum === 0 ? 0 : ( pageNum * countPerOnPage );
		const stopRow = ( pageNum + 1 ) * countPerOnPage;

		/* Обрезаем по строкам на странице после фильтра */
		filteredArr = filteredArr.slice( startRow, stopRow );

		return { arr: filteredArr, count };
	}, [ pageNum, isFilterApply, orderBy, dataAccount ] );


	return <>
		<FilterContext.Provider value={ { filter } }>
			<InnerContainer direction={ 'left' } show={ true }>
				<BackToLink handleClose={ handleClose } backToType={ 'client' }>

					<Grid container style={ { flexDirection: 'row' } }>

						<Grid item xs={ 12 } md={ 9 } style={ { flexDirection: 'column', padding: "1rem" } }>
							{/* Выпадающий список клиентов */ }
							<Grid container style={ { display: "flex", alignItems: "center", } }>

								<Grid item xs={ 12 } md={ 3 }>
									{
										<ComboBox
											textStyle={ { fontWeight: '700' } }
											data={ dataHolder.holders }
											fieldLabelOption={ 'name' }
											label={ fnLocalizeFilter( locale, '_lang.module.common.title.titleClientComboBox' ) }
											fnOnChange={ handleChangeClient }
											defaultValue={ holderId }
										/>
									}
								</Grid>
								<Grid item xs={ 12 } md={ 3 }>
									<InfoBlock
										title={ fnLocalizeFilter( locale, `_lang.module.clientList.titleStatus` ) }
										value={ fnLocalizeFilter( locale, `_lang.statusClient.${ client?.status }` ) }
										isLoading={ client?.status === undefined || isLoadingClient }
										sx={ { padding: matches ? "1rem 0 0 0" : "0 0 1rem 4rem" } }
									/>
								</Grid>
							</Grid>

						</Grid>

						<Grid item xs={ 12 } md={ 9 }
						      style={ { display: "flex", justifyContent: "flex-start", padding: "1rem", alignItems: "center" } }>
							<ClientGroupTitle mt={ 1 }
							                  title={ `${ fnLocalizeFilter( locale, '_lang.module.dataGroup.accounts' ) }` }/>
							{/* Фильтр таблицы */ }

							<FilterTable
								type={ 'accounts' }
								onFilterClear={ handleClearFilter }
								onFilterChange={ handleChangeFilter }
								onFilterCommit={ handleCommitFilter }
							/>

						</Grid>

						<Grid item xs={ 12 } md={ 10 } style={ { flexDirection: 'column', padding: "1rem" } }>

							<CustomTable
								rows={ rows.arr }
								columns={ columns }
								isLoading={ isLoading || isLoadingClient }
								count={ rows.count }
								labelEmptyRows={ fnLocalizeFilter( locale, '_lang.module.common.table.emptyRows' ) }
								rowsPerPageOptions={ [ countPerOnPage ] }
								fnRefreshData={ handleChangePage }
								holderId={ client?.holderId }
							/>

						</Grid>

					</Grid>


				</BackToLink>
			</InnerContainer>
		</FilterContext.Provider>
	</>
}
