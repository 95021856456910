import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../../../../services/context.service";
import { IUsers } from "../../../../../services/response.interface";
import { fnLocalizeFilter } from "../../../../../services/localize.service";
import { useAppDispatch } from "../../../../../hooks/redux";
import ComboBox from "../../../../UI/Input/ComboBox";
import Modal from "../../../../UI/Modal/Modal";
import { settingAPI } from "../../../../../services/settingAPI.service";
import InputFilled from "../../../../UI/Input/InputFilled";
import ComboboxMultiply from "../../../../UI/Input/ComboboxMultiply";
import { holderAPI } from "../../../../../services/holderAPI.service";
import InputPhone from "../../../../UI/Input/InputPhone";
import OutlineBox from "../../../../UI/OutlineBox/OutlineBox";
import { handleCreateUser, handleUpdateUser } from "./utils/local.request.api";
import { checkPermission } from "../../../../../helpers/permissions";

interface IUserEditor extends Omit<IUsers, "personalAccountUserId"> {
}

const initialUser: IUserEditor = {
	id: -1,
	apiRole: 'SmartCardsReporter',
	active: true,
	email: '',
	firstName: '',
	lastName: '',
	phoneNumber: '380',
	holders: [],
	listHolders: ''
};

interface IModalUserEditor {
	isShow: boolean;
	userId: number;

	handleClose (): void;
}


export default function ModalUserEditor ( { isShow = false, userId, handleClose }: IModalUserEditor ) {
	/* Получаем языковую локаль */
	const { locale } = useGlobalContext();

	const dispatch = useAppDispatch();

	const { data: dataHolder = { holders: [] } } = holderAPI.useFetchAllClientsQuery( locale );
	const { data: dataRoles = { roles: [] } } = settingAPI.useFetchUserRolesQuery( { locale } );
	const { data: dataUsers = { users: [] } } = settingAPI.useFetchAllUsersQuery( { locale } );
	const [ getUsers ] = settingAPI.useLazyFetchAllUsersQuery();

	const [ selectedUser, setSelectedUser ] = useState<IUserEditor>( initialUser );
	const [ phone, setPhone ] = useState( '' );

	useEffect( () => {
		let newUser = initialUser;

		if ( userId !== -1 ) {
			newUser = dataUsers.users.filter( u => u.personalAccountUserId === userId )[ 0 ];
			setPhone( newUser.phoneNumber );
		}

		setSelectedUser( { ...newUser } );

	}, [ dataUsers, userId ] );


	//
	// const firstName = useInput( selectedUser.firstName, { isEmpty: true }, locale );
	// const lastName = useInput( selectedUser.lastName, { isEmpty: true }, locale );
	// const email = useInput( selectedUser.email, { isEmpty: true, isEmail: true }, locale );

	/* Нажатие на ОК при создании пользователя */
	const handleBtnCreateUser = () => {
		const { holders, lastName, firstName, email, apiRole } = selectedUser;
		if ( selectedUser.id === -1 ) {
			handleCreateUser( { locale, firstName, lastName, phoneNumber: phone, holders, email, apiRole, dispatch } )
				.then( res => {

					if ( res ) {
						getUsers( { locale } );
						handleClose();
					}
				} );
		} else {
			handleUpdateUser( { ...selectedUser, phoneNumber: phone, locale, dispatch } )
				.then( res => {

					if ( res ) {
						getUsers( { locale } );
						handleClose();
					}
				} );
		}
	};


	const handleChangeClient = ( arr: any[] ) => {
		const newUser = { ...selectedUser, holders: [ ...arr.map( a => ( { holderId: a } ) ) ] }
		setSelectedUser( newUser );
	};

	const handleChangeApiRole = ( roleId: number ) => {

		const role = dataRoles.roles.filter( r => r.id === roleId )[ 0 ];
		const newUser = { ...selectedUser, apiRole: role.roleName }
		setSelectedUser( newUser );
	}

	const handleChangeText = ( val: string, field: string ) => {
		const newUser = { ...selectedUser, [ field ]: val }
		setSelectedUser( newUser );
	}

	return <Modal
		handleBtnOk={ checkPermission( { permissionName: 'PersonalAccounts' } ) > 1 ? handleBtnCreateUser : () => {
		} }
		isOpen={ isShow }
		handleBtnCancel={ handleClose }
		disabled={ checkPermission( { permissionName: 'PersonalAccounts' } ) < 2 }
	>
		<Grid item sx={ {
			minWidth: '300px',
			minHeight: '500px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between'
		} }>


			<Grid>

				<InputFilled label={ fnLocalizeFilter( locale, '_lang.module.setting.users.titleLastName' ) }
				             onChange={ ( e: any ) => handleChangeText( e.target.value, 'lastName' ) }
				             value={ selectedUser.lastName }
				/>
				<InputFilled label={ fnLocalizeFilter( locale, '_lang.module.setting.users.titleFirstName' ) }
				             onChange={ ( e: any ) => handleChangeText( e.target.value, 'firstName' ) }
				             value={ selectedUser.firstName }
				/>
				<InputFilled label={ fnLocalizeFilter( locale, '_lang.module.setting.users.titleEmail' ) }
				             onChange={ ( e: any ) => handleChangeText( e.target.value, 'email' ) }
				             value={ selectedUser.email }
				/>

				<OutlineBox sx={ { padding: "0 !important" } }>
					<InputPhone
						label={ fnLocalizeFilter( locale, '_lang.module.register.titlePhone' ) }
						value={ phone }
						onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => setPhone( e.target.value ) }
						sx={ { "& .MuiInput-root:before, & .MuiInput-root:after": { display: 'none' } } }
					/>
				</OutlineBox>

				<ComboboxMultiply
					array={ dataHolder.holders }
					optionName={ 'name' }
					fieldId={ 'holderId' }
					selected={ selectedUser && selectedUser.holders.map( h => h.holderId ) }
					onChange={ handleChangeClient }
					id={ 'holders' }
				/>

				<ComboBox
					textStyle={ { fontWeight: '700' } }
					data={ dataRoles.roles }
					fieldLabelOption={ 'roleName' }
					label={ fnLocalizeFilter( locale, '_lang.module.setting.users.titleApiRole' ) }
					fnOnChange={ handleChangeApiRole }
					defaultValue={ selectedUser.id !== -1 ? dataRoles.roles.filter( r => r.roleName === selectedUser.apiRole ).map( r => ( r.id ) )[ 0 ] : undefined }
				/>

			</Grid>

		</Grid>
	</Modal>
}