import React, { useMemo } from "react";

import { CardMedia, Grid, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";
import Menu from "../Menu/Menu";
import MenuMobile from "../Menu/MenuMobile";
import { getMedia } from "../../../../helpers/media";
import { Route, Routes } from "react-router-dom";
import { menus } from "../../../../helpers/menu";
import { checkPermission } from "../../../../helpers/permissions";

const useStyles = makeStyles<Theme>( ( theme ) => ( {

	logo: {
		width: "180px",
		margin: "5px auto 0 auto",
		[ theme.breakpoints.down( 'md' ) ]: {
			margin: "5px .5rem 0 auto"
		}
	},
	container: {
		position: "relative",
		display: "flex",
		background: theme.palette.primary.main,
		justifyContent: "flex-start",
		minHeight: '100%',
		width: '100%',
		boxSizing: "border-box",
		[ theme.breakpoints.down( 'md' ) ]: {}
	},
	menu: {
		width: "200px",
		[ theme.breakpoints.down( 'md' ) ]: {
			width: "inherit"
		}
	},
	main: {
		flex: 1,
		minHeight: "100%",
		borderRadius: "7px",
		backgroundColor: theme.palette.primary.light,
		padding: "1rem",
		position: 'relative',
		overflowX: 'hidden'
	}
} ) );

export default function MainLayout () {
	const classes = useStyles();

	const matches = useMediaQuery( getMedia() );

	/* Формируем линки для приложения */
	const routesDefault = useMemo( () => {
		return <Routes>
			<Route index element={ <></> }/>
			{
				menus
					.filter( menu => {
						return !!menu.icon || ( menu.permission && checkPermission( { permissionName: menu.permission } ) > 0 )
					} )
					.map( menu => ( <Route key={ menu.link } path={ menu.link } element={ menu.component }/> ) )
			}
			{
				//<Route path={ '*' } element={ <PageNotFound/> }/>
			}
		</Routes>
	}, [] );

	return <>
		<Grid className={ classes.container }
		      style={ { flexDirection: `${ matches ? "column" : "row" }` } }
		      id={ "outer-container" }>

			<Grid item className={ classes.menu }>

				{/* Логотип */ }
				<CardMedia
					className={ classes.logo }
					style={ { width: "180px" } }
					component="img"
					image={ './img/logo.png' }
				/>

				{/* Формируем меню для десктопа и ПК */ }
				{
					matches
						? <MenuMobile menu={ <Menu/> }/>
						: <Menu/>
				}


			</Grid>

			<Grid item className={ classes.main } sx={ { margin: ".5rem" } }>
				{ routesDefault }
			</Grid>

		</Grid>
	</>
};
