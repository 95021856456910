import "./DatePicker.css";
import moment from 'moment';
import 'moment/locale/uk';
import { Box, Button, Grid, IconButton } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Calendar from "react-calendar";
import { makeStyles } from "@mui/styles";
import InputFilled from "../Input/InputFilled";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles( ( theme ) => {
	return ( {
		root: {
			display: "flex",
			justifyContent: "flex-start",
			// color: env.color.defaultFontTextColor,
			flexWrap: "wrap",
			alignItems: 'center',
			"& [class*=MuiFormControl-root]": {
				width: "100%"
			}
		},
		containerBtn: {
			marginLeft: ".2rem",
			marginTop: ".5rem",
			width: "2.5rem",
			height: "2.5rem"
		},
		calendarBtn: {
			// color: env.color.key,
			"&:hover": {
				cursor: "pointer",
				// color: env.color.iconApply
			}
		},
		containerCalendar: {
			position: "relative",
			display: "flex",
			alignItems: "center",
			flex: 1
		},
		labelBeforeInput: {
			marginRight: ".5rem"
		},
		input: {

			"& input": { textAlign: "center" }
		},
		btnContainer: {
			display: "flex",
			justifyContent: "space-between",
			flexDirection: "row"
		}
	} )

} );

moment.locale( 'uk' );

const CustomBox = styled( Box )( ( { theme } ) => ( {
	position: "absolute",
	display: "flex",
	flexDirection: "column",
	top: "100%",

	padding: ".5rem",
	backgroundColor: theme.palette.primary.main,
	borderRadius: "5px",
	zIndex: 9999
} ) );

type IFormatShow = 'DD.MM.YYYY';
type IMaxDetail = 'month';

interface IDatePicker {
	tooltipBtn?: string;
	labelCancel?: string;
	labelBtn?: string;
	labelInput?: string;
	disabled?: boolean;
	selectRange?: boolean;
	isTableFilter?: boolean;
	formatShow?: IFormatShow;
	maxDetail?: IMaxDetail;
	pickerValue: [ string, string ],

	onChange ( value: any ): void;
}


export default function DatePicker ( {
	                                     tooltipBtn = "Відкрити календар",
	                                     labelCancel = "Закрити",
	                                     labelBtn = "Обрати",
	                                     labelInput = "Період",
	                                     selectRange = true,
	                                     disabled = false,
	                                     onChange,
	                                     isTableFilter = false,
	                                     formatShow = "DD.MM.YYYY",
	                                     pickerValue,
	                                     maxDetail = "month"
                                     }: IDatePicker ) {
	const classes = useStyles();
	const [ value, setValue ] = useState<any>();
	const [ isShowCalendar, setShowCalendar ] = useState( false );

	useEffect( () => {
		let date = ( selectRange )
			? [ `${ moment().format( 'YYYY-MM' ) }-01T00:00:00`, `${ moment().format( 'YYYY-MM-DD' ) }T23:59:59` ]
			: new Date();

		setValue( date );

	}, [ selectRange ] )

	useEffect( () => {
		setValue( pickerValue );
	}, [ pickerValue ] )

	const fnOpenCalendar = () => {
		setShowCalendar( prev => !prev );
	}

	const fnSubmitDate = () => {
		onChange( value );
		setShowCalendar( prev => !prev );
	}

	const fnCancelDate = () => {
		setShowCalendar( prev => !prev );
	}

	const fnFormatDate = useCallback( ( value: any ) => {
		return value?.length !== 2
			? moment( value ).format( formatShow )
			: `${ moment( value[ 0 ] ).format( formatShow ) } - ${ moment( value[ 1 ] ).format( formatShow ) }`
	}, [ formatShow ] );

	const showValue = useMemo( () => {
		return fnFormatDate( value )
	}, [ value, fnFormatDate ] );

	return <>
		<Grid item xs={ 12 } className={ classes.root }>
			<Grid className={ classes.containerCalendar }>

				<InputFilled
					label={ labelInput }
					variant={ 'filled' }
					value={ showValue }
					disabled={ disabled }
					sx={ { "& input": { paddingRight: '2.5rem' } } }
				/>

				{
					isShowCalendar &&
            <CustomBox>
              <Grid item>
                <Calendar
                    onChange={ setValue }
                    value={ value }
                    selectRange={ selectRange }
                    maxDetail={ maxDetail }
                    locale={ 'uk-UA' }
                />
              </Grid>
              <Grid item xs={ 12 } className={ classes.btnContainer } style={ { marginTop: ".5rem" } }>

                <Button variant="contained" color="secondary"
                        onClick={ fnCancelDate }>{ labelCancel }</Button>

                <Button variant="contained" color="secondary"
                        onClick={ fnSubmitDate }>{ labelBtn }</Button>
              </Grid>
            </CustomBox>
				}

				{
					!disabled &&
            <Tooltip title={ tooltipBtn } sx={ { position: 'absolute', top: '12%', right: 0 } }>
              <IconButton size="small" className={ classes.containerBtn } onClick={ fnOpenCalendar }>
                <DateRangeIcon className={ classes.calendarBtn }/>
              </IconButton>
            </Tooltip>
				}
			</Grid>
		</Grid>
	</>
};
