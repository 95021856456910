import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { styled } from "@mui/material/styles";
import { Box, IconButton, Typography } from "@mui/material";
import Loading from "../../../../Loading/Loading";
import { lazy, Suspense, useState, } from "react";
import { IRow } from "../../Rows/Rows";
import { ISingleRow } from "../../../interface/interface.custom";
import { useAppDispatch } from "../../../../../../hooks/redux";
import { showTooltip } from "../../../../../../store/actions/layoutActions";

const Modal = lazy( () => import('../../../../Modal/Modal') );

const IconButtonCustom = styled( IconButton )( () => ( {
	padding: 0
} ) );

interface IDeleteRow {
	labelConfirm: string;
	row: IRow;

	onConfirmDelete? ( { row }: ISingleRow ): Promise<boolean>;
}

export default function DeleteRow ( { labelConfirm,  row, onConfirmDelete }: IDeleteRow ) {
	const [ isOpenModal, setOpenModal ] = useState( false );

	const dispatch = useAppDispatch();

	const handleDeleteRow = () => {
		onConfirmDelete && onConfirmDelete( { row } ).then( res => {
			if ( res ) {
				handleCloseDialog();
			}
		} );
	};

	const handleConfirmationDialog = () => {
		setOpenModal( true )
	};

	const handleCloseDialog = () => {
		setOpenModal( false )
	};

	return <>
		<IconButtonCustom onClick={ handleConfirmationDialog }>
			<DeleteForeverIcon/>
		</IconButtonCustom>
		{
			isOpenModal && <Suspense fallback={ <Loading type={ 'fullScreen' }/> }>
          <Modal
              handleBtnOk={ handleDeleteRow }
              isOpen={ isOpenModal }
              handleBtnCancel={ handleCloseDialog }
          >
            <Box sx={ { width: '300px' } }>
              <Typography>{ labelConfirm }</Typography>
            </Box>
          </Modal>
        </Suspense>
		}
	</>
}