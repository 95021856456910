import { Grid, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CancelIcon from "@mui/icons-material/Cancel";
import { ISingleRow } from "../../../interface/interface.custom";

const IconButtonCustom = styled( IconButton )( ( props ) => ( {
	padding: 0,
	"&:hover": {
		color: props.property === 'initial' ? 'text.secondary' : props.property === 'apply' ? '#4a9a4a' : '#c75b5b'
	}
} ) );

const Container = styled( Grid )( ( props ) => ( {
	position: 'relative',
	[ props.theme.breakpoints.down( 'md' ) ]: {
		width: '100px'
	}
} ) );

const TextEditor = styled( TextField )( () => ( {
	"& input": {
		padding: "0rem 3rem .1rem .5rem",
		width: '100%'
	}
} ) );

const ContainerField = styled( Grid )( () => ( {
	display: 'flex',
	justifyContent: 'space-between'
} ) );

const ContainerBtn = styled( Grid )( () => ( {
	position: 'absolute',
	right: 0,
	top: 0
} ) );

interface ICellTextEditor {
	type: React.InputHTMLAttributes<unknown>['type'],
	row: any;
	field: string;

	onCommitChanges? ( { row }: ISingleRow ): Promise<boolean>;
}

export default function CellTextEditor ( { type, field, row, onCommitChanges }: ICellTextEditor ) {
	const [ isShowEdit, setShowEdit ] = useState( false );
	const [ val, setValue ] = useState<string | number>( 0 );
	const [ valCommit, setValueCommit ] = useState<string | number>( 0 );

	useEffect( () => {
		setValue( row[ field ] )
		setValueCommit( row[ field ] )
	}, [ row ] )

	const handleOpenEditField = () => {
		setShowEdit( true );
	};

	const handleCancelEditField = () => {
		setValueCommit( val );
		setShowEdit( false );
	};

	const handleSaveEditField = () => {
		setValue(valCommit);

		const newRow = row && { ...row, [ field ]: valCommit }
		if ( onCommitChanges )
			onCommitChanges( { row: newRow } )
				.then( res => {
					if ( res ) {
						setShowEdit( false );
					}
				} )
	};

	const handleChangeInput = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		const newValue = type === 'number' ? +e.target.value : e.target.value;
		setValueCommit(newValue)
	};

	return <>
		<Grid sx={ { boxSizing: 'border-box' } }>
			{
				isShowEdit
					?
					<Container>
						<TextEditor variant="outlined" value={ valCommit } size={ 'small' } type={ type } onChange={ handleChangeInput }/>
						<ContainerBtn>
							<IconButtonCustom property={ 'apply' } onClick={ handleSaveEditField }><SaveAsIcon/></IconButtonCustom>
							<IconButtonCustom property={ 'cancel' } onClick={ handleCancelEditField }><CancelIcon/></IconButtonCustom>
						</ContainerBtn>
					</Container>
					:
					<ContainerField>
						{
							( type === 'number' && val === 0 )
								? <Typography
									sx={ { flex: "1", fontSize: "inherit" } }>{ `${ ( field !== 'balance' ) ? '-' : val.toLocaleString() }` }</Typography>
								: <>
									<Typography sx={ { flex: "1", fontSize: "inherit" } }>{ val.toLocaleString() }</Typography>
									<IconButtonCustom property={ 'initial' } onClick={ handleOpenEditField }> <EditIcon/>
									</IconButtonCustom>
								</>
						}

					</ContainerField>
			}

		</Grid>
	</>
}
