import { useGlobalContext } from "../../../../../services/context.service";
import { Suspense, useMemo, useState } from "react";
import { settingAPI } from "../../../../../services/settingAPI.service";
import { holderAPI } from "../../../../../services/holderAPI.service";
import CustomTable from "../../../../UI/CustomTable";
import { fnLocalizeFilter } from "../../../../../services/localize.service";
import { getColumns } from "./users.column";
import { useAppDispatch } from "../../../../../hooks/redux";
import { styled } from "@mui/material/styles";
import { Button, Grid } from "@mui/material";
import ClientGroupTitle from "../../../../Common/ClientGroupTitle";
import Loading from "../../../../UI/Loading/Loading";
import ModalUserEditor from "./ModalUserEditor";
import { IRow } from "../../../../UI/CustomTable/components/Rows/Rows";
import { checkPermission } from "../../../../../helpers/permissions";

const GridTitle = styled( Grid )( () => ( {
	display: 'flex',
	alignItems: 'center',
	"&>.MuiGrid-root": {
		margin: 0
	}
} ) );

export default function ChangeUser () {
	const { locale } = useGlobalContext();
	const [ isOpenModal, setOpenModal ] = useState( false );

	const { data: dataRoles = { roles: [] } } = settingAPI.useFetchUserRolesQuery( { locale } );
	const { data: dataUsers = { users: [] }, isLoading } = settingAPI.useFetchAllUsersQuery( { locale } );
	const { data: dataHolder = { holders: [] } } = holderAPI.useFetchAllClientsQuery( locale );
	const [ userId, setUserId ] = useState<number>( -1 );

	const dispatch = useAppDispatch();

	/* Заполняем табличные заголовки */
	const columns = useMemo( () => {
		return dataRoles.roles && getColumns( {
			locale,
			dispatch,
			users: dataUsers.users,
			roles: dataRoles.roles,
			holders: dataHolder.holders
		} );
	}, [ dataUsers.users, dataRoles.roles, dispatch, locale ] );

	const handleDoubleClick = ( row: IRow ) => {
		setUserId( row.personalAccountUserId );
		setOpenModal( true );
	};

	const handleCreateNewUser = () => {
		setUserId( -1 );
		setOpenModal( true )
	};

	return <>
		<Grid item>
			<GridTitle item xs={ 12 } sx={ { margin: checkPermission( { permissionName: 'Limits' } ) > 1 ? 'initial': '1rem' } }>
				<ClientGroupTitle title={ fnLocalizeFilter( locale, '_lang.module.setting.titleChangeUser' ) } mt={ 0 }/>
				{ checkPermission( { permissionName: 'PersonalAccounts' } ) > 1 &&
					<Button
						color={ 'secondary' }
						variant={ 'contained' }
						sx={ { margin: '1rem' } }
						onClick={ handleCreateNewUser }
					>
						{ fnLocalizeFilter( locale, '_lang.module.setting.titleAddNewUser' ) }
					</Button>
				}
			</GridTitle>
			<CustomTable
				rows={ dataUsers.users }
				isSimple
				columns={ columns }
				isLoading={ isLoading }
				labelEmptyRows={ fnLocalizeFilter( locale, '_lang.module.common.table.emptyRows' ) }
				onDoubleClick={ handleDoubleClick }
			/>
		</Grid>

		{
			isOpenModal && <Suspense fallback={ <Loading type={ 'fullScreen' }/> }>
          <ModalUserEditor isShow={ isOpenModal } handleClose={ () => setOpenModal( false ) } userId={ userId }/>
        </Suspense>
		}

	</>
}