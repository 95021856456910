import { IAccounts } from "../../../../services/response.interface";
import { IOrderBy } from "../../../UI/CustomTable/interface/interface.custom";
import { IActiveAccounts } from "../../../../utils/interface";

export interface IFilterAccountsField {
	active: IActiveAccounts;
	balance: {
		from: number;
		to: number;
	},
	name: string;
}

export const initialStateFilterAccount: IFilterAccountsField = {
	active: {
		isActive: false,
		isBlock: false
	},
	balance: {
		from: 0,
		to: 0
	},
	name: ''
}

export interface IFilterApply {
	/**
	 * Искомій массив
	 */
	accounts: IAccounts[];
	/**
	 * поля фильтра
	 */
	filter: IFilterAccountsField;
	/**
	 * Сортировка по полю и метод (asc, desc)
	 */
	orderBy: IOrderBy;
}