import { useCustomStyles } from "../../../style/global";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import Loading from "../Loading/Loading";
import { ISingleRow } from "../CustomTable/interface/interface.custom";
import { GritEditItem } from "./common";
import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import { styled } from "@mui/material/styles";
import TextEditor from "../TextEditor/TextEditor";

export type TTypeTextParams = 'text' | 'phone' | 'disabled';

interface ITextParams {

	row: any;
	title: string;
	fieldValue: string;
	isLoading?: boolean;
	type?: TTypeTextParams;

	onChangeTextField ( { row }: ISingleRow ): Promise<boolean>;

	sx?: React.CSSProperties;

}

const IconButtonCustom = styled( IconButton )( () => ( {
	padding: 0
} ) );

export default function TextParams ( {
	                                     title,
	                                     row,
	                                     fieldValue,
	                                     type = 'text',
	                                     isLoading = false,
	                                     onChangeTextField,
	                                     sx = {}
                                     }: ITextParams ) {
	const classes = useCustomStyles();

	const [ isShowEdit, setEdit ] = useState<boolean>( false );

	const handleSwitchEditField = () => {
		setEdit( prev => !prev );
	};

	const handleSaveEditField = async ( value: string ) => {
		const newRow = {
			...row,
			[ fieldValue ]: value
		};
		return await onChangeTextField( { row: newRow } );
	};

	const handleCancelEdit = () => {
		setEdit( false );
	};


	return <>
		<Box sx={ { ...sx } }>

			{
				isShowEdit
					? <TextEditor
						label={ title }
						val={ row[ fieldValue ] }
						onCancelEdit={ handleCancelEdit }
						onSaveEdit={ handleSaveEditField }
						type={ type }
					/>
					: <>

						<Box className={ classes.textDefault }>{ title }</Box>
						<GritEditItem className={ classes.titleDefault }>
							{
								isLoading
									? <Loading type={ 'small' }/>
									: <>
										<Typography>{ row[ fieldValue ] }</Typography>
										{
											type !== 'disabled' &&
                        <Grid item xs={ 1 }>
                          <IconButtonCustom onClick={ handleSwitchEditField }> <EditIcon/> </IconButtonCustom>
                        </Grid>
										}
									</>
							}

						</GritEditItem>

					</>
			}


		</Box>
	</>
}