import { getDataFromLocalStorage } from "../utils/methods";
import { menus } from "./menu";

export type TPermission =
	'Accounts'
	| 'Limits'
	| 'Cards'
	| 'PersonalAccounts'
	| 'Holders'
	| 'Reports'
	| 'Attributes'
	| 'Driver'
	| 'All';

interface IPermission {
	permissionId: string;
	permissionName: TPermission;
	permissionValue: access;
}

export interface ICheckPermission {
	permissionName: TPermission;
}

enum access {
	'notAccess',
	'write',
	'read',

}


export function checkPermission ( { permissionName }: ICheckPermission ): access {

	if ( permissionName === 'All' ) return 2;

	let perms: IPermission[] = JSON.parse( getDataFromLocalStorage( 'permissions' ) as string )

	const resAccess = perms.filter( perm => perm.permissionName === permissionName )[ 0 ];

	let resValue: number = 0;
	switch ( resAccess?.permissionValue ) {
		case access.read: {
			resValue = 1
			break;
		}
		case access.write: {
			resValue = 2
			break;
		}
		default : {
			resValue = 0;
			break;
		}
	}

	return resValue;
}

export function getPermissionLinks ( { permissionName }: ICheckPermission ) {
	return menus.filter( menu => !!menu.icon && menu.permission && checkPermission( { permissionName: menu.permission } ) > 0 )
}
