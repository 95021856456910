import { fnLocalizeFilter, ILocale } from "../../../../services/localize.service";
import { IColumn } from "../../../UI/CustomTable/components/Columns/Columns";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

interface IGetColumns {
	locale: ILocale;
}

export const getColumns = ( { locale }: IGetColumns ): IColumn[] => {

	return [
		{
			field: 'collapse',
			label: '',
			formatHeader: 'empty',
			cell: { format: 'collapse', type: 'report-trn' },
			align: 'right'
		},
		{
			field: 'terminalDate',
			label: fnLocalizeFilter( locale, '_lang.module.reports.titleDate' ),
			formatHeader: 'default',
			cell: { format: 'date-time' },
			align: 'center',
			alignData: 'center'
		},
		{
			field: 'cardId',
			label: fnLocalizeFilter( locale, '_lang.module.reports.titleCard' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center'
		},
		{
			field: 'terminalName',
			label: fnLocalizeFilter( locale, '_lang.module.reports.titleAddress' ),
			formatHeader: 'default',
			cell: { format: 'icon-before-text', icon: LocalGasStationIcon },
			align: 'center',
			alignData: 'left',
			width: 300
		},
		{
			field: 'purseRemark',
			label: fnLocalizeFilter( locale, '_lang.module.reports.titlePurse' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center'
		},
		{
			field: 'quantity',
			label: fnLocalizeFilter( locale, '_lang.module.reports.titleQty' ),
			formatHeader: 'default',
			cell: { format: 'numericalDigit' },
			align: 'center',
			alignData: 'center'
		},
		{
			field: 'amount',
			label: fnLocalizeFilter( locale, '_lang.module.reports.titleSum' ),
			formatHeader: 'default',
			cell: { format: 'numericalDigit' },
			align: 'center',
			alignData: 'center'
		},
		{
			field: 'discount',
			label: fnLocalizeFilter( locale, '_lang.module.reports.titleDiscount' ),
			formatHeader: 'default',
			cell: { format: 'numericalDigit' },
			align: 'center',
			alignData: 'center'
		},
		{
			field: 'sumWithDiscount',
			label: fnLocalizeFilter( locale, '_lang.module.reports.titleSumWithDiscount' ),
			formatHeader: 'default',
			cell: { format: 'numericalDigit' },
			align: 'center',
			alignData: 'center'
		}
	];
};
