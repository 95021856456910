import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { setupStore } from "./store/store";
import Tooltip from "./components/UI/Tooltip/Tooltip";
import { BrowserRouter } from "react-router-dom";
import Loading from "./components/UI/Loading/Loading";

const root = ReactDOM.createRoot(
	document.getElementById( 'root' ) as HTMLElement
);

const store = setupStore();

const RootContainer = () => {

	const [ baseName, setBaseName ] = useState<string>();

	const getCustomBaseName = async () => {
		const config = await fetch( 'config.json' )
			.then( function ( response ) {
				return response.json();
			} );

		config && setBaseName( config.basename );
	};

	useEffect( () => {
		getCustomBaseName().then();
	}, [] );

	if ( !baseName )
		return <Loading type={ 'fullScreen' }/>
	else
		return (
			<BrowserRouter basename={ baseName }>
				<SnackbarProvider maxSnack={ 3 } autoHideDuration={ 10000 }>
					<Tooltip/>
					<App/>
				</SnackbarProvider>
			</BrowserRouter>
		)
};

root.render(
	<React.StrictMode>
		<Provider store={ store }>
			<RootContainer/>
		</Provider>
	</React.StrictMode>
);

